import React, { useMemo, useEffect, useContext, useState, useCallback } from 'react'
import { useAccount, useChainId } from 'wagmi'

import { useAccountModal, useConnectModal } from '@rainbow-me/rainbowkit'

import { mainnet } from 'viem/chains'

import { parseUnits } from 'viem'

import { debounce } from 'lodash'

import { ReactComponent as MultipleCrossIcon } from '../../assets/navigation/multiple-cross.svg'
import { ReactComponent as WalletPlusIcon } from '../../assets/wallet/wallet-plus.svg'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { formatBigNumber, formatStringToNumber } from '../../utils'
import InputCurrencySelector from './InputCurrencySelector'
import { ThemeContext } from '../../context/ThemeProvider'
import { DAPP_CONFIG } from '../../constants'
import { TMintScreen } from '../../types'

interface IProps {
  label: string
  token: string
  amount: string
  balance: bigint
  classes?: string
  tokens?: string[]
  allowance?: bigint
  chainIcon?: number
  tokenPrice?: number
  isDisabled?: boolean
  showAccount?: boolean
  hideBalance?: boolean
  hideDollars?: boolean
  canEditAccount?: boolean
  isAmountGtBalance: boolean
  setAmount: (amount: string) => void
  setToken: (colateral: string) => void
  setIsApproved?: (flag: boolean) => void
  setScreen: (screen: TMintScreen) => void
  setIsAmountGtBalance?: (flag: boolean) => void
}

const DebouncedInput = (props: IProps) => {
  const [localAmount, setLocalAmount] = useState(props.amount || '')

  const theme = useContext(ThemeContext)
  const connectModal = useConnectModal()
  const accountModal = useAccountModal()
  const chainId = useChainId({ config: rainbowKitConfig })
  const account = useAccount({ config: rainbowKitConfig })

  const token = useMemo(() => {
    const config = DAPP_CONFIG[chainId || mainnet.id]
    if (!config) {
      return DAPP_CONFIG[mainnet.id].tokens['USN']
    }
    const _token = config.tokens[props.token]
    if (!_token) return config.tokens['USN']
    return _token
  }, [chainId, props.token])

  const formattedBalance = useMemo(
    () => formatBigNumber(props.balance || BigInt(0), token.decimals, true),
    [props.balance, token.decimals],
  )

  const formattedAmountInDollars = useMemo(() => {
    const dollars = Number(props.tokenPrice || 0) * Number(props.amount || 0)
    return formatStringToNumber(dollars.toString(), true)
  }, [props.tokenPrice, props.amount])

  const onChangeAmount = (event: React.FormEvent<HTMLInputElement>) => {
    let amount: string = (event?.target as HTMLInputElement)?.value

    if (isNaN(Number(amount))) {
      return
    }

    const regex = /^\d*\.?\d{0,4}$/
    if (!regex.test(amount)) {
      amount = (Math.floor(Number(amount) * 10000) / 10000).toString()
    }

    setLocalAmount(amount)
    debouncedOnChangeAmount(amount)
  }

  const setAmountFromPresets = (option: 'half' | 'max') => {
    const balance = props.balance || BigInt(0)
    const amount = formatBigNumber(option === 'half' ? balance / BigInt(2) : balance, token.decimals, false)
    const _formattedAmount = (Math.floor(Number(amount) * 10000) / 10000).toString()
    setLocalAmount(_formattedAmount)
    props.setAmount(_formattedAmount)
  }

  const debouncedOnChangeAmount = useCallback(
    debounce((amount: string) => {
      props.setAmount(amount)
    }, 500),
    [props.setAmount],
  )

  useEffect(() => {
    setLocalAmount('')
    props.setAmount('')
  }, [chainId, account.isConnected, account.address, account.chainId])

  useEffect(() => {
    if (!props.isDisabled && props.setIsAmountGtBalance) {
      props.setIsAmountGtBalance(parseUnits(props.amount, token.decimals) > props.balance ? true : false)
    }

    if (!props.isDisabled && props.allowance !== undefined && props.setIsApproved) {
      props.setIsApproved(parseUnits(props.amount, token.decimals) < props.allowance ? true : false)
    }
  }, [token, props])

  return (
    <div
      className={`w-full flex flex-col items-start justify-start rounded-lg p-4 gap-4 border-[1px] ${theme.isLightTheme ? 'bg-stone-50 border-stone-200' : 'border-stone-800 bg-stone-950'} ${props.isAmountGtBalance && 'border-red-500'} ${props.classes || ''}`}
    >
      <div className="w-full flex items-center justify-between">
        <p
          className={`w-fit font-Suisse-Intl font-[450] text-sm ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-600'}`}
        >
          {props.label}
        </p>

        {props.showAccount && (
          <button
            onClick={account.isConnected ? accountModal.openAccountModal : connectModal.openConnectModal}
            className={`w-fit rounded flex items-center justify-center py-1 px-[6px] gap-1 ${theme.isLightTheme ? 'bg-green-200' : 'bg-[#244133]'}`}
          >
            {!account.isConnected && (
              <div className={`h-4 w-4 ${theme.isLightTheme ? 'text-green-700' : 'text-green-700'}`}>
                <WalletPlusIcon className="w-full h-full" />
              </div>
            )}
            <p
              className={`font-Suisse-Intl font-[450] text-sm ${theme.isLightTheme ? 'text-green-600' : 'text-[#6CE9AA]'}`}
            >
              {!account.address
                ? 'CONNECT WALLET'
                : `${account.address.slice(0, 4)}...${account.address.slice(account.address.length - 5)}`}
            </p>
            {account.isConnected && props.canEditAccount && (
              <div className={`h-4 w-4 ${theme.isLightTheme ? 'text-green-700' : 'text-green-700'}`}>
                <MultipleCrossIcon className="w-full h-full" />
              </div>
            )}
          </button>
        )}

        {!props.showAccount && !props.hideBalance && (
          <div className="grow flex items-center justify-end gap-1 flex-wrap">
            <p
              className={`grow w-fit max-w-[48%] font-Suisse-Intl font-normal text-right text-wrap text-sm ${theme.isLightTheme ? 'text-[#525252]' : 'text-stone-500'}`}
            >
              BAL: {formattedBalance} {token.symbol}
            </p>
            <button
              onClick={() => setAmountFromPresets('half')}
              className={`rounded py-[2px] px-2 font-Suisse-Intl font-medium text-sm ${theme.isLightTheme ? 'bg-white border-stone-100 border-[1px] text-stone-500' : 'bg-stone-800 text-stone-400'}`}
            >
              HALF
            </button>
            <button
              onClick={() => setAmountFromPresets('max')}
              className={`rounded py-[2px] px-2 font-Suisse-Intl font-medium text-sm ${theme.isLightTheme ? 'bg-white border-stone-100 border-[1px] text-stone-500' : 'bg-stone-800 text-stone-400'}`}
            >
              MAX
            </button>
          </div>
        )}
      </div>

      <div className={`w-full max-w-full grid grid-cols-3 grid-rows-1 gap-2 items-center justify-between`}>
        <div className="w-full max-w-full col-span-2 p-0 m-0">
          <input
            type="text"
            id="amountIn"
            name="amountIn"
            placeholder="0.00"
            value={localAmount}
            onChange={onChangeAmount}
            disabled={props.isDisabled}
            className={`grow w-fit max-w-full font-Suisse-Intl font-semimedium text-2xl text-wrap outline-none bg-transaparent ${theme.isLightTheme ? 'text-stone-900 placeholder:text-stone-400' : 'text-stone-50 placeholder:text-[#737373]'}`}
          />
          {!props.hideDollars && props.tokenPrice !== undefined && (
            <p className={`font-Inter font-normal text-sm ${theme.isLightTheme ? 'text-stone-400' : 'text-[#525252]'}`}>
              ${formattedAmountInDollars}
            </p>
          )}
        </div>
        <div className="justify-self-end text-right p-0 m-0">
          {props.tokens?.length ? (
            <InputCurrencySelector
              chainIcon={props.chainIcon}
              tokens={props.tokens}
              setToken={props.setToken}
              token={props.token}
            />
          ) : (
            <div
              className={`w-fit cursor-pointer flex gap-1 rounded-[56px] py-[6px] px-[8px] bg-transaparent items-center justify-between justify-self-end`}
            >
              <div className="relative w-6 h-6">
                <img
                  alt={token.symbol}
                  className="w-full h-full"
                  src={`${process.env.PUBLIC_URL}/assets/tokens/${token.symbol}.svg`}
                  onError={() => import(`../../assets/tokens/${token.assetsIconName}`)}
                />
                {props.chainIcon && (
                  <div
                    className={`absolute h-3 w-3 ${theme.isLightTheme ? 'border-[1px] border-white' : ''} rounded-[100px] bottom-0 right-0`}
                  >
                    <img
                      alt={props.chainIcon.toString()}
                      className="w-full h-full"
                      src={`${process.env.PUBLIC_URL}/assets/chains/${props.chainIcon}.svg`}
                    />
                  </div>
                )}
              </div>
              <p
                className={`font-Suisse-Intl font-medium text-base ${theme.isLightTheme ? 'text-stone-700' : 'text-stone-100'}`}
              >
                {token.name}
              </p>
            </div>
          )}
        </div>
      </div>

      {props.showAccount && !props.hideBalance && (
        <>
          <hr
            className={`w-full border-b-[1px] border-dashed ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-800'}`}
          />

          <div className={`w-full flex items-center justify-between`}>
            <p className={`font-Inter font-normal text-sm ${theme.isLightTheme ? 'text-[#525252]' : 'text-[#525252]'}`}>
              BAL: {formattedBalance} {token.symbol}
            </p>
            <div className="w-fit flex items-center justify-between gap-1">
              <button
                onClick={() => setAmountFromPresets('half')}
                className={`rounded py-[2px] px-2 font-Suisse-Intl font-medium text-sm ${theme.isLightTheme ? 'text-stone-500 bg-stone-100' : 'bg-stone-800 text-stone-400'}`}
              >
                HALF
              </button>
              <button
                onClick={() => setAmountFromPresets('max')}
                className={`rounded py-[2px] px-2 font-Suisse-Intl font-medium text-sm ${theme.isLightTheme ? 'text-stone-500 bg-stone-100' : 'bg-stone-800 text-stone-400'}`}
              >
                MAX
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default DebouncedInput
