import React, { useContext, useState } from 'react'

import BuyContextProvider, { BuyContext } from '../../context/BuyProviderNew'
import BuySuccessful from '../../components/Buy/BuySuccessful'
import BuyConfirm from '../../components/Buy/BuyConfirm'
import ActionCard from '../../components/ActionCard'
import InputAndBuy from '../../components/Buy'
import { TUsnScreen } from '../../types'

interface IProps {
  screen: TUsnScreen
  setScreen: (screen: TUsnScreen) => void
}

const Buy = () => {
  const [txnUrl, setTxnUrl] = useState<string>('')

  const buy = useContext(BuyContext)

  return (
    <ActionCard screen={buy.screen} setScreen={buy.setScreen}>
      {buy.screen === 'input' ? (
        <InputAndBuy />
      ) : buy.screen === 'confirm' ? (
        <BuyConfirm setTxnUrl={setTxnUrl} />
      ) : (
        <BuySuccessful txnUrl={txnUrl} />
      )}
    </ActionCard>
  )
}

export const BuyProviderWrapper = () => {
  return (
    <BuyContextProvider>
      <Buy />
    </BuyContextProvider>
  )
}

export default BuyProviderWrapper
