import { useCallback, useEffect, useMemo, useState } from 'react'

import { useChainId, useEstimateFeesPerGas } from 'wagmi'
import { mainnet } from 'viem/chains'

import { rainbowKitConfig } from '../constants/rainbowKitConfig'
import { TGasTrackerApiResponse } from '../types'
import { DAPP_CONFIG } from '../constants'
import { formatBigNumber } from '../utils'

const useNetworkFee = (action: string, chainId?: number, gasLimit?: bigint) => {
  const [networkFee, setNetworkFee] = useState<number>(0)

  const wagmiChainId = useChainId({ config: rainbowKitConfig })

  const currentChainId = useMemo(() => {
    return chainId || wagmiChainId
  }, [wagmiChainId, chainId])

  const currentChainFeeData = useEstimateFeesPerGas({
    config: rainbowKitConfig,
    chainId: currentChainId as 1 | 50104 | 324 | undefined,
  })

  const fetchNetworkFee = useCallback(async () => {
    const gasDataFromAPI = await fetch(`${process.env.REACT_APP_BASE_API_URL ?? ''}/api/v1/gas-and-eth-price`)
      .then(
        (res) => {
          if (res.ok) {
            return res.json()
          }
          throw Error('Failed to get response from coingecko')
        },
        (err: Error) => {
          throw Error(err?.message)
        },
      )
      .then(
        (data) => {
          return data as TGasTrackerApiResponse
        },
        (err: Error) => {
          throw Error(err?.message)
        },
      )
      .catch((err: Error) => {
        console.error('Failed fetching gas data from api', err)
        return {
          ethPrice: '3900.83',
          gasPrice: '13786322077',
        } as TGasTrackerApiResponse
      })

    const _wagmiGasPrice = BigInt(currentChainFeeData?.data?.gasPrice || 0)
    const _wagmiFeePerGas = BigInt(currentChainFeeData?.data?.maxFeePerGas || 0)
    const _wagmiPriorityFeePerGas = BigInt(currentChainFeeData?.data?.maxPriorityFeePerGas || 0)
    const _wagmiFee = _wagmiGasPrice || _wagmiFeePerGas + _wagmiPriorityFeePerGas
    const ethPriceInNum = Number(gasDataFromAPI?.ethPrice || 0)

    if (_wagmiFee) {
      const _gasLimit = gasLimit !== undefined ? gasLimit : BigInt(DAPP_CONFIG[currentChainId]?.gasLimit[action] || 0)
      const gasInNative = _wagmiFee * _gasLimit
      const gasInNativeInNum = Number(formatBigNumber(gasInNative, 18, false))
      setNetworkFee(gasInNativeInNum * ethPriceInNum)
      return
    }

    const _gasPrice = chainId === mainnet.id ? BigInt(gasDataFromAPI?.gasPrice || 0) : BigInt(0)
    const _gasLimit = gasLimit !== undefined ? gasLimit : BigInt(DAPP_CONFIG[currentChainId].gasLimit[action] || 0)
    const gasInNative = _gasPrice * _gasLimit
    const gasInNativeInNum = Number(formatBigNumber(gasInNative, 18, false))
    setNetworkFee(gasInNativeInNum * ethPriceInNum)
  }, [action, chainId, currentChainId, currentChainFeeData.data, gasLimit, setNetworkFee])

  useEffect(() => {
    fetchNetworkFee().catch((err: Error) => console.error(err.stack))

    const refreshBalance = setInterval(fetchNetworkFee, DAPP_CONFIG[chainId ?? mainnet.id].dataRefershInterval ?? 10000)
    return () => clearInterval(refreshBalance)
  }, [action, chainId, currentChainId, currentChainFeeData.data, gasLimit, fetchNetworkFee])

  return networkFee
}

export default useNetworkFee
