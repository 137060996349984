import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import ReactDOM from 'react-dom/client'
import React from 'react'

import { QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter } from 'react-router-dom'
import '@rainbow-me/rainbowkit/styles.css'

import { WagmiProvider } from 'wagmi'

import { rainbowKitConfig, tanstackQueryClient } from './constants/rainbowKitConfig'
import reportWebVitals from './reportWebVitals'

import ThemeContextProvider from './context/ThemeProvider'
import RootApp from './components/RootApp'
import './index.css'

const theme = extendTheme({
  styles: {
    global: {
      '*:focus': {
        outline: 'none', // Removes focus ring
      },
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <WagmiProvider config={rainbowKitConfig}>
      <QueryClientProvider client={tanstackQueryClient}>
        <ChakraProvider theme={theme}>
          <ThemeContextProvider>
            <BrowserRouter>
              <RootApp />
            </BrowserRouter>
          </ThemeContextProvider>
        </ChakraProvider>
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
