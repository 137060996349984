import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react'

import React, { useContext } from 'react'

import welcomeNoonLogo from '../../assets/welcome-connect-modal.png'
import elipsis39 from '../../assets/dashboard/elipsis-39.png'
import { ThemeContext } from '../../context/ThemeProvider'
import avatar from '../../assets/avatar.png'

interface IProps {
  isOpen: boolean
  onClose: () => void
}

const WelcomeModal = (props: IProps) => {
  const { isOpen, onClose } = props

  const theme = useContext(ThemeContext)

  return (
    <Modal isOpen={isOpen} onClose={() => {}} autoFocus={false}>
      <ModalOverlay />
      <ModalContent
        borderRadius={'12px'}
        borderWidth={'1px'}
        background={theme.isLightTheme ? '#ECEDF6' : '#1C1917'}
        borderColor={theme.isLightTheme ? '#D6D3D1' : '#312D2C'}
        className={`relative top-44 w-full rounded-lg p-6 gap-6 ${theme.isLightTheme ? 'bg-[#ECEDF6] border-[#D6D3D1]' : 'bg-[#1C1917] border-[#312D2C]'} dashboard-profile-edit-modal`}
      >
        <div className="realtive w-full flex flex-col items-center justify-center gap-0 overflow-hidden rounded-lg">
          <div className="absolute right-0 -top-0 md:-right-0 p-0 m-0 w-full h-full z-4 rounded-lg">
            <img src={elipsis39} alt="" className="w-full h-full p-0 m-0 rounded-lg" />
          </div>

          <div className="min-w-full max-w-full h-48 w-full overflow-hidden p-0 z-3">
            <img
              src={welcomeNoonLogo}
              alt=""
              className="w-full min-w-full max-w-full aspect-auto border-2 border-stone-500"
            />
          </div>

          <div className="w-full gap-1 flex flex-col items-center justify-center z-50">
            <p
              className={`w-full font-Louize font-normal text-4xl leading-[44px] tracking-tighter ${theme.isLightTheme ? 'text-stone-900' : 'text-white'} text-center`}
            >
              Welcome to Noon
            </p>
            <p
              className={`w-full font-Suisse-Intl font-semiMedium text-lg leading-7 tracking-tighter ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} text-center text-wrap break-words`}
            >
              By clicking below button you are accepting our{' '}
              <a href="https://www.noon.capital/terms-and-conditions" target="_blank" rel="noreferrer">
                <u>Terms and conditions</u>
              </a>
              ,{' '}
              <a href="https://www.noon.capital/cookie-policy" target="_blank" rel="noreferrer">
                <u>Cookie Policy</u>
              </a>{' '}
              and{' '}
              <a href="https://www.noon.capital/privacypolicy" target="_blank" rel="noreferrer">
                <u>Privacy Policy</u>
              </a>
            </p>
          </div>
        </div>

        <div className="w-full flex flex-col md:flex-row gap-4 items-center justify-center z-50">
          <button
            onClick={() => {
              try {
                localStorage.setItem('noon-dapp-policies', 'accepted')
                onClose()
              } catch {
                return
              }
            }}
            className={`mr-auto ml-auto w-[50%] rounded-[184px] py-2 px-3 gap-1 bg-indigo-600 flex items-center justify-center`}
          >
            <p className={`font-Suisse-Intl font-medium text-base text-stone-50`}>I Accept</p>
          </button>
        </div>
      </ModalContent>
    </Modal>
  )
}

export default WelcomeModal
