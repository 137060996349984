import React, { useContext, useEffect, useMemo, useState } from 'react'

import { Tooltip } from '@chakra-ui/react'

import { useAccount } from 'wagmi'

import {
  formatBigNumber,
  formatBigNumberCompactNoDecimals,
  formatBigNumberNoDecimal,
  formatStringToNumber,
  formatStringToNumber3,
  formatStringToNumberCompactNoDecimal,
  formatStringToNumberNoDecimal,
} from '../../utils'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../ui/DropdownMenu'
import { ReactComponent as ChevronBigDownIcon } from '../../assets/navigation/chevron-big-down.svg'
import { ReactComponent as ChevronBigUpIcon } from '../../assets/navigation/chevron-big-up.svg'
import { ReactComponent as InfoFilledIcon } from '../../assets/info/info-circle-filled.svg'
import successIconGroupClipped from '../../assets/success/success-group-clipped.png'
import { ReactComponent as SpinnerIcon } from '../../assets/info/spinner.svg'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import elipsis39 from '../../assets/dashboard/elipsis-39.png'
import { ThemeContext } from '../../context/ThemeProvider'
import usesUsnHoldings from '../../hooks/useSusnHoldings'
import useUsnHoldings from '../../hooks/useUsnHoldings'
import usesUsnPrice from '../../hooks/usesUsnPrice'
import sUSN from '../../assets/tokens/sUSN.svg'
import USN from '../../assets/tokens/USN.svg'
import { TPointsInfo } from '../../types'

interface IProps {
  points?: string
  isEmpty?: boolean
  onOpen?: () => void
}

const Holdings = ({ isEmpty = false, points = '0', onOpen = () => {} }: IProps) => {
  const [pointsInfo, setPointsInfo] = useState<TPointsInfo>()

  const susnPrice = usesUsnPrice()
  const theme = useContext(ThemeContext)
  const usnHoldingsGraphQl = useUsnHoldings()
  const sUsnHoldingsGraphQl = usesUsnHoldings()
  const account = useAccount({ config: rainbowKitConfig })

  const [isUsnHoldingsOpen, setIsUsnHoldingsOpen] = useState(false)
  const [issUsnHoldingsOpen, setIssUsnHoldingsOpen] = useState(false)

  const fetchPointsInfo = async () => {
    await fetch(`${process.env.REACT_APP_BASE_API_URL ?? ''}/api/v1/points/${account.address?.toLowerCase()}`)
      .then(
        (res) => {
          if (res.ok) {
            return res.json()
          }

          throw new Error('Failed to fetch user dashboard data')
        },
        (err) => {
          throw new Error(err)
        },
      )
      .then((json) => json)
      .then((info) => {
        setPointsInfo(
          info || {
            byChain: {},
            totalPoints: '0',
            referralPoints: {
              earned: '0',
              fromReferrals: '0',
              total: '0',
              referrerAddress: '',
            },
          },
        )
      })
      .catch(() => {
        setPointsInfo({
          byChain: {},
          totalPoints: '0',
          referralPoints: {
            earned: '0',
            fromReferrals: '0',
            total: '0',
            referrerAddress: '',
          },
        })
        return
      })
  }

  const formattedTotalUsnHoldings = useMemo(() => {
    let totalUsn: number = 0
    Object.keys(usnHoldingsGraphQl.data || {}).map((chain) => {
      const balance = Number(usnHoldingsGraphQl?.data[chain] || '0')
      totalUsn += balance
    })
    return formatStringToNumber((totalUsn || 0).toString(), true)
  }, [usnHoldingsGraphQl])

  const [totalsUsnHoldings, formattedTotalsUsnHoldings] = useMemo(() => {
    let totalsUsn: number = 0
    Object.keys(usnHoldingsGraphQl.data || {}).map((chain) => {
      const balance = Number(sUsnHoldingsGraphQl?.data[chain] || '0')
      totalsUsn += balance
    })
    return [totalsUsn, formatStringToNumber((totalsUsn || 0).toString(), true)]
  }, [sUsnHoldingsGraphQl])

  const formattedTotalsUsnDollars = useMemo(() => {
    const price = Number(susnPrice.data?.sUsnPrice || '0')
    return formatStringToNumber(Number(totalsUsnHoldings * price).toString(), true)
  }, [totalsUsnHoldings, susnPrice])

  const [, , totalPointsFormattedCompact] = useMemo(() => {
    if (!pointsInfo || !pointsInfo?.byChain || !Object.keys(pointsInfo?.byChain || {})?.length) {
      return [{ Ethereum: '0', Sophon: '0', Zksync: '0', Referrals: '0' } as { [chain: string]: string }, '0', '0']
    }

    const byChain: { [chain: string]: string } = { Ethereum: '0', Sophon: '0', Zksync: '0', Referrals: '0' }
    const chains = Object.keys(pointsInfo?.byChain || {})
    let superTotal = 0
    for (const chain of chains) {
      if (chain === '1') {
        let total: number = 0
        for (const points of Object.values(pointsInfo?.byChain['1']?.byProtocol || {})) {
          total += Number(formatBigNumberNoDecimal(BigInt(points?.current || '0'), 18, false))
        }
        byChain['Ethereum'] = formatStringToNumberCompactNoDecimal(total?.toString() || '0', true)
        superTotal += total
      }

      if (chain === '9') {
        let total: number = 0
        for (const points of Object.values(pointsInfo?.byChain['9']?.byProtocol || {})) {
          total += Number(formatBigNumberNoDecimal(BigInt(points?.current || '0'), 18, false))
        }
        byChain['Zksync'] = formatStringToNumberCompactNoDecimal(total?.toString() || '0', true)
        superTotal += total
      }

      if (chain === '11') {
        let total: number = 0
        for (const points of Object.values(pointsInfo?.byChain['11']?.byProtocol || {})) {
          total += Number(formatBigNumberNoDecimal(BigInt(points?.current || '0'), 18, false))
        }
        byChain['Sophon'] = formatStringToNumberCompactNoDecimal(total?.toString() || '0', true)
        superTotal += total
      }
    }

    byChain['Referrals'] = formatBigNumberCompactNoDecimals(BigInt(pointsInfo?.referralPoints?.total || '0'), 18, true)
    superTotal += Number(formatBigNumberNoDecimal(BigInt(pointsInfo?.referralPoints?.total || '0'), 18, false))

    return [
      byChain,
      formatStringToNumberNoDecimal(superTotal?.toString() || '0', true),
      formatStringToNumberCompactNoDecimal(superTotal?.toString() || '0', true),
    ]
  }, [pointsInfo])

  const formattedsUsnPrice = useMemo(() => {
    return formatStringToNumber3(susnPrice?.data?.sUsnPrice || '0', true)
  }, [susnPrice.data])

  const c25 = useMemo(() => {
    return (0.25 * 4 * 1e6) / 100
  }, [])

  const c26 = useMemo(() => {
    const _points = Math.floor(Number(formatBigNumber(BigInt(points || '0'), 18, false) || '0'))
    return (_points * 100) / 48600000000
  }, [points])

  const estimatedValue = useMemo(() => {
    return (c25 * c26 * (4 + 1)) / 5
  }, [c25, c26])

  const onToggleUsnHoldingsOpen = (open: boolean) => setIsUsnHoldingsOpen(open)
  const onTogglesUsnHoldingsOpen = (open: boolean) => setIssUsnHoldingsOpen(open)

  useEffect(() => {
    fetchPointsInfo()
  }, [account.address, account.chainId])

  return (
    <div
      className={`w-full h-full grid grid-cols-1 md:grid-cols-2 md:border-l-[1px] border-b-[1px] ${theme.isLightTheme ? 'border-dashboard-light-mode-hero-section' : 'border-stone-800'} rounded-tr-2xl`}
    >
      <div
        className={`w-full flex flex-col items-start justify-start gap-0 p-0 m-0 md:border-r-[1px] ${theme.isLightTheme ? 'border-dashboard-light-mode-hero-section' : 'border-stone-800'}`}
      >
        <div
          className={`grow w-full flex flex-col items-start justify-between p-4 m-0 gap-4 border-b-[1px] ${theme.isLightTheme ? 'border-dashboard-light-mode-hero-section' : 'border-stone-800'}`}
        >
          <div className={`w-full flex flex-col items-start justify-center gap-2`}>
            <div className="w-full max-w-full flex items-center justify-start gap-1">
              <p
                className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
              >
                USN Holdings
              </p>
              <Tooltip
                hasArrow={true}
                label={<div>Please allow up to 10m for any bridged assets to re-appear in your wallet.</div>}
                bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                placement="top"
                borderRadius={'rounded-lg'}
                paddingX={'px-3'}
                paddingY={'py-[6px]'}
                className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
              >
                <div className={`h-4 w-4 ${theme.isLightTheme ? 'text-stone-400' : 'text-stone-500'} `}>
                  <InfoFilledIcon className="w-full h-full" />
                </div>
              </Tooltip>
            </div>

            <div className="w-full flex flex-row items-center justify-between gap-2">
              <div className="flex flex-col items-start justify-start gap-[2px]">
                <div className="flex items-center justify-start gap-1">
                  <div className="w-6 h-6">
                    <img alt="USN" className="w-full h-full" src={USN} />
                  </div>
                  <p
                    className={`font-Suisse-Intl font-semibold text-2xl ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                  >
                    {isEmpty ? '0.00' : formattedTotalUsnHoldings}
                  </p>
                </div>
                <p className={`font-Suisse-Intl font-[450] text-sm text-stone-400`}>
                  ${isEmpty ? '0.00' : formattedTotalUsnHoldings}
                </p>
              </div>

              <div className="flex flex-col items-end justify-start gap-[2px]">
                <p
                  className={`font-Suisse-Intl font-medium text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                >
                  $1.000
                </p>
                <p className={`font-Suisse-Intl font-[450] text-sm text-stone-400`}>Current Price</p>
              </div>
            </div>
          </div>

          <DropdownMenu open={isUsnHoldingsOpen} onOpenChange={onToggleUsnHoldingsOpen} defaultOpen={false}>
            <DropdownMenuTrigger
              id="points-menu-option-button"
              className={`w-fit cursor-pointer flex gap-[2px] rounded-[56px] border-[1px] py-[4px] px-[6px] ${theme.isLightTheme ? 'bg-white border-stone-300' : 'bg-stone-800 border-stone-700'} items-center justify-center dashboard-points-menu-button`}
            >
              <div className="w-15 max-w-full flex -space-x-2 rtl:space-x-reverse">
                {Object.keys(usnHoldingsGraphQl?.data || {})
                  .reverse()
                  .map((chain) => (
                    <img
                      key={chain}
                      className="w-5 h-5 rounded-[100px]"
                      src={`${process.env.PUBLIC_URL}/assets/chains/${chain?.toLowerCase() || 'ethereum'}.svg`}
                      alt=""
                    />
                  ))}
              </div>
              {isUsnHoldingsOpen ? (
                <div className={`w-5 h-5 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}>
                  <ChevronBigUpIcon className="w-full h-full" />
                </div>
              ) : (
                <div className={`w-5 h-5 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}>
                  <ChevronBigDownIcon className="w-full h-full leading-6" />
                </div>
              )}
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className={`w-full max-w-full rounded-xl border-[1px] p-2 ${theme.isLightTheme ? 'bg-white border-stone-200' : 'bg-stone-800 border-stone-700'} w-fit dashboard-points-menu-list-container z-50`}
            >
              <p
                className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
              >
                USN Holdings
              </p>

              {Object.keys(usnHoldingsGraphQl?.data || {}).map((chain, i) => (
                <DropdownMenuItem
                  key={i}
                  className="w-full max-w-full flex flex-row rounded-lg p-2 gap-4 items-center justify-between"
                >
                  <div className="w-full flex flex-row items-center justify-start gap-1">
                    <div className={`h-5 w-5 rounded-[100px]`}>
                      <img
                        className="w-full h-full object-contain"
                        src={`${process.env.PUBLIC_URL}/assets/chains/${chain.toLowerCase() || '1'}.svg`}
                      />
                    </div>
                    <p
                      className={`w-fit font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'} capitalize justify-self-end text-right`}
                    >
                      {chain}
                    </p>
                  </div>

                  <div className="w-full flex flex-row items-center justify-end gap-1">
                    <div className={`h-4 w-4`}>
                      <img src={USN} className="w-full h-full object-contain" />
                    </div>
                    <p
                      className={`w-fit font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                    >
                      {formatStringToNumber(usnHoldingsGraphQl?.data[chain] || '0', true)}
                    </p>
                  </div>
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        <div
          className={`grow w-full flex flex-col items-start justify-between p-4 m-0 gap-4 border-b-[1px] md:border-b-0 ${theme.isLightTheme ? 'border-dashboard-light-mode-hero-section' : 'border-stone-800'}`}
        >
          <div className={`w-full flex flex-col items-start justify-center gap-2`}>
            <div className="w-full max-w-full flex items-center justify-start gap-1">
              <p
                className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
              >
                sUSN Holdings
              </p>
              <Tooltip
                hasArrow={true}
                label={<div>Please allow up to 10m for any bridged assets to re-appear in your wallet.</div>}
                bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                placement="top"
                borderRadius={'rounded-lg'}
                paddingX={'px-3'}
                paddingY={'py-[6px]'}
                className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
              >
                <div className={`h-4 w-4 ${theme.isLightTheme ? 'text-stone-400' : 'text-stone-500'} `}>
                  <InfoFilledIcon className="w-full h-full" />
                </div>
              </Tooltip>
            </div>

            <div className="w-full flex flex-row items-center justify-between gap-2">
              <div className="flex flex-col items-start justify-start gap-[2px]">
                <div className="flex items-center justify-start gap-1">
                  <div className="w-6 h-6">
                    <img alt="sUSN" className="w-full h-full" src={sUSN} />
                  </div>
                  <p
                    className={`font-Suisse-Intl font-semibold text-2xl ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                  >
                    {isEmpty ? '0.00' : formattedTotalsUsnHoldings}
                  </p>
                </div>
                <p className={`font-Suisse-Intl font-[450] text-sm text-stone-400`}>
                  ${isEmpty ? '0.00' : formattedTotalsUsnDollars}
                </p>
              </div>

              <div className="flex flex-col items-end justify-start gap-[2px]">
                <p
                  className={`font-Suisse-Intl font-medium text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                >
                  ${formattedsUsnPrice}
                </p>
                <div className="flex items-center justify-center gap-1">
                  <p className={`font-Suisse-Intl font-[450] text-sm leading-5 text-stone-400`}>Current Price</p>
                  <Tooltip
                    hasArrow={true}
                    label={
                      <div>{`The price of sUSN is based on historic returns to date, and will update daily.`}</div>
                    }
                    bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                    color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                    placement="top"
                    borderRadius={'rounded-lg'}
                    paddingX={'px-3'}
                    paddingY={'py-[6px]'}
                    className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
                  >
                    <div
                      className={`w-[14px] h-[14px] leading-5 ${theme.isLightTheme ? 'text-stone-300' : 'text-stone-500'}`}
                    >
                      <InfoFilledIcon className="w-full h-full" />
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>

          <DropdownMenu open={issUsnHoldingsOpen} onOpenChange={onTogglesUsnHoldingsOpen} defaultOpen={false}>
            <DropdownMenuTrigger
              id="points-menu-option-button"
              className={`w-fit cursor-pointer flex gap-[2px] rounded-[56px] border-[1px] py-[4px] px-[6px] ${theme.isLightTheme ? 'bg-white border-stone-300' : 'bg-stone-800 border-stone-700'} items-center justify-center dashboard-points-menu-button`}
            >
              <div className="w-15 max-w-full flex -space-x-2 rtl:space-x-reverse">
                {Object.keys(sUsnHoldingsGraphQl?.data || {})
                  .reverse()
                  .map((chain) => (
                    <img
                      key={chain}
                      className="w-5 h-5 rounded-[100px]"
                      src={`${process.env.PUBLIC_URL}/assets/chains/${chain?.toLowerCase() || 'ethereum'}.svg`}
                      alt=""
                    />
                  ))}
              </div>
              {issUsnHoldingsOpen ? (
                <div className={`w-5 h-5 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}>
                  <ChevronBigUpIcon className="w-full h-full" />
                </div>
              ) : (
                <div className={`w-5 h-5 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}>
                  <ChevronBigDownIcon className="w-full h-full leading-6" />
                </div>
              )}
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className={`w-full max-w-full rounded-xl border-[1px] p-2 ${theme.isLightTheme ? 'bg-white border-stone-200' : 'bg-stone-800 border-stone-700'} w-fit dashboard-points-menu-list-container z-50`}
            >
              <p
                className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
              >
                sUSN Holdings
              </p>

              {Object.keys(sUsnHoldingsGraphQl?.data || {}).map((chain, i) => (
                <DropdownMenuItem
                  key={i}
                  className="w-full max-w-full flex flex-row rounded-lg p-2 gap-4 items-center justify-between"
                >
                  <div className="w-full flex flex-row items-center justify-start gap-1">
                    <div
                      className={`h-5 w-5 ${theme.isLightTheme ? 'border-[1px] border-white' : 'border-[1px] border-stone-500'} rounded-[100px]`}
                    >
                      <img
                        className="w-full h-full object-contain"
                        src={`${process.env.PUBLIC_URL}/assets/chains/${chain.toLowerCase() || '1'}.svg`}
                      />
                    </div>
                    <p
                      className={`w-fit font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'} capitalize justify-self-end text-right`}
                    >
                      {chain}
                    </p>
                  </div>

                  <div className="w-full flex flex-row items-center justify-end gap-1">
                    <div className={`h-4 w-4`}>
                      <img src={sUSN} className="w-full h-full object-contain" />
                    </div>
                    <p
                      className={`w-fit font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                    >
                      {formatStringToNumber(sUsnHoldingsGraphQl?.data[chain] || '0', true)}
                    </p>
                  </div>
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      <div
        className={`w-full h-full flex flex-col gap-0 m-0 p-0 ${theme.isLightTheme ? 'border-stone-300' : 'border-stone-800'} data-container rounded-2xl`}
      >
        <div
          className={`relative grow w-full flex flex-col gap-2 items-start justify-between p-4 ${theme.isLightTheme ? 'bg-white' : 'bg-stone-900'} rounded-tr-2xl overflow-hidden`}
        >
          <div className="absolute right-0 -top-0 md:-right-0 md:top-20 p-0 m-0 w-32 h-28 md:w-56 md:h-60 z-20">
            <img src={successIconGroupClipped} alt="USN icons" className="w-full h-full p-0 m-0" />
          </div>

          <div className="absolute right-0 -top-0 md:-right-0 p-0 m-0 w-full h-full z-10">
            <img src={elipsis39} alt="USN icons" className="w-full h-full p-0 m-0" />
          </div>

          <div className="w-full flex flex-col gap-2 z-[100] bg-transparent">
            <p
              className={`font-Suisse-Intl font-[450] text-2xl ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
            >
              {totalPointsFormattedCompact || '0'}
            </p>

            <div className={`w-fit flex items-center justify-center gap-1 z-[1000]`}>
              <p
                className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
              >
                Total Points
              </p>
              <Tooltip
                hasArrow={true}
                label={
                  <div className="w-full max-w-full flex flex-col gap-1">
                    <p>
                      <b>{`This is your current point balance.`}</b>
                      {` Points can be converted to our governance token
                      ($NOON) when we launch it (~Q2 2025).`}
                    </p>
                    <p className="text-xs">
                      A more detailed “Rewards” page is coming soon! There may be temporary discrepancies in your points
                      balance during our Public Beta. Rest assured, we are tracking all relevant on-chain events, and
                      will resolve all discrepancies.
                    </p>
                  </div>
                }
                bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                placement="top"
                borderRadius={'rounded-lg'}
                paddingX={'px-3'}
                paddingY={'py-[6px]'}
                className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
              >
                <div className={`w-6 h-6 ${theme.isLightTheme ? 'text-stone-300' : 'text-stone-500'}`}>
                  <InfoFilledIcon className="w-full h-full" />
                </div>
              </Tooltip>
            </div>
          </div>

          <div className="w-full max-w-full flex flex-col items-center justify-start gap-0 z-[100]">
            <p
              className={`w-fit font-Suisse-Intl font-medium text-sm rounded-t-lg border-t-[1px] border-x-[1px] border-b-0 py-[2px] px-2 ${theme.isLightTheme ? 'coming-soon-light text-[#AB8F7C] border-[#1111111A]' : 'text-[#FFDAC1] coming-soon-dark border-[#FFFFFF1A]'} text-center`}
            >
              COMING SOON
            </p>
            <p
              className={`w-full font-Suisse-Intl font-medium text-sm rounded-[40px] border-[1px] py-[6px] px-2 ${theme.isLightTheme ? 'points-estimate-calc text-[#AB8F7C] border-[#1111111A]' : 'text-[#FFDAC1] points-estimate-calc-dark border-[#FFFFFF1A]'} text-center flex items-center justify-center gap-1`}
            >
              <div className={`w-4 h-4 ${theme.isLightTheme ? 'text-[#AB8F7C]' : 'text-[#FFDAC1]'}`}>
                <SpinnerIcon className="w-full h-full font-medium" />
              </div>
              Estimate the value of your points
            </p>
          </div>

          {/* <div className="w-full grid grid-rows-1 grid-cols-2 items-center justify-between gap-1"> */}
          {/* <Tooltip
              hasArrow={true}
              label={`Coming soon`}
              bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
              color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
              placement="top"
              borderRadius={'rounded-lg'}
              paddingX={'px-3'}
              paddingY={'py-[6px]'}
              className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit`}
            >
              <div
                id="points-menu-option-button"
                className={`w-fit cursor-pointer flex gap-[2px] rounded-[56px] border-[1px] py-[4px] px-[6px] ${theme.isLightTheme ? 'bg-white border-stone-300' : 'bg-stone-800 border-stone-700'} items-center justify-center dashboard-points-menu-button`}
              >
                <div className="w-15 max-w-full flex -space-x-2 rtl:space-x-reverse">
                  {Object.keys(sUsnHoldingsGraphQl?.data || {})
                    .reverse()
                    .map((chain) => (
                      <img
                        key={chain}
                        className="w-5 h-5 rounded-[100px]"
                        src={`${process.env.PUBLIC_URL}/assets/chains/${chain?.toLowerCase() || 'ethereum'}.svg`}
                        alt=""
                      />
                    ))}
                </div>
                {false ? (
                  <div className={`w-5 h-5 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}>
                    <ChevronBigUpIcon className="w-full h-full" />
                  </div>
                ) : (
                  <div className={`w-5 h-5 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}>
                    <ChevronBigDownIcon className="w-full h-full leading-6" />
                  </div>
                )}
              </div>
            </Tooltip> */}

          {/* <div className="grow w-auto justify-self-end">
              <p
                className={`font-Suisse-Intl font-medium text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'} text-right`}
              >
                Coming soon
              </p>
              <p
                className={`font-Suisse-Intl font-[450] text-sm ${theme.isLightTheme ? 'text-stone-400' : 'text-stone-400'} text-right`}
              >
                Points estimate
              </p>
            </div> */}
          {/* </div> */}
        </div>

        {/* <button
          type="button"
          disabled={!onOpen}
          onClick={() => {}}
          className={`cursor-pointer w-full h-fit flex items-center justify-center gap-2 py-3 px-4 border-t-[1px] ${theme.isLightTheme ? 'border-t-stone-200 bg-stone-100' : 'border-t-stone-700 bg-stone-800'}`}
        >
          <p
            className={`font-Suisse-Intl font-medium text-sm ${theme.isLightTheme ? 'text-stone-600' : 'text-stone-50'} text-center`}
          >
            Estimate the value of your points (Coming soon)
          </p>
          <div className={`w-6 h-6 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-200'}`}>
            <ArrowRightUpIcon className="w-full h-full" />
          </div>
        </button>*/}
      </div>
    </div>
  )
}

export default Holdings
