/* eslint-disable react/react-in-jsx-scope */
import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react'

import { useCallback, useContext, useMemo, useState } from 'react'

import { SpinnerIcon } from '@chakra-ui/icons'
import { useSignMessage } from 'wagmi'

import { ReactComponent as CheckTickCircleIcon } from '../../assets/success/check-tick-circle.svg'
import { ReactComponent as MultipleCrossIcon } from '../../assets/navigation/multiple-cross.svg'
import defaultAvatarLight from '../../assets/dashboard/user-light.svg'
import defaultAvatarDark from '../../assets/dashboard/user-dark.svg'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { ThemeContext } from '../../context/ThemeProvider'
import { EApiCallStatus, TAddress } from '../../types'
import avatar from '../../assets/avatar.png'

interface IProps {
  isOpen: boolean
  onClose: () => void
  profileUsername: string
  walletAddress: TAddress | undefined
  usernameRefetch: () => void
}

const UserProfileEditModal = (props: IProps) => {
  const theme = useContext(ThemeContext)
  const { isOpen, onClose, profileUsername } = props

  const [inputUsername, setInputUsername] = useState<string>('')
  const [saveButtonText, setSaveButtonText] = useState<string>('Save')
  const [profileUpdateStatus, setProfileUpdateStatus] = useState<EApiCallStatus>(EApiCallStatus.IDLE)

  const { signMessage } = useSignMessage({ config: rainbowKitConfig })

  const onChangeInputUsername = (event: React.FormEvent<HTMLInputElement>) => {
    setInputUsername((event?.target as HTMLInputElement)?.value)
  }

  const isActionDisabled = useMemo(() => {
    return !inputUsername || !inputUsername.length
  }, [inputUsername])

  const sendSaveProfileReq = async (message: number, signature: string, userName: string, walletAddress: TAddress) => {
    await fetch(`${process.env.REACT_APP_BASE_API_URL ?? ''}/api/v1/user/${walletAddress}/username`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: userName,
        message: message,
        signature,
      }),
    })
      .then(
        (res) => {
          if (res.ok && res.status === 200) {
            props.usernameRefetch()
            setProfileUpdateStatus(EApiCallStatus.API_CALL_SUCCESS)
            props.onClose()
            return
          }
          throw new Error('Error')
        },
        (err) => {
          throw new Error('Error' + err?.message)
        },
      )
      .catch((err: Error) => {
        console.error('Error saving profile from update username API:', err)
        setProfileUpdateStatus(EApiCallStatus.API_CALL_FAILURE)
        setSaveButtonText('Try again')
        setTimeout(() => {
          setProfileUpdateStatus(EApiCallStatus.IDLE)
          setSaveButtonText('Save')
        }, 30 * 1000)
      })
  }

  const signMintData = useCallback(() => {
    const message = Math.floor(Math.random() * 100000000000)
    const MESSAGE = `Authentication ${props.walletAddress?.toLowerCase()}`

    setProfileUpdateStatus(EApiCallStatus.PROCESSING)
    setSaveButtonText('Saving...')

    if (!props.walletAddress || !props.walletAddress?.length) {
      setProfileUpdateStatus(EApiCallStatus.API_CALL_FAILURE)
      setSaveButtonText('Try again')
      return
    }

    signMessage(
      { message: MESSAGE },
      {
        onError: () => {
          setProfileUpdateStatus(EApiCallStatus.API_CALL_FAILURE)
          setSaveButtonText('Try again')
          setTimeout(() => {
            setProfileUpdateStatus(EApiCallStatus.IDLE)
            setSaveButtonText('Save')
          }, 30 * 1000)
        },
        onSuccess: (data) => {
          if (!props.walletAddress || !props.walletAddress?.length) {
            setProfileUpdateStatus(EApiCallStatus.API_CALL_FAILURE)
            setSaveButtonText('Try again')
            return
          }

          setSaveButtonText('Saving...')
          sendSaveProfileReq(message, data, inputUsername, props.walletAddress)
        },
      },
    )
  }, [inputUsername, signMessage, props.walletAddress])

  return (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus={false}>
      <ModalOverlay />
      <ModalContent
        borderRadius={'8px'}
        background={theme.isLightTheme ? '#FFFFFF' : '#1C1917'}
        className={`relative top-44 w-full rounded-lg p-6 gap-6 ${theme.isLightTheme ? 'bg-white' : 'bg-stone-900'} dashboard-profile-edit-modal`}
      >
        <div
          className={`absolute h-5 w-5 cursor-pointer ${theme.isLightTheme ? 'text-stone-400' : 'text-stone-600'} right-5 top-5 outline-none`}
          onClick={onClose}
        >
          <MultipleCrossIcon className="w-full h-full" />
        </div>

        <div className="w-full flex flex-col items-center justify-center gap-4">
          <div className="w-[72px] h-[72px]">
            <img src={avatar} alt="Profile" className="w-full h-full rounded-[666px] object-cover" />
          </div>
          <p className={`font-Louize font-normal text-4xl ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}>
            {profileUsername}
          </p>
          <div className="w-full gap-1 flex flex-col items-start justify-center">
            <p
              className={`w-full font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-600'} text-left`}
            >
              Username
            </p>
            <input
              type="text"
              value={inputUsername}
              onChange={onChangeInputUsername}
              placeholder="Please enter your username"
              className={`rounded-lg border-[1px] py-3 px-4 bg-transaparent ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-800'} w-full font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-900 placeholder:text-stone-500' : 'text-stone-50 placeholder:text-stone-600'} outline-none`}
            ></input>
          </div>
        </div>

        <div className="w-full flex flex-col md:flex-row gap-4 items-center justify-center">
          <button
            onClick={signMintData}
            disabled={isActionDisabled || profileUpdateStatus === EApiCallStatus.PROCESSING}
            className={`grow w-full md:w-auto rounded-[184px] py-2 px-3 gap-1 ${isActionDisabled ? (theme.isLightTheme ? 'bg-indigo-400' : 'bg-indigo-900') : 'bg-indigo-600'} flex items-center justify-center ${profileUpdateStatus === EApiCallStatus.PROCESSING ? 'animate-pulse' : ''}`}
          >
            {profileUpdateStatus === EApiCallStatus.PROCESSING && (
              <SpinnerIcon
                className={`w-[17px] h-[17px] ${isActionDisabled ? (theme.isLightTheme ? 'text-indigo-200' : 'text-indigo-600') : 'text-stone-50'} animate-spin`}
                height={'h-[17px]'}
                width={'w-[17px]'}
                stroke={
                  isActionDisabled ? (theme.isLightTheme ? 'text-indigo-200' : 'text-indigo-600') : 'text-stone-50'
                }
                color={
                  isActionDisabled ? (theme.isLightTheme ? 'text-indigo-200' : 'text-indigo-600') : 'text-stone-50'
                }
              />
            )}
            {profileUpdateStatus !== EApiCallStatus.PROCESSING &&
              profileUpdateStatus !== EApiCallStatus.API_CALL_FAILURE && (
                <div
                  className={`w-[17px] h-[17px] ${isActionDisabled ? (theme.isLightTheme ? 'text-indigo-200' : 'text-indigo-600') : 'text-stone-50'}`}
                >
                  <CheckTickCircleIcon className="w-full h-full" />
                </div>
              )}
            <p
              className={`font-Suisse-Intl font-medium text-base ${isActionDisabled ? (theme.isLightTheme ? 'text-indigo-200' : 'text-indigo-600') : 'text-stone-50'}`}
            >
              {saveButtonText}
            </p>
          </button>

          <button
            onClick={onClose}
            className={`grow w-full md:w-auto flex rounded-[184px] border-[1px] py-2 px-3 gap-1 ${theme.isLightTheme ? 'bg-stone-100 border-stone-300 ' : 'bg-stone-900 border-stone-800'} items-center justify-center`}
          >
            <p
              className={`font-Suisse-Intl font-medium text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
            >
              Cancel
            </p>
          </button>
        </div>
      </ModalContent>
    </Modal>
  )
}

export default UserProfileEditModal
