import React, { useContext, useEffect, useMemo, useState } from 'react'

import { ThemeContext } from '../../context/ThemeProvider'
import { BuyContext } from '../../context/BuyProviderNew'
import useBuyGas from '../../hooks/multichain/useBuyGas'
import { EApiCallStatus, ETxnStatus } from '../../types'
import useBuy from '../../hooks/multichain/txns/useBuy'
import ActionInAndOutInfo from '../ActionInAndOutInfo'
import { formatStringToNumber } from '../../utils'
import NetworkFee from '../NetworkFee/NetworkFee'
import ConnectButton from '../ConnectButton'

interface IProps {
  setTxnUrl: (url: string) => void
}

const BuyConfirm = (props: IProps) => {
  const [btnLabel, setBtnLabel] = useState<string>('Confirm')

  const gas = useBuyGas()
  const buy = useContext(BuyContext)
  const onSwap = useBuy(props.setTxnUrl)
  const theme = useContext(ThemeContext)

  const isDisabled = useMemo(() => {
    return (
      !buy.buyState.amountIn ||
      !buy.buyState.amountOut ||
      !Number(buy.buyState.amountIn) ||
      !Number(buy.buyState.amountOut)
    )
  }, [buy.buyState.amountIn, buy.buyState.amountOut])

  const isLoading = useMemo(() => {
    return (
      buy.buyTxnStatus === ETxnStatus.PROCESSING ||
      buy.chainChangeStatus === EApiCallStatus.PROCESSING ||
      buy.buyTxnStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN
    )
  }, [buy.chainChangeStatus, buy.buyTxnStatus])

  const formattedAmountIn = useMemo(
    () => formatStringToNumber(Number(buy.buyState.amountIn) ? buy.buyState.amountIn : '', true),
    [props, formatStringToNumber],
  )

  const formattedAmountOut = useMemo(
    () => formatStringToNumber(Number(buy.buyState.amountOut) ? buy.buyState.amountOut : '', true),
    [props, formatStringToNumber],
  )

  useEffect(() => {
    if (buy.buyTxnStatus === ETxnStatus.IDLE) {
      setBtnLabel('Confirm')
      return
    }

    if (buy.buyTxnStatus === ETxnStatus.PROCESSING || buy.buyTxnStatus === ETxnStatus.TXN_SUBMITTED_ON_CHAIN) {
      setBtnLabel('Confirming...')
      return
    }

    if (buy.buyTxnStatus === ETxnStatus.TXN_FAILURE) {
      setBtnLabel('Try again')
      return
    }

    setBtnLabel('Confirmed')
    buy.setScreen('success')
  }, [buy.buyTxnStatus])

  return (
    <React.Fragment>
      <p
        className={`font-Louize font-normal text-4xl tracking-tighter ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
      >
        Confirm your buy transaction
      </p>

      <ActionInAndOutInfo
        labelIn="Swapping"
        labelOut="To get back"
        tokenIn={buy.buyState.tokenIn}
        tokenOut={buy.buyState.tokenOut}
        amountIn={formattedAmountIn}
        amountOut={formattedAmountOut}
      />

      <div className="w-full max-w-full grid grid-rows-1 grid-cols-2 gap-2">
        <NetworkFee type={'data-field'} action={'swap'} gasLimit={gas?.data || undefined} />

        <div className="w-fit max-w-full flex gap-2 items-center justify-end justify-self-end">
          <p
            className={`font-Suisse-Intl font-[450] text-base text-right ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
          >
            Max Slippage
          </p>
          <p
            className={`font-Suisse-Intl font-semibold text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
          >
            1%
          </p>
        </div>
      </div>
      <ConnectButton
        label={btnLabel}
        onClick={onSwap}
        isLoading={isLoading}
        useFullAvailableWidth
        isDisabled={isDisabled}
        useResponsiveSizes={false}
        actionType="primary-action"
        actionIcon={'success-tick'}
      />
    </React.Fragment>
  )
}

export default BuyConfirm
