import { useAccount } from 'wagmi'

import ERC20Abi from '../../constants/abis/ERC20.json'

import { Contract } from 'zksync-ethers'

import { JsonRpcProvider } from 'ethers'

import { useQuery } from '@tanstack/react-query'

import { maxUint256 } from 'viem'

import { useContext } from 'react'

import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { BridgeContext } from '../../context/BridgeProvider'
import { REFETCH_INTERVAL } from '../../utils'
import { DAPP_CONFIG } from '../../constants'

const useBridgeAllowance = () => {
  const bridge = useContext(BridgeContext)
  const account = useAccount({ config: rainbowKitConfig })

  const fetch = async () => {
    if (
      !bridge ||
      !account ||
      !bridge.token ||
      !account.chain ||
      !account.address ||
      !account.chainId ||
      !account.isConnected ||
      bridge.source !== account.chainId
    ) {
      return BigInt(0)
    }
    const config = DAPP_CONFIG[bridge.source]
    if (!config) {
      return BigInt(0)
    }
    const _token = config.tokens[bridge.token]
    if (!_token) {
      return BigInt(0)
    }

    if (!config.layerZeroOftHelper && _token.contractAddress === _token.oftAddress) {
      return maxUint256
    }

    const spender = config.layerZeroOftHelper || _token.oftAddress
    const contract = new Contract(
      _token.contractAddress,
      ERC20Abi,
      new JsonRpcProvider(config.rpcUrl || account.chain.rpcUrls.default.http.at(0)),
    )
    return contract.allowance(account.address, spender)
  }

  return useQuery({
    queryFn: fetch,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    initialData: () => BigInt(0),
    placeholderData: () => BigInt(0),
    refetchInterval: REFETCH_INTERVAL,
    refetchIntervalInBackground: false,
    select: (data) => (data || BigInt(0)) as bigint,
    enabled: Boolean(bridge) && Boolean(bridge.token) && account.isConnected,
    queryKey: ['allowance', account.address, bridge.token, bridge.source, bridge.destination],
  })
}

export default useBridgeAllowance
