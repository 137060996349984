import React, { useCallback, useContext, useState } from 'react'

import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'

import { useAccount, useSwitchChain } from 'wagmi'

import { Chain } from 'wagmi/chains'

import { ReactComponent as ChevronBigDownIcon } from '../../assets/navigation/chevron-big-down.svg'
import { ReactComponent as ChevronBigUpIcon } from '../../assets/navigation/chevron-big-up.svg'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { ThemeContext } from '../../context/ThemeProvider'
import { BRIDGE_NETWORKS } from '../../constants'
import { EApiCallStatus } from '../../types'

interface IProps {
  classes: string
}

const NetworkSelector = (props: IProps) => {
  const [chainChangeStatus, setChainChangeStatus] = useState<EApiCallStatus>(EApiCallStatus.IDLE)

  const theme = useContext(ThemeContext)
  const account = useAccount({ config: rainbowKitConfig })
  const switchChain = useSwitchChain({ config: rainbowKitConfig })

  const switchNetwork = useCallback(
    (sourceNetwork: Chain['id']) => {
      setChainChangeStatus(EApiCallStatus.PROCESSING)

      switchChain.switchChain(
        { chainId: sourceNetwork as 1 | 50104 | 324 },
        {
          onError: () => {
            setChainChangeStatus(EApiCallStatus.API_CALL_FAILURE)
            setTimeout(() => {
              setChainChangeStatus(EApiCallStatus.IDLE)
            }, 30 * 1000)
          },
          onSuccess: () => {
            setChainChangeStatus(EApiCallStatus.PROCESSING)
          },
          onSettled: async (data) => {
            if (!data || data.id !== sourceNetwork) {
              setChainChangeStatus(EApiCallStatus.API_CALL_FAILURE)
              setTimeout(() => {
                setChainChangeStatus(EApiCallStatus.IDLE)
              }, 30 * 1000)
            } else {
              setChainChangeStatus(EApiCallStatus.API_CALL_SUCCESS)
              setTimeout(() => {
                setChainChangeStatus(EApiCallStatus.IDLE)
              }, 30 * 1000)
            }
          },
        },
      )
    },
    [switchChain, setChainChangeStatus],
  )

  return (
    <Menu placement="bottom">
      {({ isOpen }) => (
        <div className={`${props.classes} z-[999]`}>
          <MenuButton padding={0} margin={0}>
            <div
              className={`w-fit flex items-center justify-around rounded-[184px] border-[1px] p-[6px] gap-[6px] ${theme.isLightTheme ? 'bg-stone-100 border-stone-300' : 'bg-stone-900 border-stone-800'} ${chainChangeStatus === EApiCallStatus.PROCESSING ? 'animate-pulse' : ''}`}
            >
              <div className="h-8 w-8 md:h-9 md:w-9">
                <img
                  alt="Network Switcher"
                  className="h-full w-full object-cover"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null
                    currentTarget.src = `${process.env.PUBLIC_URL}/assets/chains/1.svg`
                  }}
                  src={`${process.env.PUBLIC_URL}/assets/chains/${account?.chainId || 1}.svg`}
                />
              </div>
              {isOpen ? (
                <div className={`w-6 h-6 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}>
                  <ChevronBigUpIcon className="w-full h-full" />
                </div>
              ) : (
                <div className={`w-6 h-6 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}>
                  <ChevronBigDownIcon className="w-full h-full" />
                </div>
              )}
            </div>
          </MenuButton>
          <MenuList
            background={theme.isLightTheme ? '#FFFFFF' : '#000000'}
            borderWidth={0}
            padding={0}
            margin={0}
            borderRadius={'12px'}
          >
            <div
              className={`w-full max-w-full rounded-xl p-1 border-[1px] ${theme.isLightTheme ? 'bg-white border-stone-200' : 'bg-black border-stone-800'}`}
            >
              {BRIDGE_NETWORKS.map((network) => (
                <MenuItem
                  onClick={() => switchNetwork(network.id)}
                  key={network.id}
                  padding={0}
                  margin={0}
                  background={'transparent'}
                  borderWidth={0}
                >
                  <div
                    id="navbar-network-dropdown"
                    className={`w-full cursor-pointer flex gap-2 p-2 items-center justify-center rounded-lg ${theme.isLightTheme ? 'text-stone-900 hover:bg-stone-100' : 'text-stone-50 hover:bg-stone-900'}`}
                  >
                    <div className="w-9 h-9">
                      <img
                        alt="Network Switcher"
                        className="h-full w-full object-cover"
                        src={`${process.env.PUBLIC_URL}/assets/chains/${network.config.icon || 'ethereum'}.svg`}
                      />
                    </div>
                    <p className={`grow font-Suisse-Intl font-medium text-base text-left`}>{network.name}</p>
                  </div>
                </MenuItem>
              ))}
            </div>
          </MenuList>
        </div>
      )}
    </Menu>
  )
}

export default NetworkSelector
