import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react'

import React, { useContext } from 'react'

import { useLocation } from 'react-router-dom'

import { ReactComponent as ExclamationMarkIcon } from '../../assets/server-exclamation-mark.svg'
import errorGlobeDark from '../../assets/error-globe-dark.png'
import elipsis39 from '../../assets/dashboard/elipsis-39.png'
import { ThemeContext } from '../../context/ThemeProvider'
import errorGlobe from '../../assets/error-globe.png'

const ProhibitedState = ({ isOpen = false }: { isOpen: boolean }) => {
  const theme = useContext(ThemeContext)

  return (
    <Modal isOpen={isOpen} onClose={() => {}} autoFocus={false}>
      <ModalOverlay />
      <ModalContent
        borderWidth={'1px'}
        borderRadius={'12px'}
        background={theme.isLightTheme ? '#ECEDF6' : '#1C1917'}
        borderColor={theme.isLightTheme ? '#D6D3D1' : '#312D2C'}
        className={`relative top-24 min-w-[38%] w-fit max-w-full rounded-lg p-6 gap-6 ${theme.isLightTheme ? 'bg-[#ECEDF6] border-[#D6D3D1]' : 'bg-[#1C1917] border-[#312D2C]'} dashboard-profile-edit-modal`}
      >
        <div className="realtive w-full max-w-full flex flex-col items-start justify-center gap-6 overflow-hidden rounded-lg">
          <div className="absolute right-0 -top-0 md:-right-0 p-0 m-0 w-48 h-auto z-10 rounded-lg">
            <img
              alt=""
              className="w-full h-full p-0 m-0 rounded-lg"
              src={theme.isLightTheme ? errorGlobe : errorGlobeDark}
            />
          </div>

          <div className="absolute right-0 -top-0 md:-right-0 p-0 m-0 w-full h-full z-20 rounded-lg">
            <img src={elipsis39} alt="" className="w-full h-full p-0 m-0 rounded-lg" />
          </div>

          <div
            className={`w-12 h-12 rounded-[9.6px] border-[1.2px] ${theme.isLightTheme ? 'bg-orange-200 border-orange-400' : 'bg-orange-950 border-orange-900'} flex items-center justify-center z-50`}
          >
            <ExclamationMarkIcon className={`w-5 h-5 ${theme.isLightTheme ? 'text-orange-700' : 'text-orange-200'}`} />
          </div>

          <div className="w-full gap-0 flex flex-col items-center justify-center z-50">
            <p
              className={`w-full font-Louize font-normal text-4xl leading-[44px] tracking-tighter ${theme.isLightTheme ? 'text-stone-900' : 'text-white'} text-left`}
            >
              Noon: Access Denied
            </p>
            <p
              className={`w-full font-Suisse-Intl font-semiMedium text-xl leading-[30px] ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-300'} text-left text-wrap break-words`}
            >
              {`We're sorry, it looks like you're trying to access Noon from a Prohibited State. We're afraid we cannot provide services to users in these states.`}
            </p>
          </div>

          <hr
            className={`w-full max-w-full h-0 border-t-[1px] ${theme.isLightTheme ? 'border-stone-300' : 'border-stone-800'} z-50`}
          />

          <p
            className={`w-full max-w-full text-left text-wrap break-words font-Suisse-Intl font-[450] text-xs leading-[18px] ${theme.isLightTheme ? 'text-stone-600' : 'text-stone-500'} z-50`}
          >
            {`“Prohibited States” means the United States, Afghanistan, Belarus, Bosnia and Herzegovina, Burma, Burundi, Central African Republic, Democratic Republic of Congo, Crimea, Donetsk and Luhansk regions of Ukraine, Cuba, Ethiopia, Haiti, Iran, Iraq, Lebanon, Libya, Mali, Myanmar, Nicaragua, Democratic People’s Republic of Korea (North Korea), Republic of Guinea-Bissau, Pakistan, Somalia, South Sudan, Sudan & Darfur, Syria, Ukraine, Venezuela, Yemen, Zimbabwe, Russia, or any other country, territory or geographical region which is itself the subject or target of any territory-wide sanctions.`}
            <br />
            <br />
            {`The list of Prohibited States is subject to change at any time. `}
          </p>
        </div>
      </ModalContent>
    </Modal>
  )
}

export default ProhibitedState
