import React from 'react'

import type { ReactNode } from 'react'

interface IProps {
  children: ReactNode
}

const Page = (props: IProps) => {
  const { children } = props

  return <div className="min-h-[calc(100vh-72px)] w-full max-w-[1400px] ml-auto mr-auto">{children}</div>
}

export default Page
