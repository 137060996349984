import { mainnet } from '@wagmi/core/chains'

import { useAccount, useChainId, useReadContract } from 'wagmi'

import WithdrawalHandlerABI from '../../constants/abis/WithdrawalHandler.json'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { DAPP_CONFIG } from '../../constants'

const useWithdrawPeriod = () => {
  const chainId = useChainId({ config: rainbowKitConfig })
  const account = useAccount({ config: rainbowKitConfig })

  return useReadContract({
    chainId: mainnet.id,
    config: rainbowKitConfig,
    abi: WithdrawalHandlerABI,
    functionName: 'withdrawPeriod',
    address: DAPP_CONFIG[mainnet.id].stakeWithdrawHandler,
    query: {
      refetchOnMount: true,
      initialData: BigInt(0),
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
      placeholderData: BigInt(0),
      refetchIntervalInBackground: true,
      refetchInterval: DAPP_CONFIG[mainnet.id].dataRefershInterval,
      select: (data) => {
        const amount = (data || BigInt(0)) as bigint
        return Number(amount.toString()) * 1000
      },
      enabled: chainId === mainnet.id && account.isConnected,
    },
  })
}

export default useWithdrawPeriod
