import React, { createContext, useEffect, useState } from 'react'

import { useAccount, useChains } from 'wagmi'

import { mainnet } from 'wagmi/chains'

import { rainbowKitConfig } from '../constants/rainbowKitConfig'
import { EApiCallStatus, ETxnStatus, TScreen } from '../types'
import { DAPP_CONFIG } from '../constants'

export interface IBuyState {
  tokenIn: string
  tokenOut: string
  amountIn: string
  amountOut: string
}

export interface IBuyContext {
  screen: TScreen
  buyState: IBuyState
  buyTokens: string[]
  sellTokens: string[]
  buyTxnStatus: ETxnStatus
  approveTxnStatus: ETxnStatus
  chainChangeStatus: EApiCallStatus
  setScreen: (screen: TScreen) => void
  setBuyTxnStatus: (status: ETxnStatus) => void
  setApproveTxnStatus: (status: ETxnStatus) => void
  setChainChangeStatus: (status: EApiCallStatus) => void
  setBuyState: React.Dispatch<React.SetStateAction<IBuyState>>
}

export const BuyContext = createContext<IBuyContext>({
  buyTokens: [],
  sellTokens: [],
  screen: 'input',
  buyState: { amountIn: '', amountOut: '', tokenIn: 'USDT', tokenOut: 'USN' },
  buyTxnStatus: ETxnStatus.IDLE,
  approveTxnStatus: ETxnStatus.IDLE,
  chainChangeStatus: EApiCallStatus.IDLE,
  setBuyState: () => {},
  setScreen: (screen: TScreen) => {
    console.log('Screen', screen)
  },
  setBuyTxnStatus: (status: ETxnStatus) => {
    console.log('Update status', status)
  },
  setApproveTxnStatus: (status: ETxnStatus) => {
    console.log('Update status', status)
  },
  setChainChangeStatus: (status: EApiCallStatus) => {
    console.log('Status', status)
  },
})

export const BuyContextProvider: React.FC<React.PropsWithChildren> = (props) => {
  const [screen, setScreen] = useState<TScreen>('input')
  const [buyTokens, setBuyTokens] = useState<string[]>([])
  const [sellTokens, setSellTokens] = useState<string[]>([])
  const [buyTxnStatus, setBuyTxnStatus] = useState<ETxnStatus>(ETxnStatus.IDLE)
  const [approveTxnStatus, setApproveTxnStatus] = useState<ETxnStatus>(ETxnStatus.IDLE)
  const [chainChangeStatus, setChainChangeStatus] = useState<EApiCallStatus>(EApiCallStatus.IDLE)
  const [buyState, setBuyState] = useState<IBuyState>({ amountIn: '', amountOut: '', tokenIn: 'USDT', tokenOut: 'USN' })

  const chains = useChains({ config: rainbowKitConfig })
  const account = useAccount({ config: rainbowKitConfig })

  useEffect(() => {
    if (!account.isConnected || !account.address || !account.chainId) {
      setScreen('input')
    }
  }, [chains, account.chainId])

  useEffect(() => {
    if (!account || !account.chainId) {
      return
    }

    const config = DAPP_CONFIG[account.chainId] || DAPP_CONFIG[mainnet.id]
    const newTokenIn = config?.tokensForSell?.at(0) || 'USN'
    const newTokenOut = config?.tokensForBuy?.at(0) || 'USDT'
    setBuyState({
      tokenIn: newTokenIn || 'USDT',
      tokenOut: newTokenOut || 'USN',
      amountIn: '',
      amountOut: '',
    })

    setScreen('input')
    setBuyTxnStatus(ETxnStatus.IDLE)
    setApproveTxnStatus(ETxnStatus.IDLE)
    setBuyTokens(config?.tokensForBuy?.length ? config?.tokensForBuy || [] : [])
    setSellTokens(config?.tokensForSell?.length ? config?.tokensForSell || [] : [])
  }, [account.isConnected, account.address, account.chainId])

  const onSetScreen = (screen: TScreen) => {
    setBuyTxnStatus(ETxnStatus.IDLE)
    setApproveTxnStatus(ETxnStatus.IDLE)
    setScreen(screen)
  }

  return (
    <BuyContext.Provider
      value={{
        screen,
        buyTxnStatus,
        approveTxnStatus,
        chainChangeStatus,
        buyTokens,
        sellTokens,
        buyState,
        setBuyState,
        setBuyTxnStatus,
        setApproveTxnStatus,
        setChainChangeStatus,
        setScreen: onSetScreen,
      }}
    >
      {props.children}
    </BuyContext.Provider>
  )
}

export default BuyContextProvider
