import React, { useContext } from 'react'

import { useAccount } from 'wagmi'

import { ReactComponent as ArrowRightUpIcon } from '../../assets/navigation/arrow-right-up.svg'
import elipsis39 from '../../assets/backgrounds/ellipse-39.png'
import { ThemeContext } from '../../context/ThemeProvider'
import EmptyContainer from '../EmptyContainer/'
import avatar from '../../assets/avatar.png'

const UserProfile = ({ username }: { username: string }) => {
  const account = useAccount()
  const theme = useContext(ThemeContext)

  return (
    <div
      className={`relative w-full max-w-full min-h-full h-full max-h-full flex flex-col items-start justify-between gap-11 p-6`}
    >
      <div className="absolute left-0 top-0 max-h-full max-w-full h-full w-full z-10">
        <img src={elipsis39} className="m-0 p-0 w-full h-full" />
      </div>

      <p
        className={`w-full font-Louize font-normal text-4xl tracking-tighter ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'} text-wrap opacity-60 z-20`}
      >
        Rewards
      </p>

      <div className="w-full flex flex-row gap-4 items-start md:items-center justify-start p-0 z-20">
        <div className="w-[88px] h-[88px] aspect-square shrink-0">
          <img alt="Profile" className="w-full h-full aspect-square rounded-[666px] object-cover" src={avatar} />
        </div>

        <div className="grow flex flex-col items-start justify-center gap-2 p-0">
          <div
            className={`w-full font-Louize font-normal text-3xl md:text-3xl tracking-tighter text-left ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
          >
            Hello, {account.isConnected ? username || 'fren' : 'fren'}!
          </div>
          <a
            target="_blank"
            rel="noreferrer"
            href={`${account?.chain?.blockExplorers?.default.url}/address/${account?.address}`}
            className={`w-full font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} flex justify-start gap-1 items-center`}
          >
            {account.address &&
              ` ${account?.address?.slice(0, 4)}...${account?.address?.slice(account?.address?.length - 5)}`}
            {account.address && (
              <div className={`w-5 h-5 text-stone-400`}>
                <ArrowRightUpIcon className="w-full h-full" />
              </div>
            )}
          </a>
          {!account.address && (
            <EmptyContainer classes={`h-12 w-32 ${theme.isLightTheme ? 'bg-stone-200' : 'bg-stone-800'}`} />
          )}
        </div>
      </div>
    </div>
  )
}

export default UserProfile
