import { useAccount } from 'wagmi'

import OftAbi from '../../constants/abis/OFT.json'

import { Contract } from 'zksync-ethers'

import { JsonRpcProvider } from 'ethers'

import { useQuery } from '@tanstack/react-query'

import { fromBytes, isAddress, parseUnits } from 'viem'

import { addressToBytes32, Options } from '@layerzerolabs/lz-v2-utilities'

import { useContext } from 'react'

import { getAmountPostSlippage, REFETCH_INTERVAL } from '../../utils'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { BridgeContext } from '../../context/BridgeProvider'
import { DAPP_CONFIG } from '../../constants'
import { TBridgeQuote } from '../../types'

const useBridgeFee = () => {
  const bridge = useContext(BridgeContext)
  const account = useAccount({ config: rainbowKitConfig })

  const fetch = async () => {
    if (
      !bridge ||
      !account ||
      !bridge.token ||
      !bridge.amount ||
      !account.chain ||
      !account.address ||
      !account.chainId ||
      !bridge.destination ||
      !account.isConnected ||
      !bridge.receiverAddress ||
      !isAddress(bridge.receiverAddress) ||
      bridge.source !== account.chainId
    ) {
      return { nativeFee: BigInt(0), lzTokenFee: BigInt(0) } as TBridgeQuote
    }

    const _source = DAPP_CONFIG[bridge.source]
    if (!_source) {
      return { nativeFee: BigInt(0), lzTokenFee: BigInt(0) } as TBridgeQuote
    }
    if (_source.layerZeroOftHelper) {
      return { nativeFee: BigInt(0), lzTokenFee: BigInt(0) } as TBridgeQuote
    }

    const _token = _source.tokens[bridge.token]
    if (!_token) {
      return BigInt(0)
    }
    const amountBn = parseUnits(bridge.amount, _token.decimals)
    if (!amountBn || !_token.oftAddress) {
      return { nativeFee: BigInt(0), lzTokenFee: BigInt(0) } as TBridgeQuote
    }
    const _destination = DAPP_CONFIG[bridge.destination]
    if (!_destination) {
      return { nativeFee: BigInt(0), lzTokenFee: BigInt(0) } as TBridgeQuote
    }

    const contract = new Contract(
      _token.oftAddress,
      OftAbi,
      new JsonRpcProvider(_source.rpcUrl || account.chain.rpcUrls.default.http.at(0)),
    )
    const executorLzReceiveOptionMaxGas = 65000
    const options = Options.newOptions().addExecutorLzReceiveOption(BigInt(executorLzReceiveOptionMaxGas), 0).toBytes()

    try {
      const amount = await contract.quoteSend(
        {
          oftCmd: '0x',
          composeMsg: '0x',
          extraOptions: options,
          amountLD: amountBn,
          dstEid: _destination.oftEid,
          minAmountLD: amountBn,
          to: fromBytes(addressToBytes32(bridge.receiverAddress), 'hex'),
        },
        false,
      )
      return amount
    } catch (err) {
      console.log('Err', err, amountBn, getAmountPostSlippage(amountBn))
      return BigInt(0)
    }
  }

  return useQuery({
    queryFn: fetch,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    refetchInterval: REFETCH_INTERVAL,
    refetchIntervalInBackground: false,
    placeholderData: { nativeFee: BigInt(0), lzTokenFee: BigInt(0) },
    initialData: () => ({ nativeFee: BigInt(0), lzTokenFee: BigInt(0) }),
    enabled: Boolean(bridge) && Boolean(bridge.token) && account.isConnected && !!bridge.receiverAddress,
    queryKey: [
      'bridge',
      'fee',
      account.address,
      bridge.token,
      bridge.amount,
      account.chainId,
      bridge.destination,
      bridge.receiverAddress,
    ],
    select: (data: unknown) => {
      const quote = data as TBridgeQuote
      return { nativeFee: quote?.nativeFee || BigInt(0), lzTokenFee: quote?.lzTokenFee || BigInt(0) }
    },
  })
}

export default useBridgeFee
