import { useAccount } from 'wagmi'

import ERC20Abi from '../../constants/abis/ERC20.json'

import { Contract } from 'zksync-ethers'

import { JsonRpcProvider } from 'ethers'

import { useQuery } from '@tanstack/react-query'

import { useContext } from 'react'

import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { BuyContext } from '../../context/BuyProviderNew'
import { REFETCH_INTERVAL } from '../../utils'
import { DAPP_CONFIG } from '../../constants'

const useBuyAllowance = () => {
  const buy = useContext(BuyContext)
  const account = useAccount({ config: rainbowKitConfig })

  const fetch = async () => {
    if (
      !buy ||
      !account ||
      !buy.buyState ||
      !buy.buyState.tokenIn ||
      !account.chain ||
      !account.address ||
      !account.chainId ||
      !account.isConnected
    ) {
      return BigInt(0)
    }
    const config = DAPP_CONFIG[account.chainId]
    if (!config) {
      return BigInt(0)
    }
    const _token = config.tokens[buy.buyState.tokenIn]
    if (
      !_token ||
      !config.tokensForSell?.includes(buy.buyState.tokenIn) ||
      !config.uniV3Quoter ||
      !config.uniV3SwapRouter
    ) {
      return BigInt(0)
    }

    const contract = new Contract(
      _token.contractAddress,
      ERC20Abi,
      new JsonRpcProvider(config.rpcUrl || account.chain.rpcUrls.default.http.at(0)),
    )
    return contract.allowance(account.address, config.uniV3SwapRouter)
  }

  return useQuery({
    queryFn: fetch,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    initialData: () => BigInt(0),
    placeholderData: () => BigInt(0),
    refetchInterval: REFETCH_INTERVAL,
    refetchIntervalInBackground: false,
    select: (data) => (data || BigInt(0)) as bigint,
    queryKey: ['buy', 'allowance', account.address, buy.buyState.tokenIn, account.chainId],
    enabled: Boolean(buy) && Boolean(buy.buyState) && Boolean(buy.buyState.tokenIn) && account.isConnected,
  })
}

export default useBuyAllowance
