/* eslint-disable react/react-in-jsx-scope */

import { useContext, useMemo, useState } from 'react'

import { Tooltip } from '@chakra-ui/react'

import ProtocolInfoChart from '../ProtocolInfo/ProtocolInfoDashboardChart'
import { ReactComponent as PlusIcon } from '../../assets/info/plus.svg'
import { ThemeContext } from '../../context/ThemeProvider'
import { formatStringToNumber } from '../../utils'
import sUSN from '../../assets/tokens/sUSN.svg'
import EmptyContainer from '../EmptyContainer'
import { TProtocolMetrics } from '../../types'

interface Iprops {
  isEmpty?: boolean
  aprTimeSeriesData?: TProtocolMetrics['apyTimeSeries']
}

const ChartInfoComponent = ({ isEmpty = false, aprTimeSeriesData = {} }: Iprops) => {
  const theme = useContext(ThemeContext)

  const [protocolInfoTimeFrame, setProtocolInfoTimeFrame] = useState<'24H' | '7D' | '30D' | 'ALL TIME'>('7D')

  const aprDataSorted = useMemo(() => {
    const data = Object.keys(aprTimeSeriesData ?? {}).map((key: string) => ({
      label: key,
      value: aprTimeSeriesData[key] ? Number(aprTimeSeriesData[key]) : 0,
    }))

    const sortedData = data.sort((a, b) => {
      const aTime = new Date(a.label).getTime()
      const bTime = new Date(b.label).getTime()
      return aTime - bTime
    })

    return sortedData
  }, [aprTimeSeriesData])

  const apyData = useMemo(() => {
    if (protocolInfoTimeFrame === '7D') {
      return aprDataSorted.slice(-7) || []
    }
    return aprDataSorted
  }, [protocolInfoTimeFrame, aprDataSorted])

  const formattedAPRPrevWeek = useMemo(() => {
    const lastValue = apyData.at(-1) || { value: '0' }
    return formatStringToNumber(lastValue?.value?.toString() ?? '0', true)
  }, [apyData])

  return (
    <div
      className={`w-full h-full flex flex-col px-4 py-6 md:py-6 md:px-8 gap-4 md:gap-2 items-start justify-center border-0 md:col-span-2`}
    >
      <div className={`w-full min-w-full rounded-lg grow`}>
        {isEmpty ? (
          <EmptyContainer classes="w-full h-full min-w-full min-h-32 max-h-full max-w-full" />
        ) : (
          <div className="min-w-full w-full h-fit" id="staking-info-chart">
            <ProtocolInfoChart data={apyData} />
          </div>
        )}
      </div>

      <div className="w-full flex-col gap-2 items-start justify-start grow">
        {isEmpty ? (
          <EmptyContainer classes={`h-12 w-32 ${theme.isLightTheme ? 'bg-stone-200' : 'bg-stone-800'}`} />
        ) : (
          <div
            className={`w-fit rounded-xl p-[2px] !pr-[6px] max-w-full flex items-center justify-center gap-[6px] ${theme.isLightTheme ? 'bg-indigo-200' : 'bg-indigo-950'}`}
          >
            <p
              className={`font-Suisse-Intl rounded-[10px] pt-[2px] px-1 font-[450] text-[32px] ${theme.isLightTheme ? 'text-stone-900 bg-white' : 'text-stone-50 bg-stone-900'}`}
            >
              {formattedAPRPrevWeek}%
            </p>
            <Tooltip
              hasArrow={true}
              label={
                <>
                  <b>These does not include governance token rewards</b>. This value is simply the APY from our “raw
                  returns” - the yield from our trading strategies.
                </>
              }
              bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
              color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
              placement="top"
              borderRadius={'rounded-lg'}
              paddingX={'px-3'}
              paddingY={'py-[6px]'}
              className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
            >
              <div className={`w-7 h-7 aspect-square rounded-[4px]`}>
                <PlusIcon className={`w-full h-full ${theme.isLightTheme ? 'text-indigo-600' : 'text-indigo-200'} `} />
              </div>
            </Tooltip>
          </div>
        )}

        <div className="w-full grid grid-cols-2 items-center justify-between">
          <div className="w-full flex gap-1 items-start justify-start">
            <p
              className={`font-[450] font-Suisse-Intl text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
            >
              <img src={sUSN} className="w-[18px] h-[18px] inline" alt="sUSN" /> sUSN APY, 7D Average
            </p>
          </div>

          <div className="w-fit flex items-center justify-center rounded-lg gap-[2px] p-[2px] z-40 shrink justify-self-end">
            <button
              onClick={() => setProtocolInfoTimeFrame('7D')}
              className={`rounded-lg py-1 px-2 font-Suisse-Intl font-medium text-sm ${
                theme.isLightTheme
                  ? protocolInfoTimeFrame === '7D'
                    ? 'text-stone-900  bg-stone-200'
                    : 'text-stone-500 bg-transaparent'
                  : protocolInfoTimeFrame === '7D'
                    ? 'text-stone-50  bg-stone-700'
                    : 'text-stone-500 bg-transaparent'
              }`}
            >
              7D
            </button>
            <button
              onClick={() => setProtocolInfoTimeFrame('30D')}
              className={`rounded-lg py-1 px-2 font-Suisse-Intl font-medium text-sm ${
                theme.isLightTheme
                  ? protocolInfoTimeFrame === '30D'
                    ? 'text-stone-900  bg-stone-200'
                    : 'text-stone-500 bg-transaparent'
                  : protocolInfoTimeFrame === '30D'
                    ? 'text-stone-50  bg-stone-700'
                    : 'text-stone-500 bg-transaparent'
              }`}
            >
              30D
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChartInfoComponent
