import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, Tooltip } from '@chakra-ui/react'
import React, { useContext, useMemo, useState } from 'react'

import moment from 'moment'

import { useAccount } from 'wagmi'

import { NavLink } from 'react-router-dom'

import { mainnet } from 'viem/chains'

import TxnWrongChainOverlayDarkMobile from '../../assets/dashboard/txn-dark-mode-mobile-coming-soon.png'
import TxnWrongChainOverlayLightMobile from '../../assets/dashboard/txn-mode-mobile-coming-soon.png'
import { ReactComponent as ArrowRightUpIcon } from '../../assets/navigation/arrow-right-up.svg'
import noRecentTransactionsDark from '../../assets/dashboard/no-recent-transactions-dark.svg'
import TxnWrongChainOverlayDark from '../../assets/dashboard/txn-dark-mode-coming-soon.png'
import { ReactComponent as ArrowRightIcon } from '../../assets/navigation/arrow-right.svg'
import { ReactComponent as TooltipIcon } from '../../assets/info/info-circle-filled.svg'
import { ReactComponent as ArrowLeftIcon } from '../../assets/navigation/arrow-left.svg'
import TxnWrongChainOverlayLight from '../../assets/dashboard/txn-mode-coming-soon.png'
import noRecentTransactions from '../../assets/dashboard/no-recent-transactions.svg'
import withdrawn from '../../assets/dashboard/withdrawn.svg'
import unstaked from '../../assets/dashboard/unstaked.svg'
import redeemed from '../../assets/dashboard/redeemed.svg'
import { ThemeContext } from '../../context/ThemeProvider'
import claimed from '../../assets/dashboard/claimed.svg'
import staked from '../../assets/dashboard/staked.svg'
import minted from '../../assets/dashboard/minted.svg'
import useRecentTxns from '../../hooks/useRecentTxns'
import { formatBigNumberCompact } from '../../utils'
import USDC from '../../assets/tokens/USDC.svg'
import USDT from '../../assets/tokens/USDT.svg'
import sUSN from '../../assets/tokens/sUSN.svg'
import USN from '../../assets/tokens/USN.svg'
import { TUserRecentTxns } from '../../types'
import ConnectButton from '../ConnectButton'

interface IProps {
  connected?: boolean
}

const RecentTransactions = ({ connected = false }: IProps) => {
  const theme = useContext(ThemeContext)

  const [page, setPage] = useState<number>(0)

  const account = useAccount()
  const transactions = useRecentTxns()

  const txns = useMemo(() => {
    return ((transactions?.data?.length ? transactions?.data : []) as TUserRecentTxns[])
      .map((txn, i) => ({
        txnNo: i + 1,
        action: txn.action,
        status: txn.status,
        timestamp: txn.timestamp,
        txnStatusCssClasses:
          txn.status.toLowerCase() === 'successful' ||
          txn.status.toLowerCase() === 'success' ||
          txn.status.includes('success')
            ? theme.isLightTheme
              ? 'text-green-700 bg-green-200'
              : 'text-green-200 bg-green-1000'
            : txn.status.toLowerCase() === 'in process' ||
                txn.status.toLowerCase() === 'in-process' ||
                txn.status.toLowerCase() === 'in_process'
              ? theme.isLightTheme
                ? 'text-warning-950 bg-warning-200'
                : 'text-warning-200 bg-warning-950'
              : txn.status.toLowerCase() === 'fail' || txn.status.toLowerCase() === 'failed'
                ? theme.isLightTheme
                  ? 'text-red-700 bg-red-200'
                  : 'text-red-200 bg-red-950'
                : '',
        tokenIn: txn.tokenIn.toLowerCase(),
        tokenInAmount: formatBigNumberCompact(
          BigInt(txn?.amountIn || txn.amount || '0'),
          txn.tokenIn.toLowerCase() === 'usdc' || txn.tokenIn.toLowerCase() === 'usdt' ? 6 : 18,
          true,
        ),
        tokenOutAmount: formatBigNumberCompact(
          BigInt(txn?.amountOut || txn.amount || '0'),
          txn.tokenOut.toLowerCase() === 'usdc' || txn.tokenOut.toLowerCase() === 'usdt' ? 6 : 18,
          true,
        ),
        tokenOut: txn.tokenOut.toLowerCase(),
        txnHash: txn.transactionHash,
        tokenInIcon:
          txn.tokenIn.toLowerCase() === 'usn'
            ? USN
            : txn.tokenIn.toLowerCase() === 'susn'
              ? sUSN
              : txn.tokenIn.toLowerCase() === 'usdc'
                ? USDC
                : txn.tokenIn.toLowerCase() === 'usdt'
                  ? USDT
                  : USN,
        tokenOutIcon:
          txn.tokenOut.toLowerCase() === 'usn'
            ? USN
            : txn.tokenOut.toLowerCase() === 'susn'
              ? sUSN
              : txn.tokenOut.toLowerCase() === 'usdc'
                ? USDC
                : txn.tokenOut.toLowerCase() === 'usdt'
                  ? USDT
                  : USN,
        tokenInSymbol: txn.tokenIn.toUpperCase(),
        tokenOutSymbol: txn.tokenOut.toUpperCase(),
        actionIcon:
          txn.action.toLowerCase() === 'mint' || txn.action.toLowerCase() === 'minted'
            ? minted
            : txn.action.toLowerCase() === 'redeem' ||
                txn.action.toLowerCase() === 'redeemed' ||
                txn.action.toLowerCase() === 'transfer' ||
                txn.action.toLowerCase() === 'transferFrom'
              ? redeemed
              : txn.action.toLowerCase() === 'stake' || txn.action.toLowerCase() === 'staked'
                ? staked
                : txn.action.toLowerCase() === 'unstake' || txn.action.toLowerCase() === 'unstaked'
                  ? unstaked
                  : txn.action.toLowerCase() === 'claim' || txn.action.toLowerCase() === 'claimed'
                    ? claimed
                    : txn.action.toLowerCase() === 'withdraw' ||
                        txn.action.toLowerCase() === 'withdrawn' ||
                        txn.action.toLowerCase() === 'withdrew'
                      ? withdrawn
                      : redeemed,
      }))
      .reverse()
  }, [transactions, account])

  const PAGE_SIZE = 5

  const noOfPages = useMemo(() => Math.ceil(txns.length / PAGE_SIZE), [txns])

  const paginatedTxns = useMemo(() => {
    const startIndex = page * PAGE_SIZE
    const endIndex = startIndex + PAGE_SIZE
    return txns.slice(startIndex, endIndex)
  }, [page, txns])

  return (
    <div className="w-full h-auto flex flex-col gap-4 items-start justify-start">
      <div className="w-full flex gap-2 items-center justify-start">
        <p
          className={`font-Suisse-Intl font-medium ${theme.isLightTheme ? 'text-stone-600' : 'text-stone-500'} text-sm`}
        >
          RECENT TRANSACTIONS
        </p>
        <Tooltip
          hasArrow={true}
          label={
            <div>
              <b>Ethereum only</b>, coming soon for other chains.
            </div>
          }
          bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
          color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
          placement="top"
          borderRadius={'rounded-lg'}
          paddingX={'px-3'}
          paddingY={'py-[6px]'}
          className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
        >
          <div className={`h-6 w-6 ${theme.isLightTheme ? 'text-stone-400' : 'text-stone-500'} `}>
            <TooltipIcon className="w-full h-full" />
          </div>
        </Tooltip>
      </div>

      <div className="w-full max-w-full p-0 m-0">
        {account.isConnected && account.chainId !== mainnet.id ? (
          <div className="relative w-full max-w-full p-0 m-0">
            <img
              src={theme.isLightTheme ? TxnWrongChainOverlayLight : TxnWrongChainOverlayDark}
              className={`hidden min-w-full w-full max-w-full min-h-full h-full max-h-full md:block`}
            />
            <img
              src={theme.isLightTheme ? TxnWrongChainOverlayLightMobile : TxnWrongChainOverlayDarkMobile}
              className={`w-full max-w-full h-full max-h-full md:hidden`}
            />
            <div
              className={`absolute h-full w-full max-w-full max-h-full top-0 flex flex-col gap-1 items-center justify-center z-[100] data-container`}
            >
              <div className="w-[105px] h-[88px] md:w-32 md:h-28">
                {theme.isLightTheme ? (
                  <img alt="No recent transactions" className="w-full h-full" src={noRecentTransactions} />
                ) : (
                  <img alt="No recent transactions" className="w-full h-full" src={noRecentTransactionsDark} />
                )}
              </div>
              <p
                className={`font-Louize font-normal text-4xl leading-[44px] md:text-[64px] md:leading-[76px] -tracking-[5%] ${theme.isLightTheme ? 'text-stone-800' : 'text-stone-300'} text-center text-wrap opacity-70`}
              >
                Coming soon
              </p>
            </div>
          </div>
        ) : !connected ? (
          <div
            className={`w-full flex flex-col rounded-xl border-[1px] p-10 gap-4 items-center justify-around ${theme.isLightTheme ? 'bg-white' : 'bg-stone-900'} ${theme.isLightTheme ? 'border-stone-300' : 'border-stone-800'} data-container z-[99]`}
          >
            <div className="w-32 h-28">
              {theme.isLightTheme ? (
                <img alt="No recent transactions" className="w-full h-full" src={noRecentTransactions} />
              ) : (
                <img alt="No recent transactions" className="w-full h-full" src={noRecentTransactionsDark} />
              )}
            </div>
            <p
              className={`font-Suisse-Intl font-semiMedium text-xl ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'} text-center text-wrap max-w-[50%]`}
            >
              We couldn&apos;t locate any transactions associated with your Wallet.
            </p>
            <ConnectButton showArrow={true} showWalletLogo={false} />
          </div>
        ) : !txns || !txns?.length ? (
          <div
            className={`w-full  z-[99] flex flex-col rounded-xl border-[1px] p-10 gap-4 items-center justify-around ${theme.isLightTheme ? 'bg-white' : 'bg-stone-900'} ${theme.isLightTheme ? 'border-stone-300' : 'border-stone-800'} data-container`}
          >
            <div className="w-32 h-28">
              {theme.isLightTheme ? (
                <img alt="No recent transactions" className="w-full h-full" src={noRecentTransactions} />
              ) : (
                <img alt="No recent transactions" className="w-full h-full" src={noRecentTransactionsDark} />
              )}
            </div>
            <p
              className={`font-Suisse-Intl font-semiMedium text-xl ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'} text-center text-wrap max-w-[50%]`}
            >
              We couldn&apos;t locate any transactions associated with your Wallet.
            </p>
            <div className="w-fit flex items-center justify-center gap-2">
              <NavLink
                to={'/get'}
                className={`w-fit rounded-[184px] py-3 px-[18px] bg-indigo-600 flex gap-[6px] items-center justify-center`}
              >
                <p className={`font-Suisse-Intl font-medium text-base text-stone-50`}>Buy USN</p>
                <div className={`w-6 h-6 text-stone-50`}>
                  <ArrowRightIcon className="w-full h-full" />
                </div>
              </NavLink>
              <NavLink
                to={'/stake'}
                className={`w-fit rounded-[184px] py-3 px-[18px] ${theme.isLightTheme ? 'bg-stone-100 border-stone-300' : 'bg-stone-900 border-stone-800'} border-[1px] flex gap-[6px] items-center justify-center`}
              >
                <p
                  className={`font-Suisse-Intl font-medium text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                >
                  Stake USN
                </p>
                <div className={`w-6 h-6 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}>
                  <ArrowRightIcon className="w-full h-full" />
                </div>
              </NavLink>
            </div>
          </div>
        ) : (
          <div
            className={`w-full z-[99] rounded-md ${theme.isLightTheme ? 'bg-stone-50' : 'border-[1px] bg-stone-900 border-stone-800'} table-container`}
          >
            <TableContainer className={`rounded-md ${theme.isLightTheme ? 'border-stone-300' : 'border-stone-800'}`}>
              <Table variant="simple" className={`${theme.isLightTheme ? '' : 'border-stone-800'}`}>
                <Thead
                  className={`py-2 px-3 border-b-[1px] ${theme.isLightTheme ? 'bg-stone-50 text-stone-600 border-b-stone-200' : 'bg-stone-900 border-b-stone-800 text-stone-500'}`}
                >
                  <Tr className={`py-2 px-3 ${theme.isLightTheme ? 'text-stone-600' : 'text-stone-500'}`}>
                    <Th
                      className={`py-2 px-3 ${theme.isLightTheme ? 'text-stone-600' : 'text-stone-500'}`}
                      borderColor={theme.isLightTheme ? '#E7E5E4' : '#312D2C'}
                      textColor={theme.isLightTheme ? '#57534E' : '#78716C'}
                      borderBottomWidth={1}
                    >
                      TX No.
                    </Th>
                    <Th
                      className={`py-2 px-3 ${theme.isLightTheme ? 'text-stone-600' : 'text-stone-500'}`}
                      borderColor={theme.isLightTheme ? '#E7E5E4' : '#312D2C'}
                      textColor={theme.isLightTheme ? '#57534E' : '#78716C'}
                      borderBottomWidth={1}
                    >
                      ACTION
                    </Th>
                    <Th
                      className={`py-2 px-3 ${theme.isLightTheme ? 'text-stone-600' : 'text-stone-500'}`}
                      borderColor={theme.isLightTheme ? '#E7E5E4' : '#312D2C'}
                      textColor={theme.isLightTheme ? '#57534E' : '#78716C'}
                      borderBottomWidth={1}
                    >
                      STATUS by
                    </Th>
                    <Th
                      className={`py-2 px-3 ${theme.isLightTheme ? 'text-stone-600' : 'text-stone-500'}`}
                      borderColor={theme.isLightTheme ? '#E7E5E4' : '#312D2C'}
                      textColor={theme.isLightTheme ? '#57534E' : '#78716C'}
                      borderBottomWidth={1}
                    >
                      TOKEN RECEIVED
                    </Th>
                    <Th
                      className={`py-2 px-3 ${theme.isLightTheme ? 'text-stone-600' : 'text-stone-500'}`}
                      borderColor={theme.isLightTheme ? '#E7E5E4' : '#312D2C'}
                      textColor={theme.isLightTheme ? '#57534E' : '#78716C'}
                      borderBottomWidth={1}
                    >
                      TOKEN SENT
                    </Th>
                    <Th
                      className={`py-2 px-3 !text-right ${theme.isLightTheme ? 'text-stone-600' : 'text-stone-500'}`}
                      borderColor={theme.isLightTheme ? '#E7E5E4' : '#312D2C'}
                      textColor={theme.isLightTheme ? '#57534E' : '#78716C'}
                      borderBottomWidth={1}
                    >
                      TRANSACTION HASH
                    </Th>
                  </Tr>
                </Thead>
                <Tbody className={`${theme.isLightTheme ? 'bg-white' : 'bg-stone-950'}`}>
                  {paginatedTxns.map((txn, i) => (
                    <Tr
                      borderColor={theme.isLightTheme ? '#E7E5E4' : 'unset'}
                      key={txn?.txnHash ?? i}
                      className={`py-2 px-4 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'}`}
                    >
                      <Td
                        borderColor={theme.isLightTheme ? '#E7E5E4' : 'unset'}
                        className={`border-b-[1px] py-2 px-4 gap-2 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'}`}
                      >
                        <p className={`${theme.isLightTheme ? 'text-stone-700' : 'text-stone-500'}`}>
                          {txn?.txnNo ?? i}
                        </p>
                      </Td>
                      <Td
                        borderColor={theme.isLightTheme ? '#E7E5E4' : 'unset'}
                        className={`border-b-[1px] py-2 px-4 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'}`}
                      >
                        <div className="w-fit flex items-start justify-center gap-2">
                          <div className="rounded-[100px] p-[6px] bg-stone-100">
                            <div className="w-6 h-6">
                              <img alt="Action" className="w-full h-full" src={txn?.actionIcon ?? redeemed} />
                            </div>
                          </div>
                          <div>
                            <p
                              className={`font-Suisse-Intl font-medium text-base ${theme.isLightTheme ? 'text-stone-700' : 'text-stone-50'} capitalize`}
                            >
                              {txn?.action ?? 'Onchain action'}
                            </p>
                            <p className="font-Suisse-Intl font-[450] text-sm text-stone-500">
                              {moment(Date.parse(txn?.timestamp) ?? new Date()).fromNow()}
                            </p>
                          </div>
                        </div>
                      </Td>
                      <Td
                        borderColor={theme.isLightTheme ? '#E7E5E4' : 'unset'}
                        className={`border-b-[1px] py-2 px-4 gap-2 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'}`}
                      >
                        <p
                          className={`w-fit font-Suisse-Intl font-medium text-sm py-[2px] px-2 rounded-md ${txn?.txnStatusCssClasses}`}
                        >
                          {txn?.status?.toUpperCase() ?? 'UNKNOWN'}
                        </p>
                      </Td>
                      <Td
                        borderColor={theme.isLightTheme ? '#E7E5E4' : 'unset'}
                        className={`border-b-[1px] py-2 px-4 gap-2 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'}`}
                      >
                        <div className="w-fit flex items-center justify-center gap-1">
                          <div className="w-5 h-5">
                            <img src={txn?.tokenInIcon ?? USN} alt="sUSN" className="w-full h-full" />
                          </div>
                          <p
                            className={`font-Suisse-Intl font-medium text-base ${theme.isLightTheme ? 'text-stone-700' : 'text-stone-50'}`}
                          >
                            {txn?.tokenInAmount ?? '0.00'} {txn?.tokenInSymbol ?? 'USN'}
                          </p>
                        </div>
                        <p className="font-Suisse-Intl font-[450] text-sm text-stone-500">
                          ~${txn?.tokenInAmount ?? '0.00'}
                        </p>
                      </Td>
                      <Td
                        borderColor={theme.isLightTheme ? '#E7E5E4' : 'unset'}
                        className={`border-b-[1px] py-2 px-4 gap-2 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'}`}
                      >
                        <div className="w-fit flex items-center justify-center gap-1">
                          <div className="w-5 h-5">
                            <img src={txn?.tokenOutIcon ?? USN} alt="USN" className="w-full h-full" />
                          </div>
                          <p
                            className={`font-Suisse-Intl font-medium text-base ${theme.isLightTheme ? 'text-stone-700' : 'text-stone-50'}`}
                          >
                            {txn?.tokenOutAmount ?? '0.00'} {txn?.tokenOutSymbol ?? 'USN'}
                          </p>
                        </div>
                        <p className="font-Suisse-Intl font-[450] text-sm text-stone-500">
                          ~${txn?.tokenOutAmount ?? '0.00'}
                        </p>
                      </Td>
                      <Td
                        borderColor={theme.isLightTheme ? '#E7E5E4' : 'unset'}
                        className={`border-b-[1px] py-2 px-4 gap-2 justify-self-end ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'}`}
                      >
                        {txn?.txnHash ? (
                          <a
                            href={`${account?.chain?.blockExplorers?.default.url}/tx/${txn?.txnHash}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="w-full flex items-center justify-end gap-1">
                              <p
                                className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-700' : 'text-stone-400'}`}
                              >
                                {txn?.txnHash
                                  ? `${txn?.txnHash.slice(0, 6)}...${txn?.txnHash.slice(txn?.txnHash.length - 6)}`
                                  : '-'}
                              </p>
                              <div className={`w-4 h-4 text-stone-400`}>
                                <ArrowRightUpIcon className="w-full h-full" />
                              </div>
                            </div>
                          </a>
                        ) : (
                          <p
                            className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-700' : 'text-stone-400'} text-right`}
                          >
                            -
                          </p>
                        )}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>

      {account.isConnected && account.chainId === mainnet.id && (
        <div className="w-full max-w-full grid grid-cols-3 gap-12 items-center justify-between">
          <button
            type="button"
            disabled={page === 0 || !txns || !txns.length || !account.address}
            onClick={() => setPage((page) => page - 1)}
            className={`w-fit rounded-lg border-[1px] py-2 px-4 gap-2 ${theme.isLightTheme ? 'border-stone-300' : 'border-stone-700'} w-fit flex items-center justify-center ${!page ? '' : 'table-pagination-selected-page-shadow'} ${Number(page) === Number(0) ? '' : theme.isLightTheme ? 'bg-white' : 'bg-stone-900'} justify-self-start`}
          >
            <div className={`h-5 w-5 ${theme.isLightTheme ? 'text-stone-400' : 'text-stone-400'} `}>
              <ArrowLeftIcon className="w-full h-full" />
            </div>
            <p
              className={`hidden md:block font-Suisse-Intl font-[450] text-[16px] ${theme.isLightTheme ? 'text-stone-700' : 'text-stone-200'}`}
            >
              Previous
            </p>
          </button>

          <div
            className={`rounded-lg py-2 px-4 gap-3 ${theme.isLightTheme ? 'border-stone-300' : 'border-stone-300'} grow flex items-center justify-center`}
          >
            {noOfPages <= 3 ? (
              Array(noOfPages)
                .fill(0)
                .map((v, i) => (
                  <button
                    key={i}
                    type="button"
                    disabled={page === i}
                    onClick={() => setPage(i)}
                    className={`hidden md:block h-10 w-10 rounded-lg border-[1px] p-2 gap-6 ${theme.isLightTheme ? 'border-stone-300' : 'border-stone-800'} ${page === i ? 'table-pagination-selected-page-shadow' : ''} ${page === i ? (theme.isLightTheme ? 'bg-white' : 'bg-stone-900') : ''} text-center`}
                  >
                    <p
                      className={`font-Suisse-Intl font-medium text-base ${theme.isLightTheme ? (page === i ? 'text-stone-900' : 'text-stone-500') : page === i ? 'text-stone-50' : 'text-stone-400'}`}
                    >
                      {i + 1}
                    </p>
                  </button>
                ))
            ) : (
              <>
                {Array(noOfPages)
                  .fill(0)
                  .map((v, i) => (
                    <button
                      key={i}
                      type="button"
                      disabled={page === i}
                      onClick={() => setPage(i)}
                      className={`hidden md:block h-10 w-10 rounded-lg border-[1px] p-2 gap-6 ${theme.isLightTheme ? 'border-stone-300' : 'border-stone-800'} ${page === i ? 'table-pagination-selected-page-shadow' : ''} ${page === i ? (theme.isLightTheme ? 'bg-white' : 'bg-stone-900') : ''} text-center`}
                    >
                      <p
                        className={`font-Suisse-Intl font-medium text-base ${theme.isLightTheme ? (page === i ? 'text-stone-900' : 'text-stone-500') : page === i ? 'text-stone-50' : 'text-stone-400'}`}
                      >
                        {i + 1}
                      </p>
                    </button>
                  ))
                  .slice(0, 2)}
                <p
                  className={`font-Suisse-Intl font-medium text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
                >
                  ...
                </p>

                {Array(noOfPages)
                  .fill(0)
                  .map((v, i) => (
                    <button
                      key={i}
                      type="button"
                      disabled={page === i}
                      onClick={() => setPage(i)}
                      className={`hidden md:block h-10 w-10 rounded-lg border-[1px] p-2 gap-6 ${theme.isLightTheme ? 'border-stone-300' : 'border-stone-800'} ${page === i ? 'table-pagination-selected-page-shadow' : ''} ${page === i ? (theme.isLightTheme ? 'bg-white' : 'bg-stone-900') : ''} text-center`}
                    >
                      <p
                        className={`font-Suisse-Intl font-medium text-base ${theme.isLightTheme ? (page === i ? 'text-stone-900' : 'text-stone-500') : page === i ? 'text-stone-50' : 'text-stone-400'}`}
                      >
                        {i + 1}
                      </p>
                    </button>
                  ))
                  .slice(-2, noOfPages + 100)}
              </>
            )}
          </div>

          <button
            type="button"
            disabled={page === noOfPages - 1 || !txns || !txns.length || !account.address}
            onClick={() => setPage((page) => page + 1)}
            className={`w-fit rounded-lg border-[1px] py-2 px-4 gap-2 ${theme.isLightTheme ? 'border-stone-300' : 'border-stone-800'} w-fit flex items-center justify-center ${Number(page) === Number(Number(noOfPages) - Number(1)) || !txns?.length ? '' : theme.isLightTheme ? 'bg-white' : 'bg-stone-900'} justify-self-end`}
          >
            <p
              className={`hidden md:block font-Suisse-Intl font-[450] text-[16px] ${theme.isLightTheme ? 'text-stone-700' : 'text-stone-200'}`}
            >
              Next
            </p>
            <div className={`h-5 w-5 ${theme.isLightTheme ? 'text-stone-400' : 'text-stone-400'} `}>
              <ArrowRightIcon className="w-full h-full" />
            </div>
          </button>
        </div>
      )}
    </div>
  )
}

export default RecentTransactions
