import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react'

import React, { useCallback, useContext, useMemo, useState } from 'react'

import { useSignMessage } from 'wagmi'
import { useAccount } from 'wagmi'

import { useSearchParams } from 'react-router-dom'

import { ReactComponent as InfoCircleFilledIcon } from '../../assets/info/info-circle-filled.svg'
import { ReactComponent as MultipleCrossIcon } from '../../assets/navigation/multiple-cross.svg'
import { ReactComponent as CheckTickCircle } from '../../assets/success/check-tick-circle.svg'
import { ReactComponent as GiftStarIcon } from '../../assets/referrals/gift-star.svg'
import { ReactComponent as CheckTickIcon } from '../../assets/success/check-tick.svg'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import elipsis39 from '../../assets/referrals/ellipse-39.png'
import { ThemeContext } from '../../context/ThemeProvider'
import ConnectButton from '../ConnectButton'
import { EApiCallStatus } from '../../types'

interface IProps {
  isOpen: boolean
  onClose: () => void
  referralCode: string
}

const ReferralModal = (props: IProps) => {
  const { isOpen, onClose } = props

  const [saveButtonText, setSaveButtonText] = useState<string>('Link Referral')
  const [referralLinkingStatus, setReferralLinkingStatus] = useState<EApiCallStatus>(EApiCallStatus.IDLE)

  const theme = useContext(ThemeContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const account = useAccount({ config: rainbowKitConfig })
  const { signMessage } = useSignMessage({ config: rainbowKitConfig })

  const isActionDisabled = useMemo(() => {
    return (
      !account.isConnected ||
      !account.address ||
      !props.referralCode ||
      referralLinkingStatus === EApiCallStatus.PROCESSING
    )
  }, [account, props.referralCode, referralLinkingStatus])

  const sendSaveProfileReq = async (message: number, signature: string, referralCode: string) => {
    await fetch(
      `${process.env.REACT_APP_BASE_API_URL ?? ''}/api/v1/user/register-code/${account.address?.toLowerCase()}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          code: referralCode,
          message: message,
          signature,
        }),
      },
    )
      .then(
        (res) => {
          if (res.ok) {
            setReferralLinkingStatus(EApiCallStatus.API_CALL_SUCCESS)
            setSaveButtonText('Referral linked')
            setTimeout(() => {
              searchParams.delete('referralCode')
              setSearchParams(searchParams)
              props.onClose()
            }, 5 * 1000)
            return
          }

          throw new Error('Error')
        },
        (err) => {
          throw new Error('Error' + err?.message)
        },
      )
      .catch((err: Error) => {
        console.error('Error linking referral code from API:', err)
        setReferralLinkingStatus(EApiCallStatus.API_CALL_FAILURE)
        setSaveButtonText('Try again')
        setTimeout(() => {
          setReferralLinkingStatus(EApiCallStatus.IDLE)
          setSaveButtonText('Link Referral')
        }, 30 * 1000)
      })
  }

  const signReferralLinking = useCallback(() => {
    const message = Math.floor(Math.random() * 100000000000)
    const MESSAGE = `Authentication ${account.address?.toLowerCase()}`

    setReferralLinkingStatus(EApiCallStatus.PROCESSING)
    setSaveButtonText('Linking...')

    if (!account.address || !account.address?.length) {
      setReferralLinkingStatus(EApiCallStatus.API_CALL_FAILURE)
      setSaveButtonText('Try again')
      setTimeout(() => {
        setReferralLinkingStatus(EApiCallStatus.IDLE)
        setSaveButtonText('Link Referral')
      }, 30 * 1000)
      return
    }

    signMessage(
      { message: MESSAGE },
      {
        onError: () => {
          setReferralLinkingStatus(EApiCallStatus.API_CALL_FAILURE)
          setSaveButtonText('Try again')
          setTimeout(() => {
            setReferralLinkingStatus(EApiCallStatus.IDLE)
            setSaveButtonText('Link Referral')
          }, 30 * 1000)
        },
        onSuccess: (data) => {
          if (!account.address || !account.address?.length) {
            setReferralLinkingStatus(EApiCallStatus.API_CALL_FAILURE)
            setSaveButtonText('Try again')
            setTimeout(() => {
              setReferralLinkingStatus(EApiCallStatus.IDLE)
              setSaveButtonText('Link Referral')
            }, 30 * 1000)
            return
          }

          setSaveButtonText('Linking...')
          sendSaveProfileReq(message, data, props.referralCode)
        },
      },
    )
  }, [props.referralCode, signMessage, account])

  return (
    <Modal isOpen={isOpen} onClose={onClose} autoFocus={false}>
      <ModalOverlay />
      <ModalContent
        border={0}
        boxShadow={0}
        background={'transparent'}
        className={`relative flex flex-col items-end justify-center top-44 w-full gap-4 border-0 bg-transparent`}
      >
        <div
          className={`h-6 w-6 cursor-pointer ${theme.isLightTheme ? 'text-stone-50' : 'text-stone-400'} outline-none`}
          onClick={onClose}
        >
          <MultipleCrossIcon className="w-full h-full" />
        </div>

        <div
          className={`relative w-full flex flex-col items-center justify-center gap-6 py-8 px-[6px] overflow-hidden rounded-2xl border-[1px] ${theme.isLightTheme ? 'bg-stone-50 border-stone-50' : 'bg-stone-900 border-stone-900'}`}
        >
          <div className="absolute p-0 h-full w-[110%] max-h-full max-w-[110%] top-0 left-0 right-0 z-30">
            <img src={elipsis39} className="w-full h-full" />
          </div>

          {referralLinkingStatus === EApiCallStatus.API_CALL_SUCCESS ? (
            <div className="bg-green-600 border-[1.2px] border-bridge-success-tooltip-border bridge-success-icon rounded-[10px] flex items-center justify-center p-3 w-12 h-12 z-40">
              <CheckTickCircle className={`w-6 h-6 text-white`} />
            </div>
          ) : (
            <div className="referral-modal-icons-bg border-[1.2px] border-[#FF4E0E1A] referral-modal-icon-shadow rounded-[10px] flex items-center justify-center p-3 w-12 h-12 z-40">
              <InfoCircleFilledIcon className={`w-6 h-6 text-orange-600 rotate-180`} />
            </div>
          )}

          <p
            className={`w-fit max-w-[80%] font-Louize font-normal text-4xl tracking-tighter text-center ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'} text-wrap opacity-60 z-40`}
          >
            {referralLinkingStatus === EApiCallStatus.API_CALL_SUCCESS
              ? `You've been successfully referred`
              : `Confirm your referral code`}
          </p>

          <div
            className={`min-w-[80%] w-fit max-w-full rounded-[10px] border-[1px] ${theme.isLightTheme ? 'border-stone-200 bg-stone-50' : 'border-stone-700 bg-stone-800'} overflow-hidden`}
          >
            <p
              className={`w-full max-w-full ${theme.isLightTheme ? 'text-[#915730] referral-info-table-header-bg' : 'text-[#FFDAC1] referral-info-table-header-bg-dark'} gap-[6px] flex items-center justify-center px-2 py-[6px] font-Suisse-Intl font-medium text-sm leading-5`}
            >
              <GiftStarIcon className={`w-5 h-5 inline`} /> Rewards
            </p>
            <p
              className={`w-full max-w-full ${theme.isLightTheme ? 'text-stone-600 border-stone-200' : 'text-stone-100 border-stone-700'} gap-[6px] flex items-center justify-center px-2 py-[6px] font-Suisse-Intl font-medium text-sm leading-5 border-b-[1px]`}
            >
              <CheckTickIcon className={`w-6 h-6 inline text-stone-400`} />
              {` You earn a 10% points boost on holdings and activities`}
            </p>
            <p
              className={`w-full max-w-full ${theme.isLightTheme ? 'text-stone-600' : 'text-stone-100'} gap-[6px] flex items-center justify-center px-2 py-[6px] font-Suisse-Intl font-medium text-sm leading-5`}
            >
              <CheckTickIcon className={`w-6 h-6 inline text-stone-400`} />
              {` Your referrer earns a points boost`}
            </p>
          </div>

          {referralLinkingStatus !== EApiCallStatus.API_CALL_SUCCESS && (
            <div className="p-0 m-0 z-50">
              <ConnectButton
                showArrow={true}
                label={saveButtonText}
                showWalletLogo={false}
                actionType="primary-action"
                isDisabled={isActionDisabled}
                onClick={signReferralLinking}
                isLoading={referralLinkingStatus === EApiCallStatus.PROCESSING}
              />
            </div>
          )}
        </div>
      </ModalContent>
    </Modal>
  )
}

export default ReferralModal
