import { useCallback, useContext } from 'react'

import { Config, useConnectorClient } from 'wagmi'

import { estimateMaxPriorityFeePerGas, waitForTransactionReceipt } from '@wagmi/core'

import { eip712WalletActions, getGeneralPaymasterInput } from 'viem/zksync'
import { createWalletClient, custom } from 'viem'
import { MaxUint256 } from 'ethers'

import Erc20Abi from '../../../constants/abis/USNUpgradeable.json'

import { DEFAULT_GAS_PER_PUBDATA_LIMIT } from 'zksync-ethers/build/utils'

import { mainnet } from 'viem/chains'

import { rainbowKitConfig } from '../../../constants/rainbowKitConfig'
import { BuyContext } from '../../../context/BuyProviderNew'
import { DAPP_CONFIG, sophon } from '../../../constants'
import { ETxnStatus } from '../../../types'

function useBuyApprove(): () => Promise<void> {
  const buy = useContext(BuyContext)
  const connector = useConnectorClient<Config>({ config: rainbowKitConfig })

  const approve = useCallback(async (): Promise<void> => {
    if (
      !buy ||
      !connector ||
      !buy.buyState ||
      !connector.data ||
      !buy.buyState.tokenIn ||
      !connector.data.chain ||
      !connector.data.account ||
      !connector.data.transport
    ) {
      buy.setApproveTxnStatus(ETxnStatus.TXN_FAILURE)
      return
    }

    const chainId = connector.data.chain.id as 1 | 50104 | 324
    const config = DAPP_CONFIG[chainId]
    if (!chainId || !config) {
      buy.setApproveTxnStatus(ETxnStatus.TXN_FAILURE)
      return
    }
    const _token = config.tokens[buy.buyState.tokenIn]
    if (!_token) {
      buy.setApproveTxnStatus(ETxnStatus.TXN_FAILURE)
      return
    }
    if (!config.uniV3SwapRouter) {
      buy.setApproveTxnStatus(ETxnStatus.TXN_FAILURE)
      return
    }

    buy.setApproveTxnStatus(ETxnStatus.PROCESSING)
    try {
      let txnHash: string

      if (config.paymaster) {
        const wallet = createWalletClient({
          transport: custom(connector.data.transport),
          chain: connector.data.chain as typeof sophon,
        }).extend(eip712WalletActions())

        txnHash = await wallet.writeContract({
          abi: Erc20Abi,
          functionName: 'approve',
          paymaster: config.paymaster,
          address: _token.contractAddress,
          account: connector.data.account,
          args: [config.uniV3SwapRouter, MaxUint256],
          gasPerPubdata: BigInt(DEFAULT_GAS_PER_PUBDATA_LIMIT || 0),
          paymasterInput: getGeneralPaymasterInput({
            innerInput: '0x',
          }),
        })
      } else {
        const wallet = createWalletClient({
          transport: custom(connector.data.transport),
          chain: connector.data.chain as typeof mainnet,
        })
        txnHash = await wallet.writeContract({
          abi: Erc20Abi,
          functionName: 'approve',
          address: _token.contractAddress,
          account: connector.data.account!,
          args: [config.uniV3SwapRouter, MaxUint256],
        })
      }

      buy.setApproveTxnStatus(ETxnStatus.TXN_SUBMITTED_ON_CHAIN)

      const receipt = await waitForTransactionReceipt(rainbowKitConfig, { hash: txnHash as `0x${string}` }).catch(
        () => ({
          status: 'unknown',
        }),
      )
      if (receipt.status === 'success') {
        buy.setApproveTxnStatus(ETxnStatus.TXN_SUCCESS)
      } else if (receipt.status === 'reverted') {
        buy.setApproveTxnStatus(ETxnStatus.TXN_FAILURE)
      } else {
        buy.setApproveTxnStatus(ETxnStatus.TXN_SUCCESS)
      }
    } catch {
      buy.setApproveTxnStatus(ETxnStatus.TXN_FAILURE)
    }
  }, [buy, connector])

  return approve
}

export default useBuyApprove
