import type { Chain } from 'wagmi/chains'

export enum EApiCallStatus {
  IDLE,
  PROCESSING,
  API_CALL_SUCCESS,
  API_CALL_FAILURE,
}
export enum ETxnStatus {
  IDLE,
  PROCESSING,
  TXN_SUCCESS,
  TXN_FAILURE,
  TXN_SUBMITTED_ON_CHAIN,
}
export type TWithdrawReq = {
  amount: bigint
  claimed: boolean
  timestamp: bigint
}
export type TBridgeQuote = { nativeFee: bigint; lzTokenFee: bigint }
export type TAddress = `0x${string}`
export type TToken = {
  name: string
  symbol: string
  decimals: number
  iconPath?: string
  oftAddress: TAddress
  assetsIconName: string
  contractAddress: TAddress
}
export type TAmount = {
  amount: bigint
  formatted: string
  humanized: string
  abbrevated: string
}
export type TUniV3Pool = {
  name: string
  pool: TAddress
  poolFee: bigint
  path: string[]
  feeForPath: bigint[]
}
export type TScreen = 'input' | 'confirm' | 'success'
export type TMintScreen = TScreen
export type TRedeemScreen = TScreen
export type TUsnTab = 'buy' | 'mint' | 'redeem'
export type TsUsnTab = 'stake' | 'unstake' | 'withdraw'
export type TTab = TUsnTab | TsUsnTab
export type TUsnScreen = TScreen
export type TsUsnScreen = TScreen
export type TCollateral = TToken

export type TMintOrder = {
  nonce: number
  expiry: number
  user: TAddress
  message: string
  usnAmount: bigint
  collateralAmount: bigint
  collateralAddress: TAddress
}
export type TGasTrackerApiResponse = { gasPrice: string; ethPrice: string }
export type TProtocolMetrics = {
  tvl: string
  usnAmount: string
  susnAmount: string
  usnPercentage: string
  susnPercentage: string
  apyTimeSeries: {
    [label: string]: string
  }
}
export type TPointsHolding = {
  id: number
  userId: number
  points: string
  chain: number
  protocol: number
  lastUpdated: string
}
export type TLeaderboard = {
  rank: number
  points: string
  walletAddress: string
  description: string | null
  username: string
  isUser: boolean
  rankChange: number
  last24HoursPoints: string
}
export type TRelativeLeaderboard = {
  userRank: number
  totalUsers: number
  rankings: TLeaderboard[]
}
export type TUserSummary = {
  userPoints: string
  userUsnMinted: string
  userPoints24hr: string
  userUsnBalance: string
  cacheTimestamp: number
  userSusnBalance: string
  userUsnRedeemed: string
  userUsnInteractions: string
}
export type TPointsInfo = {
  totalPoints: string
  byChain: {
    [id: string]: {
      total: string
      byProtocol: {
        [name: string]: {
          current: string
          last24h: string
        }
      }
    }
  }
  referralPoints: {
    total: string
    earned: string
    fromReferrals: string
    referrerAddress: string
  }
}
export type TUserRecentTxns = {
  amount: string
  action: string
  status: string
  tokenIn: string
  tokenOut: string
  timestamp: string
  amountIn?: string
  amountOut?: string
  transactionHash: string
}
export type TCountry = {
  country: string
  countryCode: {
    country: string
    alpha2: string
    alpha3: string
    numeric: string
  }
  region: string
  city: string
  timezone: string
}
export type TUserActivity = {
  points: string
  minted: string
  redeemed: string
  unstaked: string
  usnHolding: string
  susnHolding: string
  staked: {
    usn: string
    susn: string
  }
  recentTransactions: TUserRecentTxns[]
}
export type TMintReq = {
  id: number
  createdDate: string
  order: {
    user: string
    nonce: number
    expiry: number
    usnAmount: string
    collateralAmount: string
    collateralAddress: string
  }
}
export type TPendingReq = {
  pendingRedeeming: []
  pendingMinting: TMintReq[]
}
export type TChainConfig = {
  icon: string
  usn: TAddress
  oftEid: number
  sUsn: TAddress
  paymaster?: TAddress
  tokensForBuy: string[]
  tokensForMint: string[]
  tokensForSell: string[]
  tokensForBridge: string[]
  mintHandler: TAddress
  stakingVault: TAddress
  redeemHandler: TAddress
  dataRefershInterval: number
  protocolNativeTokens: string[]
  stakeWithdrawHandler: TAddress
  layerZeroOftHelper?: TAddress
  uniV3Quoter: TAddress
  uniV3SwapRouter: TAddress
  uniV3Factory: TAddress
  uniV3Pools: {
    [label: string]: TUniV3Pool
  }
  gasLimit: {
    [type: string]: number
  }
  tokens: {
    [label: string]: TCollateral
  }
  rpcUrl: string
}
export type TConfig = {
  [id: number]: TChainConfig
}
export type TBridgeConfig = Chain & {
  config: TChainConfig
}
