import { useAccount } from 'wagmi'

import Router from '@uniswap/v3-periphery/artifacts/contracts/SwapRouter.sol/SwapRouter.json'

import { Contract } from 'zksync-ethers'

import { JsonRpcProvider } from 'ethers'

import { useQuery } from '@tanstack/react-query'

import { parseUnits, zeroAddress } from 'viem'

import { useContext } from 'react'

import { encodePath, getAmountPostSlippage, REFETCH_INTERVAL } from '../../utils'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { BuyContext } from '../../context/BuyProviderNew'
import { DAPP_CONFIG } from '../../constants'

const useBuyGas = () => {
  const buy = useContext(BuyContext)
  const account = useAccount({ config: rainbowKitConfig })

  const fetch = async () => {
    if (
      !buy ||
      !account ||
      !buy.buyState ||
      !buy.buyState.tokenIn ||
      !buy.buyState.tokenOut ||
      !buy.buyState.amountIn ||
      !buy.buyState.amountOut ||
      !account.chain ||
      !account.chainId ||
      !account.address ||
      !account.isConnected
    ) {
      return BigInt(0)
    }
    const _source = DAPP_CONFIG[account.chainId]
    if (!_source) {
      return BigInt(0)
    }
    if (!_source.uniV3Pools || !_source.uniV3Quoter || !_source.uniV3SwapRouter) {
      return BigInt(0)
    }
    if (_source.paymaster) {
      return BigInt(0)
    }

    const _tokenIn = _source.tokens[buy.buyState.tokenIn]
    if (!_tokenIn || !_source.tokensForSell?.includes(buy.buyState.tokenIn)) {
      return BigInt(0)
    }
    const amountInBn = parseUnits(buy.buyState.amountIn, _tokenIn.decimals)
    if (!amountInBn) {
      return BigInt(0)
    }

    const _tokenOut = _source.tokens[buy.buyState.tokenOut]
    if (!_tokenOut || !_source.tokensForBuy?.includes(buy.buyState.tokenOut)) {
      return BigInt(0)
    }

    const amountOutBn = parseUnits(buy.buyState.amountOut, _tokenOut.decimals)
    if (!amountOutBn) {
      return BigInt(0)
    }

    const _pool =
      _source.uniV3Pools[`${buy.buyState.tokenIn}-${buy.buyState.tokenOut}`] ||
      _source.uniV3Pools[`${buy.buyState.tokenOut}-${buy.buyState.tokenIn}`]
    if (!_pool) {
      return BigInt(0)
    }

    try {
      const route = _pool?.path?.map((token) => _source?.tokens[token]?.contractAddress || zeroAddress) || []
      const fee = _pool?.feeForPath || []
      const path = encodePath(route, fee)
      const contract = new Contract(
        _source.uniV3SwapRouter,
        Router.abi,
        new JsonRpcProvider(_source.rpcUrl || account.chain.rpcUrls.default.http.at(0)),
      )

      return await contract.exactInput.estimateGas({
        path: path,
        recipient: account.address,
        deadline: BigInt(Date.now() + 5 * 60 * 1000),
        amountIn: parseUnits(buy.buyState.amountIn, _tokenIn.decimals),
        amountOutMinimum: getAmountPostSlippage(parseUnits(buy.buyState.amountOut, _tokenOut.decimals)),
      })
    } catch {
      console.error('Buy gas estimation failed')
    }

    // Fallback.
    const singleSwapLimit = BigInt(_source?.gasLimit['swap'] || '0')
    return _pool?.feeForPath?.length === 1 ? singleSwapLimit : singleSwapLimit * BigInt(2)
  }

  return useQuery({
    queryFn: fetch,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    initialData: () => BigInt(0),
    placeholderData: () => BigInt(0),
    refetchInterval: REFETCH_INTERVAL,
    refetchIntervalInBackground: false,
    select: (data) => (data || BigInt(0)) as bigint,
    queryKey: [
      'buy',
      'gas',
      account.address,
      buy.buyState.tokenIn,
      buy.buyState.tokenOut,
      buy.buyState.amountIn,
      buy.buyState.amountOut,
      account.chainId,
    ],
    enabled:
      Boolean(buy) &&
      account.isConnected &&
      Boolean(buy.buyState) &&
      Boolean(buy.buyState.tokenIn) &&
      Boolean(buy.buyState.tokenOut) &&
      Boolean(buy.buyState.amountIn) &&
      Boolean(buy.buyState.amountOut),
  })
}

export default useBuyGas
