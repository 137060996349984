import { useAccount, useChainId } from 'wagmi'
import { Tooltip } from '@chakra-ui/react'

import React, { useContext, useEffect, useMemo, useState } from 'react'

import { NavLink } from 'react-router-dom'

import { Popover, PopoverTrigger, PopoverContent } from '@chakra-ui/react'

import {
  formatBigNumber,
  formatBigNumberCompactNoDecimals,
  formatBigNumberNoDecimal,
  formatStringToNumber,
  formatStringToNumberCompactNoDecimal,
  formatStringToNumberNoDecimal,
} from '../../utils'
import { Table, TableBody, TableCell, TableFooter, TableHead, TableHeader, TableRow } from '../../components/ui/Table'
import noRefereePointsContributionDark from '../../assets/rewards/no-referee-points-contribution-dark.png'
import { ReactComponent as ChevronBigDownIcon } from '../../assets/referrals/chevron-down-solid.svg'
import noRefereePointsContribution from '../../assets/rewards/no-referee-points-contribution.png'
import { ReactComponent as ChevronBigUpIcon } from '../../assets/referrals/chevron-up-solid.svg'
import { ReactComponent as ArrowRightUpIcon } from '../../assets/navigation/arrow-right-up.svg'
import { ReactComponent as CheckTickCircle } from '../../assets/success/check-tick-circle.svg'
import noRecentTransactionsDark from '../../assets/dashboard/no-recent-transactions-dark.svg'
import { ReactComponent as MaximizeIcon } from '../../assets/navigation/chevron-big-down.svg'
import { ReactComponent as MinimizeIcon } from '../../assets/navigation/chevron-big-up.svg'
import { ReactComponent as InfoFilledIcon } from '../../assets/info/info-circle-filled.svg'
import { ReactComponent as ArrowRightIcon } from '../../assets/navigation/arrow-right.svg'
import noRecentTransactions from '../../assets/dashboard/no-recent-transactions.svg'
import { ReactComponent as ReferralIcon } from '../../assets/referrals/referral.svg'
import { ReactComponent as SpectraIcon } from '../../assets/tokens/SPECTRA.svg'
import { ReactComponent as YTsUSNIcon } from '../../assets/tokens/YT-sUSN.svg'
import { ReactComponent as SyncSwap } from '../../assets/tokens/SYNCSWAP.svg'
import { ReactComponent as SophonIcon } from '../../assets/chains/sophon.svg'
import { ReactComponent as SpinnerIcon } from '../../assets/info/spinner.svg'
import { TLeaderboard, TPointsInfo, TRelativeLeaderboard } from '../../types'
import { ReactComponent as PasteIcon } from '../../assets/action/paste.svg'
import { ReactComponent as SUSNIcon } from '../../assets/tokens/sUSN.svg'
import { ReactComponent as USDCe } from '../../assets/tokens/USDCe.svg'
import { ReactComponent as USNIcon } from '../../assets/tokens/USN.svg'
import { ReactComponent as RFXIcon } from '../../assets/tokens/RFX.svg'
import { ReactComponent as Zero } from '../../assets/tokens/ZERO.svg'
import { ReactComponent as USDT } from '../../assets/tokens/USDT.svg'
import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { ReactComponent as UNI } from '../../assets/tokens/UNI.svg'
import NoonPointsLogo from '../../assets/rewards/totalPoints.png'
import UserProfile from '../../components/Rewards/UserProfile'
import HoldingsIcon from '../../assets/rewards/holdings.png'
import elipsis39 from '../../assets/rewards/ellipse-39.png'
import NoonLogos from '../../assets/rewards/noon-logos.png'
import elipsis38 from '../../assets/rewards/ellipse-38.png'
import elipsis40 from '../../assets/rewards/ellipse-40.png'
import { ThemeContext } from '../../context/ThemeProvider'
import ScaleLogo from '../../assets/rewards/scale.png'
import useUsername from '../../hooks/useUsername'
import avatar from '../../assets/avatar.png'

import './index.css'

const DashboardPage = () => {
  const [holdingsVisible, setHoldingsVisible] = useState<boolean>(true)
  const [activityVisible, setActivityVisible] = useState<boolean>(true)
  const [referralsVisible, setReferralsVisible] = useState<boolean>(false)

  const [referralCode, setReferralCode] = useState<string>()
  const [pointsInfo, setPointsInfo] = useState<TPointsInfo>()
  const [referralCodeBtnText, setReferralCodeBtnTxt] = useState<string>('Copy Referral Link')
  const [isReferralCodeCopied, setIsReferralCodeCopied] = useState<boolean>(false)
  const [relativeLeaderboard, setRelativeLeaderboard] = useState<TRelativeLeaderboard>()

  const username = useUsername()
  const theme = useContext(ThemeContext)
  const account = useAccount({ config: rainbowKitConfig })
  const chainId = useChainId({ config: rainbowKitConfig })

  const fetchRelativeLeaderboard = async () => {
    await fetch(
      `${process.env.REACT_APP_BASE_API_URL ?? ''}/api/v1/leaderboard/${account.address?.toLowerCase()}?limit=4`,
    )
      .then(
        (res) => {
          if (res.ok) {
            return res.json()
          }

          throw new Error('Failed to fetch user dashboard data')
        },
        (err) => {
          throw new Error(err)
        },
      )
      .then((json) => json)
      .then((top) => {
        setRelativeLeaderboard(top)
      })
      .catch(() => {
        setRelativeLeaderboard({ userRank: 0, totalUsers: 0, rankings: [] as TLeaderboard[] } as TRelativeLeaderboard)
        return
      })
  }

  const fetchPointsInfo = async () => {
    await fetch(`${process.env.REACT_APP_BASE_API_URL ?? ''}/api/v1/points/${account?.address?.toLowerCase()}`)
      .then(
        (res) => {
          if (res.ok) {
            return res.json()
          }

          throw new Error('Failed to fetch user dashboard data')
        },
        (err) => {
          throw new Error(err)
        },
      )
      .then((json) => json)
      .then((info) => {
        setPointsInfo(
          info || {
            byChain: {},
            totalPoints: '0',
            referralPoints: {
              earned: '0',
              fromReferrals: '0',
              total: '0',
              referrerAddress: '',
            },
          },
        )
      })
      .catch(() => {
        setPointsInfo({
          byChain: {},
          totalPoints: '0',
          referralPoints: {
            earned: '0',
            fromReferrals: '0',
            total: '0',
            referrerAddress: '',
          },
        })
        return
      })
  }

  const fetchReferralCode = async () => {
    await fetch(`${process.env.REACT_APP_BASE_API_URL ?? ''}/api/v1/referral-code/${account.address?.toLowerCase()}`)
      .then(
        (res) => {
          if (res.ok) {
            return res.json()
          }

          throw new Error('Failed to fetch user dashboard data')
        },
        (err) => {
          throw new Error(err)
        },
      )
      .then((json) => json as { ownCode: string })
      .then((code) => {
        setReferralCode(code?.ownCode)
      })
      .catch(() => {
        setReferralCode(undefined)
        return
      })
  }

  useEffect(() => {
    fetchPointsInfo()
    fetchReferralCode()
    fetchRelativeLeaderboard()
  }, [account.address, chainId])

  const [chainAndPointsByChain, totalPointsFormatted, totalPointsFormattedCompact] = useMemo(() => {
    if (!pointsInfo || !pointsInfo?.byChain || !Object.keys(pointsInfo?.byChain || {})?.length) {
      return [{ Ethereum: '0', Sophon: '0', Zksync: '0', Referrals: '0' } as { [chain: string]: string }, '0', '0']
    }

    const byChain: { [chain: string]: string } = { Ethereum: '0', Sophon: '0', Zksync: '0', Referrals: '0' }
    const chains = Object.keys(pointsInfo?.byChain || {})
    let superTotal = 0
    for (const chain of chains) {
      if (chain === '1') {
        let total: number = 0
        for (const points of Object.values(pointsInfo?.byChain['1']?.byProtocol || {})) {
          total += Number(formatBigNumberNoDecimal(BigInt(points?.current || '0'), 18, false))
        }
        byChain['Ethereum'] = formatStringToNumberCompactNoDecimal(total?.toString() || '0', true)
        superTotal += total
      }

      if (chain === '9') {
        let total: number = 0
        for (const points of Object.values(pointsInfo?.byChain['9']?.byProtocol || {})) {
          total += Number(formatBigNumberNoDecimal(BigInt(points?.current || '0'), 18, false))
        }
        byChain['Zksync'] = formatStringToNumberCompactNoDecimal(total?.toString() || '0', true)
        superTotal += total
      }

      if (chain === '11') {
        let total: number = 0
        for (const points of Object.values(pointsInfo?.byChain['11']?.byProtocol || {})) {
          total += Number(formatBigNumberNoDecimal(BigInt(points?.current || '0'), 18, false))
        }
        byChain['Sophon'] = formatStringToNumberCompactNoDecimal(total?.toString() || '0', true)
        superTotal += total
      }
    }

    byChain['Referrals'] = formatBigNumberCompactNoDecimals(BigInt(pointsInfo?.referralPoints?.total || '0'), 18, true)
    superTotal += Number(formatBigNumberNoDecimal(BigInt(pointsInfo?.referralPoints?.total || '0'), 18, false))

    return [
      byChain,
      formatStringToNumberNoDecimal(superTotal?.toString() || '0', true),
      formatStringToNumberCompactNoDecimal(superTotal?.toString() || '0', true),
    ]
  }, [pointsInfo])

  const c25 = useMemo(() => {
    return (0.25 * 4 * 1e6) / 100
  }, [])

  const c26 = useMemo(() => {
    const _points = Math.floor(Number(formatBigNumber(BigInt(pointsInfo?.totalPoints || '0'), 18, false) || '0'))
    return (_points * 100) / 48600000000
  }, [pointsInfo])

  const estimatedValue = useMemo(() => {
    const value = (c25 * c26 * (4 + 1)) / 5
    return formatStringToNumber(value?.toString() || '0')
  }, [c25, c26])

  const [usnPointsHoldings, usnPointsHoldingsChange] = useMemo(() => {
    if (!pointsInfo || !pointsInfo?.byChain || !Object.keys(pointsInfo?.byChain || {})?.length) {
      return [0, 0]
    }

    let prevPoints: number = 0
    let points: number = 0
    const chains = Object.keys(pointsInfo?.byChain || {})
    for (const chain of chains) {
      const protocols = Object.keys(pointsInfo?.byChain[chain]?.byProtocol || {})
      for (const protocol of protocols) {
        if (protocol === 'usn') {
          const _points = formatBigNumberNoDecimal(
            BigInt(pointsInfo?.byChain[chain]?.byProtocol[protocol]?.current || '0'),
            18,
            false,
          )
          points += Number(_points)

          const _pointsChange = formatBigNumberNoDecimal(
            BigInt(pointsInfo?.byChain[chain]?.byProtocol[protocol]?.last24h || '0'),
            18,
            false,
          )
          prevPoints += Number(_pointsChange)
        }
      }
    }

    return [points || 0, prevPoints || 0]
  }, [pointsInfo])

  const [susnPointsHoldings, susnPointsHoldingsChange] = useMemo(() => {
    if (!pointsInfo || !pointsInfo?.byChain || !Object.keys(pointsInfo?.byChain || {})?.length) {
      return [0, 0]
    }

    let prevPoints: number = 0
    let points: number = 0
    const chains = Object.keys(pointsInfo?.byChain || {})
    for (const chain of chains) {
      const protocols = Object.keys(pointsInfo?.byChain[chain]?.byProtocol || {})
      for (const protocol of protocols) {
        if (protocol === 'susn') {
          const _points = formatBigNumberNoDecimal(
            BigInt(pointsInfo?.byChain[chain]?.byProtocol[protocol]?.current || '0'),
            18,
            false,
          )
          points += Number(_points)

          const _pointsChange = formatBigNumberNoDecimal(
            BigInt(pointsInfo?.byChain[chain]?.byProtocol[protocol]?.last24h || '0'),
            18,
            false,
          )
          prevPoints += Number(_pointsChange)
        }
      }
    }

    return [points || 0, prevPoints || 0]
  }, [pointsInfo])

  const holdingsSubTotal = useMemo(() => {
    return Math.round(usnPointsHoldings + susnPointsHoldings)
  }, [usnPointsHoldings, susnPointsHoldings])

  const [uniswapPoints, uniswapPointsChange] = useMemo(() => {
    if (!pointsInfo || !pointsInfo?.byChain || !Object.keys(pointsInfo?.byChain || {})?.length) {
      return [0, 0]
    }

    let prevPoints: number = 0
    let points: number = 0
    const chains = Object.keys(pointsInfo?.byChain || {})
    for (const chain of chains) {
      if (chain !== '1') continue // Skip for non eth.

      const protocols = Object.keys(pointsInfo?.byChain[chain]?.byProtocol || {})
      for (const protocol of protocols) {
        if (!protocol.toLowerCase().includes('uni')) continue // skip for non uniswap pools.

        const _points = formatBigNumberNoDecimal(
          BigInt(pointsInfo?.byChain[chain]?.byProtocol[protocol]?.current || '0'),
          18,
          false,
        )
        points += Number(_points)

        const _pointsChange = formatBigNumberNoDecimal(
          BigInt(pointsInfo?.byChain[chain]?.byProtocol[protocol]?.last24h || '0'),
          18,
          false,
        )
        prevPoints += Number(_pointsChange)
      }
    }

    return [points || 0, prevPoints || 0]
  }, [pointsInfo])

  const [syncswapZkPoints, syncswapZkPointsChange] = useMemo(() => {
    if (!pointsInfo || !pointsInfo?.byChain || !Object.keys(pointsInfo?.byChain || {})?.length) {
      return [0, 0]
    }

    let prevPoints: number = 0
    let points: number = 0
    const chains = Object.keys(pointsInfo?.byChain || {})
    for (const chain of chains) {
      if (chain !== '9') continue // Skip for non zksync.

      const protocols = Object.keys(pointsInfo?.byChain[chain]?.byProtocol || {})
      for (const protocol of protocols) {
        if (!protocol.toLowerCase().includes('syncswap')) continue // skip for non syncswap pools.

        const _points = formatBigNumberNoDecimal(
          BigInt(pointsInfo?.byChain[chain]?.byProtocol[protocol]?.current || '0'),
          18,
          false,
        )
        points += Number(_points)

        const _pointsChange = formatBigNumberNoDecimal(
          BigInt(pointsInfo?.byChain[chain]?.byProtocol[protocol]?.last24h || '0'),
          18,
          false,
        )
        prevPoints += Number(_pointsChange)
      }
    }

    return [points || 0, prevPoints || 0]
  }, [pointsInfo])

  const [syncswapSophonPoints, syncswapSophonPointsChange] = useMemo(() => {
    if (!pointsInfo || !pointsInfo?.byChain || !Object.keys(pointsInfo?.byChain || {})?.length) {
      return [0, 0]
    }

    let prevPoints: number = 0
    let points: number = 0
    const chains = Object.keys(pointsInfo?.byChain || {})
    for (const chain of chains) {
      if (chain !== '11') continue // Skip for non sophon.

      const protocols = Object.keys(pointsInfo?.byChain[chain]?.byProtocol || {})
      for (const protocol of protocols) {
        if (!protocol.toLowerCase().includes('syncswap')) continue // skip for non syncswap pools.

        const _points = formatBigNumberNoDecimal(
          BigInt(pointsInfo?.byChain[chain]?.byProtocol[protocol]?.current || '0'),
          18,
          false,
        )
        points += Number(_points)

        const _pointsChange = formatBigNumberNoDecimal(
          BigInt(pointsInfo?.byChain[chain]?.byProtocol[protocol]?.last24h || '0'),
          18,
          false,
        )
        prevPoints += Number(_pointsChange)
      }
    }

    return [points || 0, prevPoints || 0]
  }, [pointsInfo])

  const [zerolendZkPoints, zerolendZkPointsChange] = useMemo(() => {
    if (!pointsInfo || !pointsInfo?.byChain || !Object.keys(pointsInfo?.byChain || {})?.length) {
      return [0, 0]
    }

    let prevPoints: number = 0
    let points: number = 0
    const chains = Object.keys(pointsInfo?.byChain || {})
    for (const chain of chains) {
      if (chain !== '9') continue // Skip for non zksync.

      const protocols = Object.keys(pointsInfo?.byChain[chain]?.byProtocol || {})
      for (const protocol of protocols) {
        if (!protocol.toLowerCase().includes('zerolend')) continue // skip for non syncswap pools.

        const _points = formatBigNumberNoDecimal(
          BigInt(pointsInfo?.byChain[chain]?.byProtocol[protocol]?.current || '0'),
          18,
          false,
        )
        points += Number(_points)

        const _pointsChange = formatBigNumberNoDecimal(
          BigInt(pointsInfo?.byChain[chain]?.byProtocol[protocol]?.last24h || '0'),
          18,
          false,
        )
        prevPoints += Number(_pointsChange)
      }
    }

    return [points || 0, prevPoints || 0]
  }, [pointsInfo])

  const [spectraLpPoints, spectraLpPointsChange] = useMemo(() => {
    if (!pointsInfo || !pointsInfo?.byChain || !Object.keys(pointsInfo?.byChain || {})?.length) {
      return [0, 0]
    }

    let prevPoints: number = 0
    let points: number = 0
    const chains = Object.keys(pointsInfo?.byChain || {})
    for (const chain of chains) {
      if (chain !== '1') continue // Skip for non eth.

      const protocols = Object.keys(pointsInfo?.byChain[chain]?.byProtocol || {})
      for (const protocol of protocols) {
        if (!protocol.toLowerCase().includes('spectra_lp')) continue // skip for non syncswap pools.

        const _points = formatBigNumberNoDecimal(
          BigInt(pointsInfo?.byChain[chain]?.byProtocol[protocol]?.current || '0'),
          18,
          false,
        )
        points += Number(_points)

        const _pointsChange = formatBigNumberNoDecimal(
          BigInt(pointsInfo?.byChain[chain]?.byProtocol[protocol]?.last24h || '0'),
          18,
          false,
        )
        prevPoints += Number(_pointsChange)
      }
    }

    return [points || 0, prevPoints || 0]
  }, [pointsInfo])

  const [spectraYtPoints, spectraYtPointsChange] = useMemo(() => {
    if (!pointsInfo || !pointsInfo?.byChain || !Object.keys(pointsInfo?.byChain || {})?.length) {
      return [0, 0]
    }

    let prevPoints: number = 0
    let points: number = 0
    const chains = Object.keys(pointsInfo?.byChain || {})
    for (const chain of chains) {
      if (chain !== '1') continue // Skip for non eth.

      const protocols = Object.keys(pointsInfo?.byChain[chain]?.byProtocol || {})
      for (const protocol of protocols) {
        if (!protocol.toLowerCase().includes('spectra_yt')) continue // skip for non syncswap pools.

        const _points = formatBigNumberNoDecimal(
          BigInt(pointsInfo?.byChain[chain]?.byProtocol[protocol]?.current || '0'),
          18,
          false,
        )
        points += Number(_points)

        const _pointsChange = formatBigNumberNoDecimal(
          BigInt(pointsInfo?.byChain[chain]?.byProtocol[protocol]?.last24h || '0'),
          18,
          false,
        )
        prevPoints += Number(_pointsChange)
      }
    }

    return [points || 0, prevPoints || 0]
  }, [pointsInfo])

  const [rfxVaultZkPoints, rfxVaultZkPointsChange] = useMemo(() => {
    if (!pointsInfo || !pointsInfo?.byChain || !Object.keys(pointsInfo?.byChain || {})?.length) {
      return [0, 0]
    }

    let prevPoints: number = 0
    let points: number = 0
    const chains = Object.keys(pointsInfo?.byChain || {})
    for (const chain of chains) {
      if (chain !== '9') continue // Skip for non zksync.

      const protocols = Object.keys(pointsInfo?.byChain[chain]?.byProtocol || {})
      for (const protocol of protocols) {
        if (!protocol.toLowerCase().includes('rfx_rfx')) continue // skip for non syncswap pools.
        const _points = formatBigNumberNoDecimal(
          BigInt(pointsInfo?.byChain[chain]?.byProtocol[protocol]?.current || '0'),
          18,
          false,
        )
        points += Number(_points)

        const _pointsChange = formatBigNumberNoDecimal(
          BigInt(pointsInfo?.byChain[chain]?.byProtocol[protocol]?.last24h || '0'),
          18,
          false,
        )
        prevPoints += Number(_pointsChange)
      }
    }

    return [points || 0, prevPoints || 0]
  }, [pointsInfo])

  const [rfxPoolZkPoints, rfxPoolZkPointsChange] = useMemo(() => {
    if (!pointsInfo || !pointsInfo?.byChain || !Object.keys(pointsInfo?.byChain || {})?.length) {
      return [0, 0]
    }

    let prevPoints: number = 0
    let points: number = 0
    const chains = Object.keys(pointsInfo?.byChain || {})
    for (const chain of chains) {
      if (chain !== '9') continue // Skip for non zksync.

      const protocols = Object.keys(pointsInfo?.byChain[chain]?.byProtocol || {})
      for (const protocol of protocols) {
        if (!protocol.toLowerCase().includes('rfx_pool')) continue // skip for non syncswap pools.

        const _points = formatBigNumberNoDecimal(
          BigInt(pointsInfo?.byChain[chain]?.byProtocol[protocol]?.current || '0'),
          18,
          false,
        )
        points += Number(_points)

        const _pointsChange = formatBigNumberNoDecimal(
          BigInt(pointsInfo?.byChain[chain]?.byProtocol[protocol]?.last24h || '0'),
          18,
          false,
        )
        prevPoints += Number(_pointsChange)
      }
    }

    return [points || 0, prevPoints || 0]
  }, [pointsInfo])

  const activitySubtotal = useMemo(() => {
    return Math.round(
      uniswapPoints +
        syncswapZkPoints +
        syncswapSophonPoints +
        zerolendZkPoints +
        spectraLpPoints +
        spectraYtPoints +
        rfxPoolZkPoints +
        rfxVaultZkPoints,
    )
  }, [
    uniswapPoints,
    syncswapZkPoints,
    syncswapSophonPoints,
    zerolendZkPoints,
    spectraYtPoints,
    spectraLpPoints,
    rfxVaultZkPoints,
    rfxPoolZkPoints,
  ])

  const myLeaderboard = useMemo(() => {
    return relativeLeaderboard?.rankings?.find((l) => l.walletAddress.toLowerCase() === account?.address?.toLowerCase())
  }, [relativeLeaderboard])

  return (
    <div className="h-full w-full max-w-full flex flex-col gap-12 py-8 px-4 items-start justify-center">
      <div
        className={`min-h-56 w-full max-w-full grid grid-cols-1 grid-rows-3 md:grid-cols-3 md:grid-rows-1 gap-0 p-0 m-0 rounded-2xl top-row-box-shadow overflow-hidden ${theme.isLightTheme ? 'bg-white' : 'bg-stone-900'}`}
      >
        <UserProfile username={username?.data || 'fren'} />

        <div
          className={`flex flex-col md:flex-row items-start justify-start md:items-end md:justify-between gap-4 md:gap-7 p-4 border-0 border-t-[1px] border-l-0 md:border-t-0 md:border-l-[1px] ${theme.isLightTheme ? 'border-stone-300' : 'border-stone-800'}`}
        >
          <div className="w-full max-w-full md:w-fit md:max-w-[48%] flex flex-col items-start justify-start">
            <p
              className={`w-full max-w-full font-Suisse-Intl font-[450] text-5xl ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'} text-wrap`}
            >
              {totalPointsFormattedCompact}
            </p>
            <div className={`w-fit flex items-center justify-center gap-1 z-[1000]`}>
              <p
                className={`w-auto grow font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} text-wrap`}
              >
                Total Points
              </p>
              <Tooltip
                hasArrow={true}
                label={
                  <div className="w-full max-w-full flex flex-col gap-1">
                    <p>
                      <b>{`This is your current point balance.`}</b>
                      {` Points can be converted to our governance token
                        ($NOON) when we launch it (~Q2 2025).`}
                    </p>
                  </div>
                }
                bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                placement="top"
                borderRadius={'rounded-lg'}
                paddingX={'px-3'}
                paddingY={'py-[6px]'}
                className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
              >
                <div className={`w-6 h-6 ${theme.isLightTheme ? 'text-stone-300' : 'text-stone-500'} shrink-0`}>
                  <InfoFilledIcon className="w-full h-full" />
                </div>
              </Tooltip>
            </div>
          </div>

          <div className="w-full max-w-full md:w-fit md:max-w-[48%] flex flex-col items-center justify-start gap-2">
            {(chainAndPointsByChain ? Object.keys(chainAndPointsByChain) : []).map((chain, i) => (
              <div key={i} className="w-full max-w-full flex flex-row items-center justify-between gap-2">
                <div className="w-fit max-w-[48%] flex flex-row items-center justify-start gap-1">
                  <div className={`h-5 w-5 rounded-[100px] shrink-0`}>
                    {chain.toLowerCase() === 'referrals' ? (
                      <ReferralIcon
                        className={`w-full h-full ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
                      />
                    ) : (
                      <img
                        className="w-full h-full object-contain"
                        src={`${process.env.PUBLIC_URL}/assets/chains/${chain.toLowerCase() || 'ethereum'}.svg`}
                      />
                    )}
                  </div>
                  <p
                    className={`w-auto grow font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-300'} capitalize text-wrap`}
                  >
                    {chain}
                  </p>
                </div>
                <p
                  className={`grow max-w-[48%] text-wrap font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'} text-right`}
                >
                  {chainAndPointsByChain ? chainAndPointsByChain[chain].toString() || '0' : '0'}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div
          className={`relative flex flex-col items-start justify-end gap-4 p-4 border-0 border-t-[1px] border-l-0 md:border-t-0 md:border-l-[1px] ${theme.isLightTheme ? 'border-stone-300' : 'border-stone-800'} overflow-hidden`}
        >
          <div className="absolute left-0 p-0 top-0 h-grow w-auto md:w-full md:h-full">
            <img src={NoonLogos} className="m-0 p-0 w-full h-full" />
          </div>

          <div className="w-full max-w-full flex flex-col items-center justify-start gap-0 z-[100]">
            <p
              className={`w-fit font-Suisse-Intl font-medium text-sm rounded-t-lg border-t-[1px] border-x-[1px] border-b-0 py-[2px] px-2 ${theme.isLightTheme ? 'coming-soon-light text-[#AB8F7C] border-[#1111111A]' : 'text-[#FFDAC1] coming-soon-dark border-[#FFFFFF1A]'} text-center`}
            >
              COMING SOON
            </p>
            <p
              className={`w-full max-w-full text-wrap font-Suisse-Intl font-medium text-sm rounded-[40px] border-[1px] py-[6px] px-2 ${theme.isLightTheme ? 'points-estimate-calc text-[#AB8F7C] border-[#1111111A]' : 'text-[#FFDAC1] points-estimate-calc-dark border-[#FFFFFF1A]'} text-center flex items-center justify-center gap-1`}
            >
              <div className={`w-4 h-4 ${theme.isLightTheme ? 'text-[#AB8F7C]' : 'text-[#FFDAC1]'}`}>
                <SpinnerIcon className="w-full h-full font-medium" />
              </div>
              Estimate the value of your points
            </p>
          </div>
        </div>
      </div>

      <div
        className={`w-full max-w-full min-h-56 flex flex-col md:flex-row items-stretch justify-between gap-6 p-0 m-0 overflow-hidden border-0`}
      >
        <div
          className={`relative flex flex-col items-start justify-start gap-7 p-5 ${theme.isLightTheme ? 'bg-white' : 'bg-stone-900'} rounded-2xl grow overflow-hidden`}
        >
          <div className="absolute left-0 p-0 top-0 z-[10] h-full w-[68%]">
            <img src={elipsis38} className="m-0 p-0 w-full h-full" />
          </div>
          <div className="absolute left-0 p-0 bottom-0 z-[11] h-full w-[68%]">
            <img src={elipsis39} className="m-0 p-0 w-full h-full" />
          </div>
          <div className="absolute right-0 p-0 top-0 z-[12] h-full w-[42%]">
            <img src={elipsis40} className="m-0 p-0 w-full h-full" />
          </div>
          <div className="hidden md:block absolute right-0 p-0 bottom-0 md:-top-10 w-[45%] max-w-[45%] md:w-[55%] h-auto max-h-full md:max-h-full z-20 md:aspect-square">
            <img src={ScaleLogo} className="m-0 p-0 w-full h-full" />
          </div>

          <div className="w-full max-w-full md:w-fit  md:max-w-[45%] flex flex-col items-start justify-start gap-[6px]">
            <p
              className={`font-Louize font-normal text-4xl ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'} text-wrap z-30 opacity-60`}
            >
              {`Refer friends to earn boosts for you and your friends`}
            </p>
            <p
              className={`font-Suisse-Intl font-semiMedium text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} text-wrap z-30 opacity-60`}
            >
              You earn 10% of their points, they earn 10% boost{' '}
              <a
                target="_blank"
                href="https://mirror.xyz/nooncapital.eth/EjihOJP_wyvnL-BFj1lGvfgLj1CKa6nGFqiysFWoeGw"
                rel="noreferrer"
              >
                <u>(view details)</u>
              </a>
            </p>
          </div>

          <Tooltip
            hasArrow={true}
            label={
              <div className="w-full max-w-full flex flex-col gap-1">
                <p>{`To refer others, please share the copied link and ask the user to confirm the referral code`}</p>
              </div>
            }
            bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
            color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
            placement="top"
            borderRadius={'rounded-lg'}
            paddingX={'px-3'}
            paddingY={'py-[6px]'}
            className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
          >
            <button
              disabled={!referralCode}
              onClick={() => {
                setReferralCodeBtnTxt('Copying Referral Link')
                navigator.clipboard.writeText(`https://app.noon.capital/get?referralCode=${referralCode}`)
                setIsReferralCodeCopied(true)
                setReferralCodeBtnTxt('Copied Referral Link')
                setTimeout(() => {
                  setIsReferralCodeCopied(false)
                  setReferralCodeBtnTxt('Copy Referral Link')
                }, 2 * 1000)
              }}
              className={`w-full md:w-fit rounded-[184px] py-3 px-[18px] bg-indigo-600 flex gap-[6px] items-center justify-center z-30 ${referralCode ? 'cursor-pointer' : 'cursor-not-allowed'} ${referralCode ? 'cursor-pointer' : 'cursor-not-allowed'}`}
            >
              <p className={`w-fit max-w-full text-wrap font-Suisse-Intl font-medium text-base text-stone-50`}>
                {referralCodeBtnText}
              </p>
              <div className={`w-6 h-6 text-stone-50`}>
                {isReferralCodeCopied ? (
                  <CheckTickCircle className="w-full h-full" />
                ) : (
                  <PasteIcon className="w-full h-full" />
                )}
              </div>
            </button>
          </Tooltip>

          <div className="block md:hidden w-full md:w-[50%] h-auto max-h-full z-20 ml-auto mr-auto">
            <img src={ScaleLogo} className="m-0 p-0 w-full h-full" />
          </div>
        </div>

        <div
          className={`relative w-full md:w-[40%] md:max-w-[40%] p-5 flex flex-col items-start gap-4 justify-between ${theme.isLightTheme ? 'bg-indigo-200' : 'bg-indigo-950'} rounded-2xl`}
        >
          <div className="hidden md:block absolute right-0 p-0 top-0 z-10 h-auto w-[42%] max-w-[42%] max-h-full aspect-square">
            <img src={HoldingsIcon} className="m-0 p-0 w-full h-full" />
          </div>

          <div className="block md:hidden absolute right-0 p-0 top-0 z-10 h-[150px] w-auto aspect-square">
            <img src={HoldingsIcon} className="m-0 p-0 w-full h-full" />
          </div>

          <p
            className={`mt-[150px] md:mt-0 w-full max-w-full md:w-[60%] md:max-w-[60%] font-Louize font-normal text-4xl tracking-tighter ${theme.isLightTheme ? 'text-indigo-600' : 'text-indigo-200'}  text-wrap`}
          >
            Hold USN and sUSN to earn Points
          </p>

          <div className="w-full max-w-full md:w-[60%] md:max-w-[60%] grid grid-rows-1 grid-cols-2 items-center justify-start gap-2">
            <NavLink
              to="/stake"
              className="grow rounded-[184px] py-3 px-[18px] bg-indigo-600 font-Suisse-Intl font-medium text-base text-stone-50 text-center"
            >
              Get sUSN <ArrowRightIcon className="w-6 h-6 inline-block" />
            </NavLink>
            <NavLink
              to="/get"
              className={`grow rounded-[184px] py-3 px-[18px] border-[1px] ${theme.isLightTheme ? 'bg-stone-100 border-stone-200' : 'bg-stone-900 border-stone-800'} font-Suisse-Intl font-medium text-base ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'} text-center`}
            >
              Get USN <ArrowRightIcon className="w-6 h-6 inline-block" />
            </NavLink>
          </div>
        </div>
      </div>

      <div className="w-full max-w-full flex flex-col items-start justify-start gap-2">
        <p
          className={`w-full max-w-full text-wrap font-Louize font-normal text-2xl tracking-tighter ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'} opacity-60`}
        >
          Points breakdown
        </p>

        <div
          className={`w-full overflow-hidden border-[1px] ${theme.isLightTheme ? 'border-stone-300' : 'border-stone-800'} container-shadow rounded-xl border-collapse`}
        >
          <Table className={`p-0 m-0 border-0 text-center border-collapse`}>
            <TableHeader
              className={`border-[1px] border-t-0 border-l-0 border-r-0 ${theme.isLightTheme ? 'border-stone-200 bg-stone-50' : 'bg-stone-900 border-stone-700'} font-Suisse-Intl font-semiMedium text-base`}
            >
              <TableRow>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200 bg-stone-50' : 'bg-stone-900 border-stone-700'} p-4 text-stone-500`}
                >
                  No.
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 ${theme.isLightTheme ? 'border-stone-200 bg-stone-50' : 'bg-stone-900 border-stone-700'} p-4 text-stone-500`}
                >
                  Name
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 ${theme.isLightTheme ? 'border-stone-200 bg-stone-50' : 'bg-stone-900 border-stone-700'} p-4 text-stone-500`}
                >
                  Chain
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 ${theme.isLightTheme ? 'border-stone-200 bg-stone-50' : 'bg-stone-900 border-stone-700'} p-4 text-stone-500`}
                >
                  Points Multiplier
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 ${theme.isLightTheme ? 'border-stone-200 bg-stone-50' : 'bg-stone-900 border-stone-700'} p-4 text-stone-500`}
                >
                  Points Received (1d Change)
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 border-r-0 ${theme.isLightTheme ? 'border-stone-200 bg-stone-50' : 'bg-stone-900 border-stone-700'} p-4  text-stone-500 text-center`}
                >
                  Ways to Earn
                </TableHead>
              </TableRow>
            </TableHeader>

            <TableBody>
              <TableRow
                onClick={() => setHoldingsVisible((b) => !b)}
                className={`border-b-0 ${theme.isLightTheme ? 'table-section-bg' : 'bg-stone-950'}`}
              >
                <TableCell
                  colSpan={4}
                  className={`border-[1px] !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 text-stone-500 font-Louize font-normal text-2xl tracking-tighter text-left h-full`}
                >
                  <div className="w-full max-w-full h-full max-h-full flex items-center justify-between gap-1 cursor-pointer">
                    <div className="grow">
                      Holdings&nbsp;
                      <Tooltip
                        hasArrow={true}
                        label={
                          <div className="w-full max-w-full flex flex-col gap-1">
                            <p>{`These are points you have earned for holding USN and sUSN in your wallet. If you have deployed USN and sUSN into any protocols, we have categorised the points earned into the “Activity” category.`}</p>
                          </div>
                        }
                        bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                        color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                        placement="top-start"
                        borderRadius={'rounded-lg'}
                        paddingX={'px-3'}
                        paddingY={'py-[6px]'}
                        className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
                      >
                        <InfoFilledIcon className="w-6 h-6 inline" />
                      </Tooltip>
                    </div>
                    <>
                      <div className="w-6 h-6 shrink-0">
                        {holdingsVisible ? (
                          <MinimizeIcon className="w-full h-full" />
                        ) : (
                          <MaximizeIcon className="w-full h-full" />
                        )}
                      </div>
                    </>
                  </div>
                </TableCell>
                <TableCell
                  className={`border-[1px] ${theme.isLightTheme ? 'border-stone-200 text-stone-900 subtotal-bg' : 'border-stone-700 text-stone-50 subtotal-dark-bg'} p-4 font-Suisse-Intl font-normal text-base`}
                >
                  <div className="w-full h-full flex items-center justify-between">
                    <p className="text-stone-500">SUBTOTAL</p>
                    <p>{formatStringToNumberNoDecimal(holdingsSubTotal?.toString() || '0', true)}</p>
                  </div>
                </TableCell>
                <TableCell
                  className={`border-[1px] !border-r-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 text-stone-500`}
                ></TableCell>
              </TableRow>

              <TableRow
                className={`${holdingsVisible ? '' : 'hidden'} ${theme.isLightTheme ? 'bg-white text-stone-900' : 'bg-stone-900 text-stone-50'} font-Suisse-Intl font-semiMedium text-base border-b-0`}
              >
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 font-Suisse-Intl font-semiMedium text-base`}
                >
                  1
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="w-fit max-w-full flex items-center justify-start gap-2">
                    <USNIcon className="w-6 h-6 shrink-0 inline" />
                    <p>USN</p>
                    <Tooltip
                      hasArrow={true}
                      label={
                        <div className="w-full max-w-full flex flex-col gap-1">
                          <p>{`All points earned are tracked and displayed.`}</p>
                        </div>
                      }
                      bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                      color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                      placement="top-start"
                      borderRadius={'rounded-lg'}
                      paddingX={'px-3'}
                      paddingY={'py-[6px]'}
                      className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
                    >
                      <p
                        className={`w-fit max-w-full rounded-lg py-[2px] px-2 ${theme.isLightTheme ? 'bg-[#E0EAFF] text-[#565DD5]' : 'bg-[#3F4270] text-[#E0EAFF]'} text-sm flex items-center justify-center gap-[2px] cursor-pointer`}
                      >
                        <p>•</p>
                        <p>LIVE</p>
                      </p>
                    </Tooltip>
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="min-w-full w-fit max-w-fit h-full flex flex-row items-center justify-start -space-x-2 rtl:space-x-reverse">
                    {['Ethereum', 'Sophon', 'Zksync'].reverse().map((chain) => (
                      <img
                        key={chain}
                        className="w-6 h-6 rounded-[100px] shrink-0"
                        src={`${process.env.PUBLIC_URL}/assets/chains/${chain?.toLowerCase() || 'ethereum'}.svg`}
                        alt=""
                      />
                    ))}
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="m-0 w-full h-full flex flex-col md:flex-row items-start md:items-center justify-center md:justify-start flex-wrap p-0 gap-2">
                    <Popover closeOnEsc={true} closeOnBlur={true}>
                      <PopoverTrigger>
                        <div
                          className={`w-fit rounded-[64px] p-[2px] pr-[6px] gap-1 flex items-center justify-center ${theme.isLightTheme ? 'bg-indigo-200' : 'bg-indigo-950'} cursor-pointer`}
                        >
                          <USNIcon className="w-6 h-6 rounded-[48px] p-0" />
                          <p
                            className={`font-Suisse-Intl font-medium text-sm ${theme.isLightTheme ? 'text-[#4A4BB8]' : 'text-[#E0EAFF]'}`}
                          >
                            70x
                          </p>
                        </div>
                      </PopoverTrigger>
                      <PopoverContent
                        padding={'12px'}
                        borderWidth={'1'}
                        borderRadius={'8'}
                        borderColor={theme.isLightTheme ? '#E7E5E4' : '#44403C'}
                        backgroundColor={theme.isLightTheme ? '#FFFFFF' : '#000000'}
                        className={`!border-[1px] ${theme.isLightTheme ? '!bg-white !text-stone-900 !border-stone-200' : '!bg-black !text-stone-50 !border-stone-700'} rounded-lg p-3 z-[1000]`}
                      >
                        <div
                          className={`w-fit max-w-fit h-fit flex flex-col items-start justify-start gap-4 ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                        >
                          <p
                            className={`w-fit max-w-fit font-Louize font-normal text-[22px] tracking-tighter`}
                          >{`Protocol Rewards`}</p>
                          <div className="w-fit max-w-fit flex items-center justify-center gap-14">
                            <p
                              className={`w-fit max-w-fit font-Suisse-Intl font-semiMedium text-sm ${theme.isLightTheme ? 'text-stone-800' : 'text-stone-300'} text-wrap`}
                            >
                              <p
                                className={`w-fit max-w-full rounded-[4px] py-[2px] px-[6px] ${theme.isLightTheme ? 'bg-[#E0EAFF] text-[#565DD5]' : 'bg-[#3F4270] text-[#E0EAFF]'} text-sm font-Suisse-Intl font-medium inline-block`}
                              >
                                70x
                              </p>
                              &nbsp;Noon Points per <USNIcon className="w-6 h-6 shrink-0 inline-block" /> USN held in
                              wallet.
                              <br />
                              <br />
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`https://mirror.xyz/nooncapital.eth/EjihOJP_wyvnL-BFj1lGvfgLj1CKa6nGFqiysFWoeGw`}
                                className="underline"
                              >
                                Read more &nbsp;
                                <ArrowRightUpIcon
                                  className={`w-6 h-6 shrink-0 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} inline`}
                                />
                              </a>
                            </p>
                          </div>
                        </div>
                      </PopoverContent>
                    </Popover>
                    <Popover closeOnEsc={true} closeOnBlur={true}>
                      <PopoverTrigger>
                        <div
                          className={`w-fit rounded-[64px] p-[2px] pr-[6px] gap-1 flex items-center justify-center ${theme.isLightTheme ? 'bg-indigo-200' : 'bg-indigo-950'} cursor-pointer`}
                        >
                          <SophonIcon className="w-6 h-6 rounded-[48px] p-0" />
                          <p
                            className={`font-Suisse-Intl font-medium text-sm ${theme.isLightTheme ? 'text-[#4A4BB8]' : 'text-[#E0EAFF]'}`}
                          >
                            1x
                          </p>
                        </div>
                      </PopoverTrigger>
                      <PopoverContent
                        outline={0}
                        padding={'12px'}
                        borderWidth={'1'}
                        borderRadius={'8'}
                        borderColor={theme.isLightTheme ? '#E7E5E4' : '#44403C'}
                        backgroundColor={theme.isLightTheme ? '#FFFFFF' : '#000000'}
                        className={`!outline-none !border-[1px] ${theme.isLightTheme ? '!bg-white !text-stone-900 !border-stone-200 !focus:border-stone-200 !focus-visible:border-stone-200' : '!bg-black !text-stone-50 !border-stone-700 !focus:border-stone-700 !focus-visible:border-stone-700'} rounded-lg p-3 z-[1000]`}
                      >
                        <div
                          className={`w-fit max-w-fit h-fit flex flex-col items-start justify-start gap-4 ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                        >
                          <p
                            className={`w-fit max-w-fit font-Louize font-normal text-[22px] tracking-tighter`}
                          >{`Partner Rewards`}</p>
                          <div className="w-fit max-w-fit flex items-center justify-center gap-14">
                            <p
                              className={`w-fit max-w-fit font-Suisse-Intl font-semiMedium text-sm ${theme.isLightTheme ? 'text-stone-800' : 'text-stone-300'} text-wrap`}
                            >
                              <p
                                className={`w-fit max-w-full rounded-[4px] py-[2px] px-[6px] ${theme.isLightTheme ? 'bg-[#E0EAFF] text-[#565DD5]' : 'bg-[#3F4270] text-[#E0EAFF]'} text-sm font-Suisse-Intl font-medium inline-block`}
                              >
                                1x
                              </p>
                              &nbsp;Sophon Points per <USNIcon className="w-6 h-6 shrink-0 inline-block" /> USN /{' '}
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/tokens/${'sUSN'}.svg`}
                                className="w-6 h-6 shrink-0 inline-block"
                              />{' '}
                              sUSN held in wallet on Sophon chain, via Sophon Farms page (details coming soon).
                              <br />
                              <br />
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`https://farm.sophon.xyz/`}
                                className="underline"
                              >
                                View rewards &nbsp;
                                <ArrowRightUpIcon
                                  className={`w-6 h-6 shrink-0 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} inline`}
                                />
                              </a>
                            </p>
                          </div>
                        </div>
                      </PopoverContent>
                    </Popover>
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4`}
                >
                  <div className="w-fit max-w-fit min-w-fit h-full flex items-center justify-center gap-1">
                    <div>{formatStringToNumberNoDecimal(usnPointsHoldings?.toString() || '0', true)}</div>
                    <p
                      className={`font-Suisse-Intl font-medium text-sm rounded-md py-[2px] px-2 ${theme.isLightTheme ? 'text-[#027A3E] bg-[#D1FAE5]' : 'text-[#D1FAE5] bg-[#054F2A]'} leading-5`}
                    >
                      {usnPointsHoldingsChange > 0
                        ? `+${formatStringToNumberNoDecimal(usnPointsHoldingsChange?.toString() || '0', true)}`
                        : `${formatStringToNumberNoDecimal(usnPointsHoldingsChange?.toString() || '0', true)}`}
                    </p>
                  </div>
                </TableHead>
                <TableHead
                  className={`h-full border-[1px] !border-l-0 !border-t-0 !border-r-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700 bg-stone-950'} p-4 text-stone-500 w-full max-h-full flex items-center justify-start gap-0`}
                >
                  <NavLink
                    to="/get"
                    className={`min-w-full w-fit max-w-fit h-fit max-h-full rounded-[40px] py-[6px] px-3 flex gap-[6px] border-[1px] items-center justify-center ${theme.isLightTheme ? 'bg-stone-100 border-stone-300 text-stone-900' : 'bg-stone-900 border-stone-800 text-stone-50'}`}
                  >
                    <p
                      className={`min-w-full w-fit max-w-fit font-Suisse-Intl font-medium text-sm text-nowrap text-center`}
                    >
                      Get <USNIcon className="w-5 h-5 shrink-0 inline" />
                      &nbsp;USN&nbsp;
                      <ArrowRightIcon
                        className={`w-5 h-5 shrink-0 inline ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
                      />
                    </p>
                  </NavLink>
                </TableHead>
              </TableRow>

              <TableRow
                className={`${holdingsVisible ? '' : 'hidden'} ${theme.isLightTheme ? 'bg-white text-stone-900' : 'bg-stone-900 text-stone-50'} font-Suisse-Intl font-semiMedium text-base border-b-0 h-full`}
              >
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 font-Suisse-Intl font-semiMedium text-base`}
                >
                  2
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4`}
                >
                  <div className="w-fit max-w-full flex items-center justify-start gap-2">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/tokens/${'sUSN'}.svg`}
                      className="w-6 h-6 shrink-0 inline"
                    />
                    <p>sUSN</p>
                    <Tooltip
                      hasArrow={true}
                      label={
                        <div className="w-full max-w-full flex flex-col gap-1">
                          <p>{`All points earned are tracked and displayed.`}</p>
                        </div>
                      }
                      bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                      color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                      placement="top-start"
                      borderRadius={'rounded-lg'}
                      paddingX={'px-3'}
                      paddingY={'py-[6px]'}
                      className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
                    >
                      <p
                        className={`w-fit max-w-full rounded-lg py-[2px] px-2 ${theme.isLightTheme ? 'bg-[#E0EAFF] text-[#565DD5]' : 'bg-[#3F4270] text-[#E0EAFF]'} text-sm flex items-center justify-center gap-[2px] cursor-pointer`}
                      >
                        <p>•</p>
                        <p>LIVE</p>
                      </p>
                    </Tooltip>
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="min-w-full w-fit max-w-fit h-full flex items-center justify-start -space-x-2 rtl:space-x-reverse">
                    {['Ethereum', 'Sophon', 'Zksync'].reverse().map((chain) => (
                      <img
                        key={chain}
                        className="w-6 h-6 rounded-[100px]"
                        src={`${process.env.PUBLIC_URL}/assets/chains/${chain?.toLowerCase() || 'ethereum'}.svg`}
                        alt=""
                      />
                    ))}
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="m-0 w-full h-full flex flex-col md:flex-row items-start md:items-center justify-center md:justify-start  flex-wrap p-0 gap-2">
                    <Popover closeOnEsc={true} closeOnBlur={true}>
                      <PopoverTrigger>
                        <div
                          className={`w-fit rounded-[64px] p-[2px] pr-[6px] gap-1 flex items-center justify-center ${theme.isLightTheme ? 'bg-indigo-200' : 'bg-indigo-950'} cursor-pointer`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/tokens/${'sUSN'}.svg`}
                            className="w-6 h-6 rounded-[48px] p-0"
                          />
                          <p
                            className={`font-Suisse-Intl font-medium text-sm ${theme.isLightTheme ? 'text-[#4A4BB8]' : 'text-[#E0EAFF]'}`}
                          >
                            10x
                          </p>
                        </div>
                      </PopoverTrigger>
                      <PopoverContent
                        padding={'12px'}
                        borderWidth={'1'}
                        borderRadius={'8'}
                        borderColor={theme.isLightTheme ? '#E7E5E4' : '#44403C'}
                        backgroundColor={theme.isLightTheme ? '#FFFFFF' : '#000000'}
                        className={`!border-[1px] ${theme.isLightTheme ? '!bg-white !text-stone-900 !border-stone-200' : '!bg-black !text-stone-50 !border-stone-700'} rounded-lg p-3 z-[1000]`}
                      >
                        <div
                          className={`w-fit max-w-fit h-fit flex flex-col items-start justify-start gap-4 ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                        >
                          <p
                            className={`w-fit max-w-fit font-Louize font-normal text-[22px] tracking-tighter`}
                          >{`Protocol Rewards`}</p>
                          <div className="w-fit max-w-fit flex items-center justify-center gap-14">
                            <p
                              className={`w-fit max-w-fit font-Suisse-Intl font-semiMedium text-sm ${theme.isLightTheme ? 'text-stone-800' : 'text-stone-300'} text-wrap`}
                            >
                              <p
                                className={`w-fit max-w-full rounded-[4px] py-[2px] px-[6px] ${theme.isLightTheme ? 'bg-[#E0EAFF] text-[#565DD5]' : 'bg-[#3F4270] text-[#E0EAFF]'} text-sm font-Suisse-Intl font-medium inline-block`}
                              >
                                10x
                              </p>
                              &nbsp;Noon Points per{' '}
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/tokens/${'sUSN'}.svg`}
                                className="w-6 h-6 shrink-0 inline-block"
                              />{' '}
                              sUSN held in wallet.
                              <br />
                              <br />
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`https://mirror.xyz/nooncapital.eth/EjihOJP_wyvnL-BFj1lGvfgLj1CKa6nGFqiysFWoeGw`}
                                className="underline"
                              >
                                Read more &nbsp;
                                <ArrowRightUpIcon
                                  className={`w-6 h-6 shrink-0 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} inline`}
                                />
                              </a>
                            </p>
                          </div>
                        </div>
                      </PopoverContent>
                    </Popover>
                    <Popover closeOnEsc={true} closeOnBlur={true}>
                      <PopoverTrigger>
                        <div
                          className={`w-fit rounded-[64px] p-[2px] pr-[6px] gap-1 flex items-center justify-center ${theme.isLightTheme ? 'bg-indigo-200' : 'bg-indigo-950'} cursor-pointer`}
                        >
                          <SophonIcon className="w-6 h-6 rounded-[48px] p-0" />
                          <p
                            className={`font-Suisse-Intl font-medium text-sm ${theme.isLightTheme ? 'text-[#4A4BB8]' : 'text-[#E0EAFF]'}`}
                          >
                            1x
                          </p>
                        </div>
                      </PopoverTrigger>
                      <PopoverContent
                        outline={0}
                        padding={'12px'}
                        borderWidth={'1'}
                        borderRadius={'8'}
                        borderColor={theme.isLightTheme ? '#E7E5E4' : '#44403C'}
                        backgroundColor={theme.isLightTheme ? '#FFFFFF' : '#000000'}
                        className={`!outline-none !border-[1px] ${theme.isLightTheme ? '!bg-white !text-stone-900 !border-stone-200 !focus:border-stone-200 !focus-visible:border-stone-200' : '!bg-black !text-stone-50 !border-stone-700 !focus:border-stone-700 !focus-visible:border-stone-700'} rounded-lg p-3 z-[1000]`}
                      >
                        <div
                          className={`w-fit max-w-fit h-fit flex flex-col items-start justify-start gap-4 ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                        >
                          <p
                            className={`w-fit max-w-fit font-Louize font-normal text-[22px] tracking-tighter`}
                          >{`Partner Rewards`}</p>
                          <div className="w-fit max-w-fit flex items-center justify-center gap-14">
                            <p
                              className={`w-fit max-w-fit font-Suisse-Intl font-semiMedium text-sm ${theme.isLightTheme ? 'text-stone-800' : 'text-stone-300'} text-wrap`}
                            >
                              <p
                                className={`w-fit max-w-full rounded-[4px] py-[2px] px-[6px] ${theme.isLightTheme ? 'bg-[#E0EAFF] text-[#565DD5]' : 'bg-[#3F4270] text-[#E0EAFF]'} text-sm font-Suisse-Intl font-medium inline-block`}
                              >
                                1x
                              </p>
                              &nbsp;Sophon Points per <USNIcon className="w-6 h-6 shrink-0 inline-block" /> USN /{' '}
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/tokens/${'sUSN'}.svg`}
                                className="w-6 h-6 shrink-0 inline-block"
                              />{' '}
                              sUSN held in wallet on Sophon chain, via Sophon Farms page (details coming soon).
                              <br />
                              <br />
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`https://farm.sophon.xyz/`}
                                className="underline"
                              >
                                View rewards &nbsp;
                                <ArrowRightUpIcon
                                  className={`w-6 h-6 shrink-0 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} inline`}
                                />
                              </a>
                            </p>
                          </div>
                        </div>
                      </PopoverContent>
                    </Popover>
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4`}
                >
                  <div className="w-fit max-w-fit min-w-fit h-full flex items-center justify-center gap-1">
                    <div>{formatStringToNumberNoDecimal(susnPointsHoldings?.toString() || '0', true)}</div>
                    <p
                      className={`font-Suisse-Intl font-medium text-sm rounded-md py-[2px] px-2 ${theme.isLightTheme ? 'text-[#027A3E] bg-[#D1FAE5]' : 'text-[#D1FAE5] bg-[#054F2A]'} leading-5`}
                    >
                      {susnPointsHoldingsChange > 0
                        ? `+${formatStringToNumberNoDecimal(susnPointsHoldingsChange?.toString() || '0', true)}`
                        : `${formatStringToNumberNoDecimal(susnPointsHoldingsChange?.toString() || '0', true)}`}
                    </p>
                  </div>
                </TableHead>
                <TableHead
                  className={`h-full border-0 !border-l-0 !border-t-0 !border-r-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700 bg-stone-950'} p-4 text-stone-500 flex items-center justify-start`}
                >
                  <NavLink
                    to="/stake"
                    className={`min-w-full w-fit max-w-fit h-fit max-h-full rounded-[40px] py-[6px] px-3 flex gap-[6px] border-[1px] items-center justify-center ${theme.isLightTheme ? 'bg-stone-100 border-stone-300 text-stone-900' : 'bg-stone-900 border-stone-800 text-stone-50'}`}
                  >
                    <p
                      className={`min-w-full w-fit max-w-fit font-Suisse-Intl font-medium text-sm text-nowrap text-center`}
                    >
                      Get <SUSNIcon className="w-5 h-5 shrink-0 inline" />
                      &nbsp;sUSN&nbsp;
                      <ArrowRightIcon
                        className={`w-5 h-5 shrink-0 inline ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
                      />
                    </p>
                  </NavLink>
                </TableHead>
              </TableRow>

              <TableRow
                className={`${theme.isLightTheme ? 'table-section-bg' : 'bg-stone-950'} border-0`}
                onClick={() => setActivityVisible((b) => !b)}
              >
                <TableCell
                  colSpan={4}
                  className={`border-[1px] !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 text-stone-500 font-Louize font-normal text-2xl tracking-tighter text-left h-full`}
                >
                  <div className="w-full max-w-full h-full max-h-full flex items-center justify-between gap-1 cursor-pointer">
                    <div className="grow">
                      Activity&nbsp;
                      <Tooltip
                        hasArrow={true}
                        label={
                          <div className="w-full max-w-full flex flex-col gap-1">
                            <p>{`These are points you have earned by deploying USN or sUSN into our partner protocols.`}</p>
                          </div>
                        }
                        bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                        color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                        placement="top-start"
                        borderRadius={'rounded-lg'}
                        paddingX={'px-3'}
                        paddingY={'py-[6px]'}
                        className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
                      >
                        <InfoFilledIcon className="w-6 h-6 inline" />
                      </Tooltip>
                    </div>
                    <>
                      <div className="w-6 h-6 shrink-0">
                        {activityVisible ? (
                          <MinimizeIcon className="w-full h-full" />
                        ) : (
                          <MaximizeIcon className="w-full h-full" />
                        )}
                      </div>
                    </>
                  </div>
                </TableCell>
                <TableCell
                  className={`border-[1px] ${theme.isLightTheme ? 'border-stone-200 text-stone-900 subtotal-bg' : 'border-stone-700 text-stone-50 subtotal-dark-bg'} p-4 font-Suisse-Intl font-normal text-base`}
                >
                  <div className="w-full h-full flex items-center justify-between">
                    <p className="text-stone-500">SUBTOTAL</p>
                    <p>{formatStringToNumberNoDecimal(activitySubtotal?.toString() || '0', true)}</p>
                  </div>
                </TableCell>
                <TableCell
                  className={`border-[1px] !border-r-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 text-stone-500`}
                ></TableCell>
              </TableRow>

              <TableRow
                className={`${activityVisible ? '' : 'hidden'} ${theme.isLightTheme ? 'bg-white text-stone-900' : 'bg-stone-900 text-stone-50'} font-Suisse-Intl font-semiMedium text-base border-b-0`}
              >
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 font-Suisse-Intl font-semiMedium text-base`}
                >
                  1
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="h-full w-full max-h-full max-w-full flex flex-col items-start justify-center gap-2">
                    <div className="w-fit max-w-full flex items-center justify-start gap-1">
                      <UNI className="w-6 h-6 shrink-0 inline"></UNI>
                      <p>Uniswap</p>
                      <Tooltip
                        hasArrow={true}
                        label={
                          <div className="w-full max-w-full flex flex-col gap-1">
                            <p>{`All points earned are tracked and displayed.`}</p>
                          </div>
                        }
                        bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                        color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                        placement="top-start"
                        borderRadius={'rounded-lg'}
                        paddingX={'px-3'}
                        paddingY={'py-[6px]'}
                        className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
                      >
                        <p
                          className={`w-fit max-w-full rounded-lg py-[2px] px-2 ${theme.isLightTheme ? 'bg-[#E0EAFF] text-[#565DD5]' : 'bg-[#3F4270] text-[#E0EAFF]'} text-sm flex items-center justify-center gap-[2px] cursor-pointer`}
                        >
                          <p>•</p>
                          <p>LIVE</p>
                        </p>
                      </Tooltip>
                    </div>
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="min-w-full w-fit max-w-fit h-full flex items-center justify-start -space-x-2 rtl:space-x-reverse">
                    {['Ethereum'].reverse().map((chain: string) => (
                      <img
                        key={chain}
                        className="w-6 h-6 rounded-[100px]"
                        src={`${process.env.PUBLIC_URL}/assets/chains/${chain?.toLowerCase() || 'ethereum'}.svg`}
                        alt=""
                      />
                    ))}
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="m-0 w-full h-full flex flex-col md:flex-row items-start md:items-center justify-center md:justify-start flex-wrap p-0 gap-2">
                    <Popover closeOnEsc={true} closeOnBlur={true}>
                      <PopoverTrigger>
                        <div
                          className={`w-fit rounded-[64px] p-[2px] pr-[6px] gap-1 flex items-center justify-center ${theme.isLightTheme ? 'bg-indigo-200' : 'bg-indigo-950'} cursor-pointer`}
                        >
                          <USNIcon className="w-6 h-6 rounded-[48px] p-0" />
                          <p
                            className={`font-Suisse-Intl font-medium text-sm ${theme.isLightTheme ? 'text-[#4A4BB8]' : 'text-[#E0EAFF]'}`}
                          >
                            280x
                          </p>
                        </div>
                      </PopoverTrigger>
                      <PopoverContent
                        padding={'12px'}
                        borderWidth={'1'}
                        borderRadius={'8'}
                        borderColor={theme.isLightTheme ? '#E7E5E4' : '#44403C'}
                        backgroundColor={theme.isLightTheme ? '#FFFFFF' : '#000000'}
                        className={`!border-[1px] ${theme.isLightTheme ? '!bg-white !text-stone-900 !border-stone-200' : '!bg-black !text-stone-50 !border-stone-700'} rounded-lg p-3 z-[1000]`}
                      >
                        <div
                          className={`w-fit max-w-fit h-fit flex flex-col items-start justify-start gap-4 ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                        >
                          <p
                            className={`w-fit max-w-fit font-Louize font-normal text-[22px] tracking-tighter`}
                          >{`Protocol Rewards`}</p>
                          <div className="w-fit max-w-fit flex items-center justify-center gap-14">
                            <p
                              className={`w-fit max-w-fit font-Suisse-Intl font-semiMedium text-sm ${theme.isLightTheme ? 'text-stone-800' : 'text-stone-300'} text-wrap`}
                            >
                              <p
                                className={`w-fit max-w-full rounded-[4px] py-[2px] px-[6px] ${theme.isLightTheme ? 'bg-[#E0EAFF] text-[#565DD5]' : 'bg-[#3F4270] text-[#E0EAFF]'} text-sm font-Suisse-Intl font-medium inline-block`}
                              >
                                4x
                              </p>
                              &nbsp;Noon Points per <USNIcon className="w-6 h-6 shrink-0 inline-block" />
                              &nbsp;{`USN deposited in active position `}
                              (in addition to Holdings points multiplier).
                              <br />
                              <br />
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`https://mirror.xyz/nooncapital.eth/EjihOJP_wyvnL-BFj1lGvfgLj1CKa6nGFqiysFWoeGw`}
                                className="underline"
                              >
                                Read more &nbsp;
                                <ArrowRightUpIcon
                                  className={`w-6 h-6 shrink-0 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} inline`}
                                />
                              </a>
                            </p>
                          </div>
                        </div>
                      </PopoverContent>
                    </Popover>
                    <Popover closeOnEsc={true} closeOnBlur={true}>
                      <PopoverTrigger>
                        <div
                          className={`w-fit rounded-[64px] p-[2px] pr-[6px] gap-1 flex items-center justify-center ${theme.isLightTheme ? 'bg-indigo-200' : 'bg-indigo-950'} cursor-pointer`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/tokens/${'sUSN'}.svg`}
                            className="w-6 h-6 rounded-[48px] p-0"
                          />
                          <p
                            className={`font-Suisse-Intl font-medium text-sm ${theme.isLightTheme ? 'text-[#4A4BB8]' : 'text-[#E0EAFF]'}`}
                          >
                            40x
                          </p>
                        </div>
                      </PopoverTrigger>
                      <PopoverContent
                        padding={'12px'}
                        borderWidth={'1'}
                        borderRadius={'8'}
                        borderColor={theme.isLightTheme ? '#E7E5E4' : '#44403C'}
                        backgroundColor={theme.isLightTheme ? '#FFFFFF' : '#000000'}
                        className={`!border-[1px] ${theme.isLightTheme ? '!bg-white !text-stone-900 !border-stone-200' : '!bg-black !text-stone-50 !border-stone-700'} rounded-lg p-3 z-[1000]`}
                      >
                        <div
                          className={`w-fit max-w-fit h-fit flex flex-col items-start justify-start gap-4 ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                        >
                          <p
                            className={`w-fit max-w-fit font-Louize font-normal text-[22px] tracking-tighter`}
                          >{`Protocol Rewards`}</p>
                          <div className="w-fit max-w-fit flex items-center justify-center gap-14">
                            <p
                              className={`w-fit max-w-fit font-Suisse-Intl font-semiMedium text-sm ${theme.isLightTheme ? 'text-stone-800' : 'text-stone-300'} text-wrap`}
                            >
                              <p
                                className={`w-fit max-w-full rounded-[4px] py-[2px] px-[6px] ${theme.isLightTheme ? 'bg-[#E0EAFF] text-[#565DD5]' : 'bg-[#3F4270] text-[#E0EAFF]'} text-sm font-Suisse-Intl font-medium inline-block`}
                              >
                                4x
                              </p>
                              &nbsp;Noon Points per{' '}
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/tokens/${'sUSN'}.svg`}
                                className="w-6 h-6 shrink-0 inline-block"
                              />
                              &nbsp;{`sUSN deposited in active position`}
                              (in addition to Holdings points multiplier).
                              <br />
                              <br />
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`https://mirror.xyz/nooncapital.eth/EjihOJP_wyvnL-BFj1lGvfgLj1CKa6nGFqiysFWoeGw`}
                                className="underline"
                              >
                                Read more &nbsp;
                                <ArrowRightUpIcon
                                  className={`w-6 h-6 shrink-0 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} inline`}
                                />
                              </a>
                            </p>
                          </div>
                        </div>
                      </PopoverContent>
                    </Popover>
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4`}
                >
                  <div className="w-fit max-w-fit min-w-fit h-full flex items-center justify-center gap-1">
                    <div>{formatStringToNumberNoDecimal(uniswapPoints?.toString() || '0', true)}</div>
                    <p
                      className={`font-Suisse-Intl font-medium text-sm rounded-md py-[2px] px-2 ${theme.isLightTheme ? 'text-[#027A3E] bg-[#D1FAE5]' : 'text-[#D1FAE5] bg-[#054F2A]'} leading-5`}
                    >
                      {uniswapPointsChange > 0
                        ? `+${formatStringToNumberNoDecimal(uniswapPointsChange?.toString() || '0', true)}`
                        : `${formatStringToNumberNoDecimal(uniswapPointsChange?.toString() || '0', true)}`}
                    </p>
                  </div>
                </TableHead>
                <TableHead
                  className={`h-full border-[1px] !border-l-0 !border-t-0 !border-r-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700 bg-stone-950'} p-4 text-stone-500 grid grid-cols-2 gap-2 items-center justify-center`}
                >
                  <Tooltip
                    hasArrow={true}
                    label={
                      <div className="w-full max-w-full flex flex-col gap-2 items-start justify-start">
                        <p
                          className={`w-full max-w-full font-Suisse-Intl font-medium text-sm text-nowrap text-stone-400 text-left`}
                        >
                          <USNIcon className="w-5 h-5 shrink-0 inline" />
                          &nbsp;/&nbsp;
                          <USDT className="w-5 h-5 shrink-0 inline" />
                        </p>
                        <p>
                          Liquidity pool for <br /> USN/USDT on Uniswap
                        </p>
                      </div>
                    }
                    bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                    color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                    placement="top-start"
                    borderRadius={'rounded-lg'}
                    paddingX={'px-3'}
                    paddingY={'py-[6px]'}
                    className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
                  >
                    <NavLink
                      target="_blank"
                      to="https://app.uniswap.org/explore/pools/ethereum/0x526CD4f72F2CC54d6A02A7feFC84753A826A5737"
                      className={`w-full max-w-full max-h-full rounded-[184px] py-[6px] px-3 gap-[6px] border-[1px] ${theme.isLightTheme ? 'bg-stone-100 border-stone-300 text-stone-900' : 'bg-stone-900 border-stone-800 text-stone-50'}`}
                    >
                      <p
                        className={`w-full max-w-full font-Suisse-Intl font-medium text-sm text-nowrap text-center text-stone-400`}
                      >
                        <USNIcon className="w-5 h-5 shrink-0 inline" />
                        &nbsp;/&nbsp;
                        <USDT className="w-5 h-5 shrink-0 inline" />
                        &nbsp;
                        <ArrowRightIcon
                          className={`w-5 h-5 shrink-0 inline ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
                        />
                      </p>
                    </NavLink>
                  </Tooltip>
                  <Tooltip
                    hasArrow={true}
                    label={
                      <div className="w-full max-w-full flex flex-col gap-2 items-start justify-start">
                        <p
                          className={`w-full max-w-full font-Suisse-Intl font-medium text-sm text-nowrap text-stone-400 text-left`}
                        >
                          <SUSNIcon className="w-5 h-5 shrink-0 inline" />
                          &nbsp;/&nbsp;
                          <USNIcon className="w-5 h-5 shrink-0 inline" />
                        </p>
                        <p>
                          Liquidity pool for <br /> sUSN/USN on Uniswap
                        </p>
                      </div>
                    }
                    bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                    color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                    placement="top-start"
                    borderRadius={'rounded-lg'}
                    paddingX={'px-3'}
                    paddingY={'py-[6px]'}
                    className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
                  >
                    <NavLink
                      target="_blank"
                      to="https://app.uniswap.org/explore/pools/ethereum/0x5CbA2738C4df481Db7228E1545f29532ED0a2dEe"
                      className={`w-full max-w-full h-fit max-h-full rounded-[184px] py-[6px] px-3 gap-[6px] border-[1px] ${theme.isLightTheme ? 'bg-stone-100 border-stone-300 text-stone-900' : 'bg-stone-900 border-stone-800 text-stone-50'}`}
                    >
                      <p
                        className={`w-full max-w-full font-Suisse-Intl font-medium text-sm text-nowrap text-center text-stone-400`}
                      >
                        <SUSNIcon className="w-5 h-5 shrink-0 inline" />
                        &nbsp;/&nbsp;
                        <USNIcon className="w-5 h-5 shrink-0 inline" />
                        &nbsp;
                        <ArrowRightIcon
                          className={`w-5 h-5 shrink-0 inline ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
                        />
                      </p>
                    </NavLink>
                  </Tooltip>
                </TableHead>
              </TableRow>

              <TableRow
                className={`${activityVisible ? '' : 'hidden'} border-0 ${theme.isLightTheme ? 'bg-white text-stone-900' : 'bg-stone-900 text-stone-50'} font-Suisse-Intl font-semiMedium text-base`}
              >
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 font-Suisse-Intl font-semiMedium text-base`}
                >
                  2
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="h-full w-full max-w-full flex items-center justify-start gap-2">
                    <SyncSwap className="w-6 h-6 shrink-0 inline" />
                    <p>SyncSwap</p>
                    <Tooltip
                      hasArrow={true}
                      label={
                        <div className="w-full max-w-full flex flex-col gap-1">
                          <p>{`All points earned are tracked and displayed.`}</p>
                        </div>
                      }
                      bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                      color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                      placement="top-start"
                      borderRadius={'rounded-lg'}
                      paddingX={'px-3'}
                      paddingY={'py-[6px]'}
                      className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
                    >
                      <p
                        className={`w-fit max-w-full rounded-lg py-[2px] px-2 ${theme.isLightTheme ? 'bg-[#E0EAFF] text-[#565DD5]' : 'bg-[#3F4270] text-[#E0EAFF]'} text-sm flex items-center justify-center gap-[2px] cursor-pointer`}
                      >
                        <p>•</p>
                        <p>LIVE</p>
                      </p>
                    </Tooltip>
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="min-w-full w-fit max-w-fit h-full flex items-center justify-start -space-x-2 rtl:space-x-reverse">
                    {['Zksync'].reverse().map((chain) => (
                      <img
                        key={chain}
                        className="w-6 h-6 rounded-[100px]"
                        src={`${process.env.PUBLIC_URL}/assets/chains/${chain?.toLowerCase() || 'ethereum'}.svg`}
                        alt=""
                      />
                    ))}
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="m-0 w-full h-full flex flex-col md:flex-row items-start md:items-center justify-center md:justify-start flex-wrap p-0 gap-2">
                    <Popover closeOnEsc={true} closeOnBlur={true}>
                      <PopoverTrigger>
                        <div
                          className={`w-fit rounded-[64px] p-[2px] pr-[6px] gap-1 flex items-center justify-center ${theme.isLightTheme ? 'bg-indigo-200' : 'bg-indigo-950'} cursor-pointer`}
                        >
                          <USNIcon className="w-6 h-6 rounded-[48px] p-0" />
                          <p
                            className={`font-Suisse-Intl font-medium text-sm ${theme.isLightTheme ? 'text-[#4A4BB8]' : 'text-[#E0EAFF]'}`}
                          >
                            280x
                          </p>
                        </div>
                      </PopoverTrigger>
                      <PopoverContent
                        padding={'12px'}
                        borderWidth={'1'}
                        borderRadius={'8'}
                        borderColor={theme.isLightTheme ? '#E7E5E4' : '#44403C'}
                        backgroundColor={theme.isLightTheme ? '#FFFFFF' : '#000000'}
                        className={`!border-[1px] ${theme.isLightTheme ? '!bg-white !text-stone-900 !border-stone-200' : '!bg-black !text-stone-50 !border-stone-700'} rounded-lg p-3 z-[1000]`}
                      >
                        <div
                          className={`w-fit max-w-fit h-fit flex flex-col items-start justify-start gap-4 ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                        >
                          <p
                            className={`w-fit max-w-fit font-Louize font-normal text-[22px] tracking-tighter`}
                          >{`Protocol Rewards`}</p>
                          <div className="w-fit max-w-fit flex items-center justify-center gap-14">
                            <p
                              className={`w-fit max-w-fit font-Suisse-Intl font-semiMedium text-sm ${theme.isLightTheme ? 'text-stone-800' : 'text-stone-300'} text-wrap`}
                            >
                              <p
                                className={`w-fit max-w-full rounded-[4px] py-[2px] px-[6px] ${theme.isLightTheme ? 'bg-[#E0EAFF] text-[#565DD5]' : 'bg-[#3F4270] text-[#E0EAFF]'} text-sm font-Suisse-Intl font-medium inline-block`}
                              >
                                4x
                              </p>
                              &nbsp;Noon Points per <USNIcon className="w-6 h-6 shrink-0 inline-block" />
                              &nbsp;{`USN deposited in active position `}
                              (in addition to Holdings points multiplier).
                              <br />
                              <br />
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`https://mirror.xyz/nooncapital.eth/EjihOJP_wyvnL-BFj1lGvfgLj1CKa6nGFqiysFWoeGw`}
                                className="underline"
                              >
                                Read more &nbsp;
                                <ArrowRightUpIcon
                                  className={`w-6 h-6 shrink-0 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} inline`}
                                />
                              </a>
                            </p>
                          </div>
                        </div>
                      </PopoverContent>
                    </Popover>
                    <Popover closeOnEsc={true} closeOnBlur={true}>
                      <PopoverTrigger>
                        <div
                          className={`w-fit rounded-[64px] p-[2px] pr-[6px] gap-1 flex items-center justify-center ${theme.isLightTheme ? 'bg-indigo-200' : 'bg-indigo-950'} cursor-pointer`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/tokens/${'sUSN'}.svg`}
                            className="w-6 h-6 rounded-[48px] p-0"
                          />
                          <p
                            className={`font-Suisse-Intl font-medium text-sm ${theme.isLightTheme ? 'text-[#4A4BB8]' : 'text-[#E0EAFF]'}`}
                          >
                            40x
                          </p>
                        </div>
                      </PopoverTrigger>
                      <PopoverContent
                        padding={'12px'}
                        borderWidth={'1'}
                        borderRadius={'8'}
                        borderColor={theme.isLightTheme ? '#E7E5E4' : '#44403C'}
                        backgroundColor={theme.isLightTheme ? '#FFFFFF' : '#000000'}
                        className={`!border-[1px] ${theme.isLightTheme ? '!bg-white !text-stone-900 !border-stone-200' : '!bg-black !text-stone-50 !border-stone-700'} rounded-lg p-3 z-[1000]`}
                      >
                        <div
                          className={`w-fit max-w-fit h-fit flex flex-col items-start justify-start gap-4 ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                        >
                          <p
                            className={`w-fit max-w-fit font-Louize font-normal text-[22px] tracking-tighter`}
                          >{`Protocol Rewards`}</p>
                          <div className="w-fit max-w-fit flex items-center justify-center gap-14">
                            <p
                              className={`w-fit max-w-fit font-Suisse-Intl font-semiMedium text-sm ${theme.isLightTheme ? 'text-stone-800' : 'text-stone-300'} text-wrap`}
                            >
                              <p
                                className={`w-fit max-w-full rounded-[4px] py-[2px] px-[6px] ${theme.isLightTheme ? 'bg-[#E0EAFF] text-[#565DD5]' : 'bg-[#3F4270] text-[#E0EAFF]'} text-sm font-Suisse-Intl font-medium inline-block`}
                              >
                                4x
                              </p>
                              &nbsp;Noon Points per{' '}
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/tokens/${'sUSN'}.svg`}
                                className="w-6 h-6 shrink-0 inline-block"
                              />
                              &nbsp;{`sUSN deposited in active position`}
                              (in addition to Holdings points multiplier).
                              <br />
                              <br />
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`https://mirror.xyz/nooncapital.eth/EjihOJP_wyvnL-BFj1lGvfgLj1CKa6nGFqiysFWoeGw`}
                                className="underline"
                              >
                                Read more &nbsp;
                                <ArrowRightUpIcon
                                  className={`w-6 h-6 shrink-0 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} inline`}
                                />
                              </a>
                            </p>
                          </div>
                        </div>
                      </PopoverContent>
                    </Popover>
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4`}
                >
                  <div className="w-fit max-w-fit min-w-fit h-full flex items-center justify-center gap-1">
                    <div>{formatStringToNumberNoDecimal(syncswapZkPoints?.toString() || '0', true)}</div>
                    <p
                      className={`font-Suisse-Intl font-medium text-sm rounded-md py-[2px] px-2 ${theme.isLightTheme ? 'text-[#027A3E] bg-[#D1FAE5]' : 'text-[#D1FAE5] bg-[#054F2A]'} leading-5`}
                    >
                      {syncswapZkPointsChange > 0
                        ? `+${formatStringToNumberNoDecimal(syncswapZkPointsChange?.toString() || '0', true)}`
                        : `${formatStringToNumberNoDecimal(syncswapZkPointsChange?.toString() || '0', true)}`}
                    </p>
                  </div>
                </TableHead>
                <TableHead
                  className={`h-full border-[1px] !border-l-0 !border-t-0 !border-r-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700 bg-stone-950'} p-4 text-stone-500 grid grid-cols-2 gap-2 items-start justify-center`}
                >
                  <Tooltip
                    hasArrow={true}
                    label={
                      <div className="w-full max-w-full flex flex-col gap-2 items-start justify-start">
                        <p
                          className={`w-full max-w-full font-Suisse-Intl font-medium text-sm text-nowrap text-stone-400 text-left`}
                        >
                          <USNIcon className="w-5 h-5 shrink-0 inline" />
                          &nbsp;/&nbsp;
                          <USDCe className="w-5 h-5 shrink-0 inline" />
                        </p>
                        <p>
                          Liquidity pool for <br /> USN/USDC.e on SyncSwap
                        </p>
                      </div>
                    }
                    bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                    color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                    placement="top-start"
                    borderRadius={'rounded-lg'}
                    paddingX={'px-3'}
                    paddingY={'py-[6px]'}
                    className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
                  >
                    <NavLink
                      target="_blank"
                      to="https://syncswap.xyz/pool/0xE6ed575D9627942893f12BF9c2Cc3c47cD11d002"
                      className={`w-full max-w-full h-fit max-h-full rounded-[184px] py-[6px] px-3 gap-[6px] border-[1px] ${theme.isLightTheme ? 'bg-stone-100 border-stone-300 text-stone-900' : 'bg-stone-900 border-stone-800 text-stone-50'}`}
                    >
                      <p
                        className={`w-full max-w-full font-Suisse-Intl font-medium text-sm text-nowrap text-center text-stone-400`}
                      >
                        <USNIcon className="w-5 h-5 shrink-0 inline" />
                        &nbsp;/&nbsp;
                        <USDCe className="w-5 h-5 shrink-0 inline" />
                        &nbsp;
                        <ArrowRightIcon
                          className={`w-5 h-5 shrink-0 inline ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
                        />
                      </p>
                    </NavLink>
                  </Tooltip>
                  <Tooltip
                    hasArrow={true}
                    label={
                      <div className="w-full max-w-full flex flex-col gap-2 items-start justify-start">
                        <p
                          className={`w-full max-w-full font-Suisse-Intl font-medium text-sm text-nowrap text-stone-400 text-left`}
                        >
                          <SUSNIcon className="w-5 h-5 shrink-0 inline" />
                          &nbsp;/&nbsp;
                          <USNIcon className="w-5 h-5 shrink-0 inline" />
                        </p>
                        <p>
                          Liquidity pool for <br /> sUSN/USN on SyncSwap
                        </p>
                      </div>
                    }
                    bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                    color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                    placement="top-start"
                    borderRadius={'rounded-lg'}
                    paddingX={'px-3'}
                    paddingY={'py-[6px]'}
                    className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
                  >
                    <NavLink
                      target="_blank"
                      to="https://syncswap.xyz/pool/0x12BF23c2Fe929C23Ab375199EFaD425e70c0ECE1"
                      className={`w-full max-w-full h-fit max-h-full rounded-[184px] py-[6px] px-3 flex gap-[6px] border-[1px] items-center justify-center ${theme.isLightTheme ? 'bg-stone-100 border-stone-300 text-stone-900' : 'bg-stone-900 border-stone-800 text-stone-50'} flex-wrap`}
                    >
                      <p
                        className={`w-full max-w-full font-Suisse-Intl font-medium text-sm text-nowrap text-center text-stone-400`}
                      >
                        <SUSNIcon className="w-5 h-5 shrink-0 inline" />
                        &nbsp;/&nbsp;
                        <USNIcon className="w-5 h-5 shrink-0 inline" />
                        &nbsp;
                        <ArrowRightIcon
                          className={`w-5 h-5 shrink-0 inline ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
                        />
                      </p>
                    </NavLink>
                  </Tooltip>
                </TableHead>
              </TableRow>

              <TableRow
                className={`${activityVisible ? '' : 'hidden'} border-0 ${theme.isLightTheme ? 'bg-white text-stone-900' : 'bg-stone-900 text-stone-50'} font-Suisse-Intl font-semiMedium text-base`}
              >
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 font-Suisse-Intl font-semiMedium text-base`}
                >
                  3
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="h-full w-full max-h-full max-w-full flex flex-col items-start justify-center gap-1">
                    <div className="w-fit max-w-full flex items-center justify-start gap-1">
                      <SyncSwap className="w-6 h-6 shrink-0 inline" />
                      <p>SyncSwap</p>
                      <Tooltip
                        hasArrow={true}
                        label={
                          <div className="w-full max-w-full flex flex-col gap-1">
                            <p>{`All points earned are tracked and displayed.`}</p>
                          </div>
                        }
                        bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                        color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                        placement="top-start"
                        borderRadius={'rounded-lg'}
                        paddingX={'px-3'}
                        paddingY={'py-[6px]'}
                        className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
                      >
                        <p
                          className={`w-fit max-w-full rounded-lg py-[2px] px-2 ${theme.isLightTheme ? 'bg-[#E0EAFF] text-[#565DD5]' : 'bg-[#3F4270] text-[#E0EAFF]'} text-sm flex items-center justify-center gap-[2px] cursor-pointer`}
                        >
                          <p>•</p>
                          <p>LIVE</p>
                        </p>
                      </Tooltip>
                    </div>
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="min-w-full w-fit max-w-fit h-full flex items-center justify-start -space-x-2 rtl:space-x-reverse">
                    {['Sophon'].reverse().map((chain) => (
                      <img
                        key={chain}
                        className="w-6 h-6 rounded-[100px]"
                        src={`${process.env.PUBLIC_URL}/assets/chains/${chain?.toLowerCase() || 'ethereum'}.svg`}
                        alt=""
                      />
                    ))}
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="m-0 w-full h-full flex flex-col md:flex-row items-start md:items-center justify-center md:justify-start  flex-wrap p-0 gap-2">
                    <Popover closeOnEsc={true} closeOnBlur={true}>
                      <PopoverTrigger>
                        <div
                          className={`w-fit rounded-[64px] p-[2px] pr-[6px] gap-1 flex items-center justify-center ${theme.isLightTheme ? 'bg-indigo-200' : 'bg-indigo-950'} cursor-pointer`}
                        >
                          <USNIcon className="w-6 h-6 rounded-[48px] p-0" />
                          <p
                            className={`font-Suisse-Intl font-medium text-sm ${theme.isLightTheme ? 'text-[#4A4BB8]' : 'text-[#E0EAFF]'}`}
                          >
                            280x
                          </p>
                        </div>
                      </PopoverTrigger>
                      <PopoverContent
                        padding={'12px'}
                        borderWidth={'1'}
                        borderRadius={'8'}
                        borderColor={theme.isLightTheme ? '#E7E5E4' : '#44403C'}
                        backgroundColor={theme.isLightTheme ? '#FFFFFF' : '#000000'}
                        className={`!border-[1px] ${theme.isLightTheme ? '!bg-white !text-stone-900 !border-stone-200' : '!bg-black !text-stone-50 !border-stone-700'} rounded-lg p-3 z-[1000]`}
                      >
                        <div
                          className={`w-fit max-w-fit h-fit flex flex-col items-start justify-start gap-4 ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                        >
                          <p
                            className={`w-fit max-w-fit font-Louize font-normal text-[22px] tracking-tighter`}
                          >{`Protocol Rewards`}</p>
                          <div className="w-fit max-w-fit flex items-center justify-center gap-14">
                            <p
                              className={`w-fit max-w-fit font-Suisse-Intl font-semiMedium text-sm ${theme.isLightTheme ? 'text-stone-800' : 'text-stone-300'} text-wrap`}
                            >
                              <p
                                className={`w-fit max-w-full rounded-[4px] py-[2px] px-[6px] ${theme.isLightTheme ? 'bg-[#E0EAFF] text-[#565DD5]' : 'bg-[#3F4270] text-[#E0EAFF]'} text-sm font-Suisse-Intl font-medium inline-block`}
                              >
                                4x
                              </p>
                              &nbsp;Noon Points per <USNIcon className="w-6 h-6 shrink-0 inline-block" />
                              &nbsp;{`USN deposited in active position `}
                              (in addition to Holdings points multiplier).
                              <br />
                              <br />
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`https://mirror.xyz/nooncapital.eth/EjihOJP_wyvnL-BFj1lGvfgLj1CKa6nGFqiysFWoeGw`}
                                className="underline"
                              >
                                Read more &nbsp;
                                <ArrowRightUpIcon
                                  className={`w-6 h-6 shrink-0 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} inline`}
                                />
                              </a>
                            </p>
                          </div>
                        </div>
                      </PopoverContent>
                    </Popover>
                    <Popover closeOnEsc={true} closeOnBlur={true}>
                      <PopoverTrigger>
                        <div
                          className={`w-fit rounded-[64px] p-[2px] pr-[6px] gap-1 flex items-center justify-center ${theme.isLightTheme ? 'bg-indigo-200' : 'bg-indigo-950'} cursor-pointer`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/tokens/${'sUSN'}.svg`}
                            className="w-6 h-6 rounded-[48px] p-0"
                          />
                          <p
                            className={`font-Suisse-Intl font-medium text-sm ${theme.isLightTheme ? 'text-[#4A4BB8]' : 'text-[#E0EAFF]'}`}
                          >
                            40x
                          </p>
                        </div>
                      </PopoverTrigger>
                      <PopoverContent
                        padding={'12px'}
                        borderWidth={'1'}
                        borderRadius={'8'}
                        borderColor={theme.isLightTheme ? '#E7E5E4' : '#44403C'}
                        backgroundColor={theme.isLightTheme ? '#FFFFFF' : '#000000'}
                        className={`!border-[1px] ${theme.isLightTheme ? '!bg-white !text-stone-900 !border-stone-200' : '!bg-black !text-stone-50 !border-stone-700'} rounded-lg p-3 z-[1000]`}
                      >
                        <div
                          className={`w-fit max-w-fit h-fit flex flex-col items-start justify-start gap-4 ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                        >
                          <p
                            className={`w-fit max-w-fit font-Louize font-normal text-[22px] tracking-tighter`}
                          >{`Protocol Rewards`}</p>
                          <div className="w-fit max-w-fit flex items-center justify-center gap-14">
                            <p
                              className={`w-fit max-w-fit font-Suisse-Intl font-semiMedium text-sm ${theme.isLightTheme ? 'text-stone-800' : 'text-stone-300'} text-wrap`}
                            >
                              <p
                                className={`w-fit max-w-full rounded-[4px] py-[2px] px-[6px] ${theme.isLightTheme ? 'bg-[#E0EAFF] text-[#565DD5]' : 'bg-[#3F4270] text-[#E0EAFF]'} text-sm font-Suisse-Intl font-medium inline-block`}
                              >
                                4x
                              </p>
                              &nbsp;Noon Points per{' '}
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/tokens/${'sUSN'}.svg`}
                                className="w-6 h-6 shrink-0 inline-block"
                              />
                              &nbsp;{`sUSN deposited in active position`}
                              (in addition to Holdings points multiplier).
                              <br />
                              <br />
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`https://mirror.xyz/nooncapital.eth/EjihOJP_wyvnL-BFj1lGvfgLj1CKa6nGFqiysFWoeGw`}
                                className="underline"
                              >
                                Read more &nbsp;
                                <ArrowRightUpIcon
                                  className={`w-6 h-6 shrink-0 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} inline`}
                                />
                              </a>
                            </p>
                          </div>
                        </div>
                      </PopoverContent>
                    </Popover>
                    <Popover closeOnEsc={true} closeOnBlur={true}>
                      <PopoverTrigger>
                        <div
                          className={`w-fit rounded-[64px] p-[2px] pr-[6px] gap-1 flex items-center justify-center ${theme.isLightTheme ? 'bg-indigo-200' : 'bg-indigo-950'} cursor-pointer`}
                        >
                          <SophonIcon className="w-6 h-6 rounded-[48px] p-0" />
                          <p
                            className={`font-Suisse-Intl font-medium text-sm ${theme.isLightTheme ? 'text-[#4A4BB8]' : 'text-[#E0EAFF]'}`}
                          >
                            2x
                          </p>
                        </div>
                      </PopoverTrigger>
                      <PopoverContent
                        padding={'12px'}
                        borderWidth={'1'}
                        borderRadius={'8'}
                        borderColor={theme.isLightTheme ? '#E7E5E4' : '#44403C'}
                        backgroundColor={theme.isLightTheme ? '#FFFFFF' : '#000000'}
                        className={`!border-[1px] ${theme.isLightTheme ? '!bg-white !text-stone-900 !border-stone-200' : '!bg-black !text-stone-50 !border-stone-700'} rounded-lg p-3 z-[1000]`}
                      >
                        <div
                          className={`w-fit max-w-fit h-fit flex flex-col items-start justify-start gap-4 ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                        >
                          <p
                            className={`w-fit max-w-fit font-Louize font-normal text-[22px] tracking-tighter`}
                          >{`Partner Rewards`}</p>
                          <div className="w-fit max-w-fit flex items-center justify-center gap-14">
                            <p
                              className={`w-fit max-w-fit font-Suisse-Intl font-semiMedium text-sm ${theme.isLightTheme ? 'text-stone-800' : 'text-stone-300'} text-wrap`}
                            >
                              <p
                                className={`w-fit max-w-full rounded-[4px] py-[2px] px-[6px] ${theme.isLightTheme ? 'bg-[#E0EAFF] text-[#565DD5]' : 'bg-[#3F4270] text-[#E0EAFF]'} text-sm font-Suisse-Intl font-medium inline-block`}
                              >
                                2x
                              </p>
                              &nbsp;Sophon Points per <USNIcon className="w-6 h-6 shrink-0 inline-block" />
                              &nbsp;USN / <SUSNIcon className="w-6 h-6 shrink-0 inline-block" />
                              &nbsp;sUSN deposited, claimable on Sophon Farms page. (Coming soon)
                              <br />
                              <br />
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`https://farm.sophon.xyz/`}
                                className="underline"
                              >
                                View rewards &nbsp;
                                <ArrowRightUpIcon
                                  className={`w-6 h-6 shrink-0 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} inline`}
                                />
                              </a>
                            </p>
                          </div>
                        </div>
                      </PopoverContent>
                    </Popover>
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4`}
                >
                  <div className="w-fit max-w-fit min-w-fit h-full flex items-center justify-center gap-1">
                    <div>{formatStringToNumberNoDecimal(syncswapSophonPoints?.toString() || '0', true)}</div>
                    <p
                      className={`font-Suisse-Intl font-medium text-sm rounded-md py-[2px] px-2 ${theme.isLightTheme ? 'text-[#027A3E] bg-[#D1FAE5]' : 'text-[#D1FAE5] bg-[#054F2A]'} leading-5`}
                    >
                      {syncswapSophonPointsChange > 0
                        ? `+${formatStringToNumberNoDecimal(syncswapSophonPointsChange?.toString() || '0', true)}`
                        : `${formatStringToNumberNoDecimal(syncswapSophonPointsChange?.toString() || '0', true)}`}
                    </p>
                  </div>
                </TableHead>
                <TableHead
                  className={`h-full border-[1px] !border-l-0 !border-t-0 !border-r-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700 bg-stone-950'} p-4 text-stone-500 grid grid-cols-2 gap-2 items-start justify-center`}
                >
                  <Tooltip
                    hasArrow={true}
                    label={
                      <div className="w-full max-w-full flex flex-col gap-2 items-start justify-start">
                        <p
                          className={`w-full max-w-full font-Suisse-Intl font-medium text-sm text-nowrap text-stone-400 text-left`}
                        >
                          <USNIcon className="w-5 h-5 shrink-0 inline" />
                          &nbsp;/&nbsp;
                          <USDT className="w-5 h-5 shrink-0 inline" />
                        </p>
                        <p>
                          Liquidity pool for <br /> USN/USDT on SyncSwap
                        </p>
                      </div>
                    }
                    bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                    color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                    placement="top-start"
                    borderRadius={'rounded-lg'}
                    paddingX={'px-3'}
                    paddingY={'py-[6px]'}
                    className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
                  >
                    <NavLink
                      target="_blank"
                      to="https://syncswap.xyz/pool/0x0Cdb3454293FDfa187B14025F29cdA3319fcd3B5"
                      className={`w-full max-w-full h-fit max-h-full rounded-[184px] py-[6px] px-3 flex gap-[6px] border-[1px] items-center justify-center ${theme.isLightTheme ? 'bg-stone-100 border-stone-300 text-stone-900' : 'bg-stone-900 border-stone-800 text-stone-50'} flex-wrap`}
                    >
                      <p
                        className={`w-full max-w-full font-Suisse-Intl font-medium text-sm text-nowrap text-center text-stone-400`}
                      >
                        <USNIcon className="w-5 h-5 shrink-0 inline" />
                        &nbsp;/&nbsp;
                        <USDT className="w-5 h-5 shrink-0 inline" />
                        &nbsp;
                        <ArrowRightIcon
                          className={`w-5 h-5 shrink-0 inline ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
                        />
                      </p>
                    </NavLink>
                  </Tooltip>
                  <Tooltip
                    hasArrow={true}
                    label={
                      <div className="w-full max-w-full flex flex-col gap-2 items-start justify-start">
                        <p
                          className={`w-full max-w-full font-Suisse-Intl font-medium text-sm text-nowrap text-stone-400 text-left`}
                        >
                          <SUSNIcon className="w-5 h-5 shrink-0 inline" />
                          &nbsp;/&nbsp;
                          <USNIcon className="w-5 h-5 shrink-0 inline" />
                        </p>
                        <p>
                          Liquidity pool for <br /> sUSN/USN on SyncSwap
                        </p>
                      </div>
                    }
                    bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                    color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                    placement="top-start"
                    borderRadius={'rounded-lg'}
                    paddingX={'px-3'}
                    paddingY={'py-[6px]'}
                    className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
                  >
                    <NavLink
                      target="_blank"
                      to="https://syncswap.xyz/pool/0x9116a0E6C8d04E82397B64E72D26d14D290b42eF"
                      className={`w-full max-w-full h-fit max-h-full rounded-[184px] py-[6px] px-3 flex gap-[6px] border-[1px] items-center justify-center ${theme.isLightTheme ? 'bg-stone-100 border-stone-300 text-stone-900' : 'bg-stone-900 border-stone-800 text-stone-50'} flex-wrap`}
                    >
                      <p
                        className={`w-full max-w-full font-Suisse-Intl font-medium text-sm text-nowrap text-center text-stone-400`}
                      >
                        <SUSNIcon className="w-5 h-5 shrink-0 inline" />
                        &nbsp;/&nbsp;
                        <USNIcon className="w-5 h-5 shrink-0 inline" />
                        &nbsp;
                        <ArrowRightIcon
                          className={`w-5 h-5 shrink-0 inline ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
                        />
                      </p>
                    </NavLink>
                  </Tooltip>
                </TableHead>
              </TableRow>

              <TableRow
                className={`${activityVisible ? '' : 'hidden'} border-0 ${theme.isLightTheme ? 'bg-white text-stone-900' : 'bg-stone-900 text-stone-50'} font-Suisse-Intl font-semiMedium text-base`}
              >
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 font-Suisse-Intl font-semiMedium text-base`}
                >
                  4
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="h-full w-full max-h-full max-w-full flex flex-col items-start justify-center gap-1">
                    <div className="w-fit max-w-full flex items-center justify-start gap-1">
                      <Zero className="w-6 h-6 shrink-0 inline" />
                      <p>Zerolend</p>
                      <Tooltip
                        hasArrow={true}
                        label={
                          <div className="w-full max-w-full flex flex-col gap-1">
                            <p>{`All points earned are tracked and displayed.`}</p>
                          </div>
                        }
                        bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                        color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                        placement="top-start"
                        borderRadius={'rounded-lg'}
                        paddingX={'px-3'}
                        paddingY={'py-[6px]'}
                        className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
                      >
                        <p
                          className={`w-fit max-w-full rounded-lg py-[2px] px-2 ${theme.isLightTheme ? 'bg-[#E0EAFF] text-[#565DD5]' : 'bg-[#3F4270] text-[#E0EAFF]'} text-sm flex items-center justify-center gap-[2px] cursor-pointer`}
                        >
                          <p>•</p>
                          <p>LIVE</p>
                        </p>
                      </Tooltip>
                    </div>
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="min-w-full w-fit max-w-fit h-full flex items-center justify-start -space-x-2 rtl:space-x-reverse">
                    {['Zksync'].reverse().map((chain) => (
                      <img
                        key={chain}
                        className="w-6 h-6 rounded-[100px]"
                        src={`${process.env.PUBLIC_URL}/assets/chains/${chain?.toLowerCase() || 'ethereum'}.svg`}
                        alt=""
                      />
                    ))}
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="m-0 w-full h-full flex flex-col md:flex-row items-start md:items-center justify-center md:justify-start  flex-wrap p-0 gap-2">
                    <Popover closeOnEsc={true} closeOnBlur={true}>
                      <PopoverTrigger>
                        <div
                          className={`w-fit rounded-[64px] p-[2px] pr-[6px] gap-1 flex items-center justify-center ${theme.isLightTheme ? 'bg-indigo-200' : 'bg-indigo-950'} cursor-pointer`}
                        >
                          <USNIcon className="w-6 h-6 rounded-[48px] p-0" />
                          <p
                            className={`font-Suisse-Intl font-medium text-sm ${theme.isLightTheme ? 'text-[#4A4BB8]' : 'text-[#E0EAFF]'}`}
                          >
                            210x
                          </p>
                        </div>
                      </PopoverTrigger>
                      <PopoverContent
                        padding={'12px'}
                        borderWidth={'1'}
                        borderRadius={'8'}
                        borderColor={theme.isLightTheme ? '#E7E5E4' : '#44403C'}
                        backgroundColor={theme.isLightTheme ? '#FFFFFF' : '#000000'}
                        className={`!border-[1px] ${theme.isLightTheme ? '!bg-white !text-stone-900 !border-stone-200' : '!bg-black !text-stone-50 !border-stone-700'} rounded-lg p-3 z-[1000]`}
                      >
                        <div
                          className={`w-fit max-w-fit h-fit flex flex-col items-start justify-start gap-4 ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                        >
                          <p
                            className={`w-fit max-w-fit font-Louize font-normal text-[22px] tracking-tighter`}
                          >{`Protocol Rewards`}</p>
                          <div className="w-fit max-w-fit flex items-center justify-center gap-14">
                            <p
                              className={`w-fit max-w-fit font-Suisse-Intl font-semiMedium text-sm ${theme.isLightTheme ? 'text-stone-800' : 'text-stone-300'} text-wrap`}
                            >
                              <p
                                className={`w-fit max-w-full rounded-[4px] py-[2px] px-[6px] ${theme.isLightTheme ? 'bg-[#E0EAFF] text-[#565DD5]' : 'bg-[#3F4270] text-[#E0EAFF]'} text-sm font-Suisse-Intl font-medium inline-block`}
                              >
                                3x
                              </p>
                              &nbsp;Noon Points per <USNIcon className="w-6 h-6 shrink-0 inline-block" />
                              &nbsp;{`deposited in active position (in addition to Holdings points multiplier).`}
                              <br />
                              {`[Strategic Partner, increased rewards vs  other lending protocols]`}
                              <br />
                              <br />
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`https://mirror.xyz/nooncapital.eth/EjihOJP_wyvnL-BFj1lGvfgLj1CKa6nGFqiysFWoeGw`}
                                className="underline"
                              >
                                Read more &nbsp;
                                <ArrowRightUpIcon
                                  className={`w-6 h-6 shrink-0 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} inline`}
                                />
                              </a>
                            </p>
                          </div>
                        </div>
                      </PopoverContent>
                    </Popover>
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4`}
                >
                  <div className="w-fit max-w-fit min-w-fit h-full flex items-center justify-center gap-1">
                    <div>{formatStringToNumberNoDecimal(zerolendZkPoints?.toString() || '0', true)}</div>
                    <p
                      className={`font-Suisse-Intl font-medium text-sm rounded-md py-[2px] px-2 ${theme.isLightTheme ? 'text-[#027A3E] bg-[#D1FAE5]' : 'text-[#D1FAE5] bg-[#054F2A]'} leading-5`}
                    >
                      {zerolendZkPointsChange > 0
                        ? `+${formatStringToNumberNoDecimal(zerolendZkPointsChange?.toString() || '0', true)}`
                        : `${formatStringToNumberNoDecimal(zerolendZkPointsChange?.toString() || '0', true)}`}
                    </p>
                  </div>
                </TableHead>
                <TableHead
                  className={`h-full border-[1px] !border-l-0 !border-t-0 !border-r-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700 bg-stone-950'} p-4 text-stone-500 flex items-start justify-center`}
                >
                  <NavLink
                    target="_blank"
                    to="https://app.zerolend.xyz/reserve-overview/?underlyingAsset=0x0469d9d1de0ee58fa1153ef00836b9bbcb84c0b6&marketName=proto_zksync_era_v3"
                    className={`min-w-full w-fit h-fit max-w-fit max-h-full rounded-[184px] py-[6px] px-3 flex gap-[6px] border-[1px] items-center justify-center ${theme.isLightTheme ? 'bg-stone-100 border-stone-300 text-stone-900' : 'bg-stone-900 border-stone-800 text-stone-50'} flex-wrap`}
                  >
                    <p
                      className={`min-w-full w-fit max-w-full font-Suisse-Intl font-medium text-sm text-nowrap text-center`}
                    >
                      Supply <USNIcon className="w-5 h-5 shrink-0 inline" />
                      &nbsp;USN and Borrow
                      <ArrowRightIcon
                        className={`w-5 h-5 shrink-0 inline ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
                      />
                    </p>
                  </NavLink>
                </TableHead>
              </TableRow>

              <TableRow
                className={`${activityVisible ? '' : 'hidden'} border-0 ${theme.isLightTheme ? 'bg-white text-stone-900' : 'bg-stone-900 text-stone-50'} font-Suisse-Intl font-semiMedium text-base`}
              >
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 font-Suisse-Intl font-semiMedium text-base`}
                >
                  5
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="h-full w-full max-w-full flex items-center justify-start gap-2">
                    <Tooltip
                      hasArrow={true}
                      label={
                        <div className="w-full max-w-full flex flex-col gap-1">
                          <p>{`Swap Pool LPs earn fees from this pool, in addition to Noon & RFX rewards.`}</p>
                        </div>
                      }
                      bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                      color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                      placement="top-start"
                      borderRadius={'rounded-lg'}
                      paddingX={'px-3'}
                      paddingY={'py-[6px]'}
                      className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
                    >
                      <p className="cursor-pointer p-0 m-0">
                        <RFXIcon className="w-6 h-6 shrink-0 inline-block" />
                        &nbsp;Swap Pool
                      </p>
                    </Tooltip>
                    <Tooltip
                      hasArrow={true}
                      label={
                        <div className="w-full max-w-full flex flex-col gap-1">
                          <p>{`All points earned are tracked and displayed.`}</p>
                        </div>
                      }
                      bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                      color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                      placement="top-start"
                      borderRadius={'rounded-lg'}
                      paddingX={'px-3'}
                      paddingY={'py-[6px]'}
                      className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
                    >
                      <p
                        className={`w-fit max-w-full rounded-lg py-[2px] px-2 ${theme.isLightTheme ? 'bg-[#E0EAFF] text-[#565DD5]' : 'bg-[#3F4270] text-[#E0EAFF]'} text-sm flex items-center justify-center gap-[2px] cursor-pointer`}
                      >
                        <p>•</p>
                        <p>LIVE</p>
                      </p>
                    </Tooltip>
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="min-w-full w-fit max-w-fit h-full flex items-center justify-start -space-x-2 rtl:space-x-reverse">
                    {['Zksync'].reverse().map((chain) => (
                      <img
                        key={chain}
                        className="w-6 h-6 rounded-[100px]"
                        src={`${process.env.PUBLIC_URL}/assets/chains/${chain?.toLowerCase() || 'ethereum'}.svg`}
                        alt=""
                      />
                    ))}
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="m-0 w-full h-full flex flex-col md:flex-row items-start md:items-center justify-center md:justify-start flex-wrap p-0 gap-2">
                    <Popover closeOnEsc={true} closeOnBlur={true}>
                      <PopoverTrigger>
                        <div
                          className={`w-fit rounded-[64px] p-[2px] pr-[6px] gap-1 flex items-center justify-center ${theme.isLightTheme ? 'bg-indigo-200' : 'bg-indigo-950'} cursor-pointer`}
                        >
                          <USNIcon className="w-6 h-6 rounded-[48px] p-0" />
                          <p
                            className={`font-Suisse-Intl font-medium text-sm ${theme.isLightTheme ? 'text-[#4A4BB8]' : 'text-[#E0EAFF]'}`}
                          >
                            280x
                          </p>
                        </div>
                      </PopoverTrigger>
                      <PopoverContent
                        padding={'12px'}
                        borderWidth={'1'}
                        borderRadius={'8'}
                        borderColor={theme.isLightTheme ? '#E7E5E4' : '#44403C'}
                        backgroundColor={theme.isLightTheme ? '#FFFFFF' : '#000000'}
                        className={`!border-[1px] ${theme.isLightTheme ? '!bg-white !text-stone-900 !border-stone-200' : '!bg-black !text-stone-50 !border-stone-700'} rounded-lg p-3 z-[1000]`}
                      >
                        <div
                          className={`w-fit max-w-fit h-fit flex flex-col items-start justify-start gap-4 ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                        >
                          <p
                            className={`w-fit max-w-fit font-Louize font-normal text-[22px] tracking-tighter`}
                          >{`Protocol Rewards`}</p>
                          <div className="w-fit max-w-fit flex items-center justify-center gap-14">
                            <p
                              className={`w-fit max-w-fit font-Suisse-Intl font-semiMedium text-sm ${theme.isLightTheme ? 'text-stone-800' : 'text-stone-300'} text-wrap`}
                            >
                              <p
                                className={`w-fit max-w-full rounded-[4px] py-[2px] px-[6px] ${theme.isLightTheme ? 'bg-[#E0EAFF] text-[#565DD5]' : 'bg-[#3F4270] text-[#E0EAFF]'} text-sm font-Suisse-Intl font-medium inline-block`}
                              >
                                4x
                              </p>
                              &nbsp;Noon Points per <USNIcon className="w-6 h-6 shrink-0 inline-block" />
                              &nbsp;{`USN deposited in active position `}
                              (in addition to Holdings points multiplier).
                              <br />
                              <br />
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`https://mirror.xyz/nooncapital.eth/EjihOJP_wyvnL-BFj1lGvfgLj1CKa6nGFqiysFWoeGw`}
                                className="underline"
                              >
                                Read more &nbsp;
                                <ArrowRightUpIcon
                                  className={`w-6 h-6 shrink-0 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} inline`}
                                />
                              </a>
                            </p>
                          </div>
                        </div>
                      </PopoverContent>
                    </Popover>
                    <Popover closeOnEsc={true} closeOnBlur={true}>
                      <PopoverTrigger>
                        <div
                          className={`w-fit rounded-[64px] p-[2px] pr-[6px] gap-1 flex items-center justify-center ${theme.isLightTheme ? 'bg-indigo-200' : 'bg-indigo-950'} cursor-pointer`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/tokens/${'RFX'}.svg`}
                            className="w-6 h-6 rounded-[48px] p-0"
                          />
                          <p
                            className={`font-Suisse-Intl font-medium text-sm ${theme.isLightTheme ? 'text-[#4A4BB8]' : 'text-[#E0EAFF]'}`}
                          >
                            4x
                          </p>
                        </div>
                      </PopoverTrigger>
                      <PopoverContent
                        padding={'12px'}
                        borderWidth={'1'}
                        borderRadius={'8'}
                        borderColor={theme.isLightTheme ? '#E7E5E4' : '#44403C'}
                        backgroundColor={theme.isLightTheme ? '#FFFFFF' : '#000000'}
                        className={`!border-[1px] ${theme.isLightTheme ? '!bg-white !text-stone-900 !border-stone-200' : '!bg-black !text-stone-50 !border-stone-700'} rounded-lg p-3 z-[1000]`}
                      >
                        <div
                          className={`w-fit max-w-fit h-fit flex flex-col items-start justify-start gap-4 ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                        >
                          <p
                            className={`w-fit max-w-fit font-Louize font-normal text-[22px] tracking-tighter`}
                          >{`Partner Rewards`}</p>
                          <div className="w-fit max-w-fit flex items-center justify-center gap-14">
                            <p
                              className={`w-fit max-w-fit font-Suisse-Intl font-semiMedium text-sm ${theme.isLightTheme ? 'text-stone-800' : 'text-stone-300'} text-wrap`}
                            >
                              <p
                                className={`w-fit max-w-full rounded-[4px] py-[2px] px-[6px] ${theme.isLightTheme ? 'bg-[#E0EAFF] text-[#565DD5]' : 'bg-[#3F4270] text-[#E0EAFF]'} text-sm font-Suisse-Intl font-medium inline-block`}
                              >
                                4x
                              </p>
                              &nbsp;RFX Points per{' '}
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/tokens/${'USN'}.svg`}
                                className="w-6 h-6 shrink-0 inline-block"
                              />
                              &nbsp;{`USN deposited in active position.`}
                              <br />
                              <br />
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`https://mirror.xyz/nooncapital.eth/EjihOJP_wyvnL-BFj1lGvfgLj1CKa6nGFqiysFWoeGw`}
                                className="underline"
                              >
                                Read more &nbsp;
                                <ArrowRightUpIcon
                                  className={`w-6 h-6 shrink-0 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} inline`}
                                />
                              </a>
                            </p>
                          </div>
                        </div>
                      </PopoverContent>
                    </Popover>
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4`}
                >
                  <div className="w-fit max-w-fit min-w-fit h-full flex items-center justify-center gap-1">
                    <div>{formatStringToNumberNoDecimal(rfxPoolZkPoints?.toString() || '0', true)}</div>
                    <p
                      className={`font-Suisse-Intl font-medium text-sm rounded-md py-[2px] px-2 ${theme.isLightTheme ? 'text-[#027A3E] bg-[#D1FAE5]' : 'text-[#D1FAE5] bg-[#054F2A]'} leading-5`}
                    >
                      {rfxPoolZkPointsChange > 0
                        ? `+${formatStringToNumberNoDecimal(rfxPoolZkPointsChange?.toString() || '0', true)}`
                        : `${formatStringToNumberNoDecimal(rfxPoolZkPointsChange?.toString() || '0', true)}`}
                    </p>
                  </div>
                </TableHead>
                <TableHead
                  className={`h-full border-[1px] !border-l-0 !border-t-0 !border-r-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700 bg-stone-950'} p-4 text-stone-500 flex gap-2 items-start justify-center`}
                >
                  <Tooltip
                    hasArrow={true}
                    label={
                      <div className="w-full max-w-full flex flex-col gap-2 items-start justify-start">
                        <p
                          className={`w-full max-w-full font-Suisse-Intl font-medium text-sm text-nowrap text-stone-400 text-left`}
                        >
                          <USNIcon className="w-5 h-5 shrink-0 inline" />
                          &nbsp;/&nbsp;
                          <USDCe className="w-5 h-5 shrink-0 inline" />
                        </p>
                        <p>
                          Liquidity pool for <br /> USN/USDC.e on RFX
                        </p>
                      </div>
                    }
                    bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                    color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                    placement="top-start"
                    borderRadius={'rounded-lg'}
                    paddingX={'px-3'}
                    paddingY={'py-[6px]'}
                    className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} w-full min-w-full max-w-full rounded-lg py-[6px] px-3 h-fit z-[1000]`}
                  >
                    <NavLink
                      target="_blank"
                      to="https://app.rfx.exchange/earn?market=0x567779Fd248a6f5596748510200C00655b3a0e01"
                      className={`min-w-full w-fit h-fit max-w-fit max-h-full rounded-[184px] py-[6px] px-3 gap-[6px] border-[1px] ${theme.isLightTheme ? 'bg-stone-100 border-stone-300 text-stone-900' : 'bg-stone-900 border-stone-800 text-stone-50'}`}
                    >
                      <p
                        className={`min-w-full w-fit max-w-full font-Suisse-Intl font-medium text-sm text-nowrap text-center text-stone-400`}
                      >
                        <USNIcon className="w-5 h-5 shrink-0 inline" />
                        &nbsp;/&nbsp;
                        <USDCe className="w-5 h-5 shrink-0 inline" />
                        &nbsp;
                        <ArrowRightIcon
                          className={`w-5 h-5 shrink-0 inline ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
                        />
                      </p>
                    </NavLink>
                  </Tooltip>
                </TableHead>
              </TableRow>

              <TableRow
                className={`${activityVisible ? '' : 'hidden'} border-0 ${theme.isLightTheme ? 'bg-white text-stone-900' : 'bg-stone-900 text-stone-50'} font-Suisse-Intl font-semiMedium text-base`}
              >
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 font-Suisse-Intl font-semiMedium text-base`}
                >
                  6
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="h-full w-full max-w-full flex items-center justify-start gap-2">
                    <Tooltip
                      hasArrow={true}
                      label={
                        <div className="w-full max-w-full flex flex-col gap-1">
                          <p>{`Shared Liquidity Vaults earn fees from top yielding RFX pools.`}</p>
                        </div>
                      }
                      bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                      color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                      placement="top-start"
                      borderRadius={'rounded-lg'}
                      paddingX={'px-3'}
                      paddingY={'py-[6px]'}
                      className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
                    >
                      <p className="cursor-pointer p-0 m-0">
                        <RFXIcon className="w-6 h-6 shrink-0 inline-block" />
                        &nbsp;Vault
                      </p>
                    </Tooltip>
                    <Tooltip
                      hasArrow={true}
                      label={
                        <div className="w-full max-w-full flex flex-col gap-1">
                          <p>{`All points earned are tracked and displayed.`}</p>
                        </div>
                      }
                      bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                      color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                      placement="top-start"
                      borderRadius={'rounded-lg'}
                      paddingX={'px-3'}
                      paddingY={'py-[6px]'}
                      className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
                    >
                      <p
                        className={`w-fit max-w-full rounded-lg py-[2px] px-2 ${theme.isLightTheme ? 'bg-[#E0EAFF] text-[#565DD5]' : 'bg-[#3F4270] text-[#E0EAFF]'} text-sm flex items-center justify-center gap-[2px] cursor-pointer`}
                      >
                        <p>•</p>
                        <p>LIVE</p>
                      </p>
                    </Tooltip>
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="min-w-full w-fit max-w-fit h-full flex items-center justify-start -space-x-2 rtl:space-x-reverse">
                    {['Zksync'].reverse().map((chain) => (
                      <img
                        key={chain}
                        className="w-6 h-6 rounded-[100px]"
                        src={`${process.env.PUBLIC_URL}/assets/chains/${chain?.toLowerCase() || 'ethereum'}.svg`}
                        alt=""
                      />
                    ))}
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="m-0 w-full h-full flex flex-col md:flex-row items-start md:items-center justify-center md:justify-start flex-wrap p-0 gap-2">
                    <Popover closeOnEsc={true} closeOnBlur={true}>
                      <PopoverTrigger>
                        <div
                          className={`w-fit rounded-[64px] p-[2px] pr-[6px] gap-1 flex items-center justify-center ${theme.isLightTheme ? 'bg-indigo-200' : 'bg-indigo-950'} cursor-pointer`}
                        >
                          <USNIcon className="w-6 h-6 rounded-[48px] p-0" />
                          <p
                            className={`font-Suisse-Intl font-medium text-sm ${theme.isLightTheme ? 'text-[#4A4BB8]' : 'text-[#E0EAFF]'}`}
                          >
                            210x
                          </p>
                        </div>
                      </PopoverTrigger>
                      <PopoverContent
                        padding={'12px'}
                        borderWidth={'1'}
                        borderRadius={'8'}
                        borderColor={theme.isLightTheme ? '#E7E5E4' : '#44403C'}
                        backgroundColor={theme.isLightTheme ? '#FFFFFF' : '#000000'}
                        className={`!border-[1px] ${theme.isLightTheme ? '!bg-white !text-stone-900 !border-stone-200' : '!bg-black !text-stone-50 !border-stone-700'} rounded-lg p-3 z-[1000]`}
                      >
                        <div
                          className={`w-fit max-w-fit h-fit flex flex-col items-start justify-start gap-4 ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                        >
                          <p
                            className={`w-fit max-w-fit font-Louize font-normal text-[22px] tracking-tighter`}
                          >{`Protocol Rewards`}</p>
                          <div className="w-fit max-w-fit flex items-center justify-center gap-14">
                            <p
                              className={`w-fit max-w-fit font-Suisse-Intl font-semiMedium text-sm ${theme.isLightTheme ? 'text-stone-800' : 'text-stone-300'} text-wrap`}
                            >
                              <p
                                className={`w-fit max-w-full rounded-[4px] py-[2px] px-[6px] ${theme.isLightTheme ? 'bg-[#E0EAFF] text-[#565DD5]' : 'bg-[#3F4270] text-[#E0EAFF]'} text-sm font-Suisse-Intl font-medium inline-block`}
                              >
                                3x
                              </p>
                              &nbsp;Noon Points per <USNIcon className="w-6 h-6 shrink-0 inline-block" />
                              &nbsp;{`USN deposited into shared liquidity vault (USN) `}
                              (in addition to Holdings points multiplier).
                              <br />
                              <br />
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`https://mirror.xyz/nooncapital.eth/EjihOJP_wyvnL-BFj1lGvfgLj1CKa6nGFqiysFWoeGw`}
                                className="underline"
                              >
                                Read more &nbsp;
                                <ArrowRightUpIcon
                                  className={`w-6 h-6 shrink-0 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} inline`}
                                />
                              </a>
                            </p>
                          </div>
                        </div>
                      </PopoverContent>
                    </Popover>
                    <Popover closeOnEsc={true} closeOnBlur={true}>
                      <PopoverTrigger>
                        <div
                          className={`w-fit rounded-[64px] p-[2px] pr-[6px] gap-1 flex items-center justify-center ${theme.isLightTheme ? 'bg-indigo-200' : 'bg-indigo-950'} cursor-pointer`}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/tokens/${'RFX'}.svg`}
                            className="w-6 h-6 rounded-[48px] p-0"
                          />
                          <p
                            className={`font-Suisse-Intl font-medium text-sm ${theme.isLightTheme ? 'text-[#4A4BB8]' : 'text-[#E0EAFF]'}`}
                          >
                            3x
                          </p>
                        </div>
                      </PopoverTrigger>
                      <PopoverContent
                        padding={'12px'}
                        borderWidth={'1'}
                        borderRadius={'8'}
                        borderColor={theme.isLightTheme ? '#E7E5E4' : '#44403C'}
                        backgroundColor={theme.isLightTheme ? '#FFFFFF' : '#000000'}
                        className={`!border-[1px] ${theme.isLightTheme ? '!bg-white !text-stone-900 !border-stone-200' : '!bg-black !text-stone-50 !border-stone-700'} rounded-lg p-3 z-[1000]`}
                      >
                        <div
                          className={`w-fit max-w-fit h-fit flex flex-col items-start justify-start gap-4 ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                        >
                          <p
                            className={`w-fit max-w-fit font-Louize font-normal text-[22px] tracking-tighter`}
                          >{`Partner Rewards`}</p>
                          <div className="w-fit max-w-fit flex items-center justify-center gap-14">
                            <p
                              className={`w-fit max-w-fit font-Suisse-Intl font-semiMedium text-sm ${theme.isLightTheme ? 'text-stone-800' : 'text-stone-300'} text-wrap`}
                            >
                              <p
                                className={`w-fit max-w-full rounded-[4px] py-[2px] px-[6px] ${theme.isLightTheme ? 'bg-[#E0EAFF] text-[#565DD5]' : 'bg-[#3F4270] text-[#E0EAFF]'} text-sm font-Suisse-Intl font-medium inline-block`}
                              >
                                3x
                              </p>
                              &nbsp;RFX Points per{' '}
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/tokens/${'USN'}.svg`}
                                className="w-6 h-6 shrink-0 inline-block"
                              />
                              &nbsp;{`USN deposited into shared liquidity vault (USN).`}
                              <br />
                              <br />
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`https://mirror.xyz/nooncapital.eth/EjihOJP_wyvnL-BFj1lGvfgLj1CKa6nGFqiysFWoeGw`}
                                className="underline"
                              >
                                Read more &nbsp;
                                <ArrowRightUpIcon
                                  className={`w-6 h-6 shrink-0 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} inline`}
                                />
                              </a>
                            </p>
                          </div>
                        </div>
                      </PopoverContent>
                    </Popover>
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4`}
                >
                  <div className="w-fit max-w-fit min-w-fit h-full flex items-center justify-center gap-1">
                    <div>{formatStringToNumberNoDecimal(rfxVaultZkPoints?.toString() || '0', true)}</div>
                    <p
                      className={`font-Suisse-Intl font-medium text-sm rounded-md py-[2px] px-2 ${theme.isLightTheme ? 'text-[#027A3E] bg-[#D1FAE5]' : 'text-[#D1FAE5] bg-[#054F2A]'} leading-5`}
                    >
                      {rfxVaultZkPointsChange > 0
                        ? `+${formatStringToNumberNoDecimal(rfxVaultZkPointsChange?.toString() || '0', true)}`
                        : `${formatStringToNumberNoDecimal(rfxVaultZkPointsChange?.toString() || '0', true)}`}
                    </p>
                  </div>
                </TableHead>
                <TableHead
                  className={`h-full border-[1px] !border-l-0 !border-t-0 !border-r-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700 bg-stone-950'} p-4 text-stone-500 flex gap-2 items-start justify-center`}
                >
                  <NavLink
                    target="_blank"
                    to="https://app.rfx.exchange/vault"
                    className={`min-w-full w-fit h-fit max-w-fit max-h-full rounded-[184px] py-[6px] px-3 gap-[6px] border-[1px] ${theme.isLightTheme ? 'bg-stone-100 border-stone-300 text-stone-900' : 'bg-stone-900 border-stone-800 text-stone-50'}`}
                  >
                    <p
                      className={`min-w-full w-fit max-w-full font-Suisse-Intl font-medium text-sm text-nowrap text-center ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                    >
                      Deposit into Shared Liquidity Vault &nbsp;
                      <ArrowRightIcon
                        className={`w-5 h-5 shrink-0 inline ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
                      />
                    </p>
                  </NavLink>
                </TableHead>
              </TableRow>

              <TableRow
                className={`${activityVisible ? '' : 'hidden'} border-0 ${theme.isLightTheme ? 'bg-white text-stone-900' : 'bg-stone-900 text-stone-50'} font-Suisse-Intl font-semiMedium text-base`}
              >
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 font-Suisse-Intl font-semiMedium text-base`}
                >
                  7
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="h-full w-full max-w-full flex items-center justify-start gap-2">
                    <p className="p-0 m-0">
                      <SpectraIcon className="w-6 h-6 shrink-0 inline-block" />
                      &nbsp;Spectra Pool (LP-sUSN)
                    </p>
                    <Tooltip
                      hasArrow={true}
                      label={
                        <div className="w-full max-w-full flex flex-col gap-1">
                          <p>{`All points earned are tracked and displayed.`}</p>
                        </div>
                      }
                      bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                      color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                      placement="top-start"
                      borderRadius={'rounded-lg'}
                      paddingX={'px-3'}
                      paddingY={'py-[6px]'}
                      className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
                    >
                      <p
                        className={`w-fit max-w-full rounded-lg py-[2px] px-2 ${theme.isLightTheme ? 'bg-[#E0EAFF] text-[#565DD5]' : 'bg-[#3F4270] text-[#E0EAFF]'} text-sm flex items-center justify-center gap-[2px] cursor-pointer`}
                      >
                        <p>•</p>
                        <p>LIVE</p>
                      </p>
                    </Tooltip>
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="min-w-full w-fit max-w-fit h-full flex items-center justify-start -space-x-2 rtl:space-x-reverse">
                    {['Ethereum'].reverse().map((chain) => (
                      <img
                        key={chain}
                        className="w-6 h-6 rounded-[100px]"
                        src={`${process.env.PUBLIC_URL}/assets/chains/${chain?.toLowerCase() || 'ethereum'}.svg`}
                        alt=""
                      />
                    ))}
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="m-0 w-full h-full flex flex-col md:flex-row items-start md:items-center justify-center md:justify-start flex-wrap p-0 gap-2">
                    <Popover closeOnEsc={true} closeOnBlur={true}>
                      <PopoverTrigger>
                        <div
                          className={`w-fit rounded-[64px] p-[2px] pr-[6px] gap-1 flex items-center justify-center ${theme.isLightTheme ? 'bg-indigo-200' : 'bg-indigo-950'} cursor-pointer`}
                        >
                          <SUSNIcon className="w-6 h-6 rounded-[48px] p-0" />
                          <p
                            className={`font-Suisse-Intl font-medium text-sm ${theme.isLightTheme ? 'text-[#4A4BB8]' : 'text-[#E0EAFF]'}`}
                          >
                            40x
                          </p>
                        </div>
                      </PopoverTrigger>
                      <PopoverContent
                        padding={'12px'}
                        borderWidth={'1'}
                        borderRadius={'8'}
                        borderColor={theme.isLightTheme ? '#E7E5E4' : '#44403C'}
                        backgroundColor={theme.isLightTheme ? '#FFFFFF' : '#000000'}
                        className={`!border-[1px] ${theme.isLightTheme ? '!bg-white !text-stone-900 !border-stone-200' : '!bg-black !text-stone-50 !border-stone-700'} rounded-lg p-3 z-[1000]`}
                      >
                        <div
                          className={`w-fit max-w-fit h-fit flex flex-col items-start justify-start gap-4 ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                        >
                          <p
                            className={`w-fit max-w-fit font-Louize font-normal text-[22px] tracking-tighter`}
                          >{`Protocol Rewards`}</p>
                          <div className="w-fit max-w-fit flex items-center justify-center gap-14">
                            <p
                              className={`w-fit max-w-fit font-Suisse-Intl font-semiMedium text-sm ${theme.isLightTheme ? 'text-stone-800' : 'text-stone-300'} text-wrap`}
                            >
                              <p
                                className={`w-fit max-w-full rounded-[4px] py-[2px] px-[6px] ${theme.isLightTheme ? 'bg-[#E0EAFF] text-[#565DD5]' : 'bg-[#3F4270] text-[#E0EAFF]'} text-sm font-Suisse-Intl font-medium inline-block`}
                              >
                                4x
                              </p>
                              &nbsp;Noon Points per <SUSNIcon className="w-6 h-6 shrink-0 inline-block" />
                              &nbsp;
                              {`sUSN deposited into LP position on Spectra (in addition to Holdings points multiplier).`}
                              <br />
                              <br />
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`https://mirror.xyz/nooncapital.eth/EjihOJP_wyvnL-BFj1lGvfgLj1CKa6nGFqiysFWoeGw`}
                                className="underline"
                              >
                                Read more &nbsp;
                                <ArrowRightUpIcon
                                  className={`w-6 h-6 shrink-0 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} inline`}
                                />
                              </a>
                            </p>
                          </div>
                        </div>
                      </PopoverContent>
                    </Popover>
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4`}
                >
                  <div className="w-fit max-w-fit min-w-fit h-full flex items-center justify-center gap-1">
                    <div>{formatStringToNumberNoDecimal(spectraLpPoints?.toString() || '0', true)}</div>
                    <p
                      className={`font-Suisse-Intl font-medium text-sm rounded-md py-[2px] px-2 ${theme.isLightTheme ? 'text-[#027A3E] bg-[#D1FAE5]' : 'text-[#D1FAE5] bg-[#054F2A]'} leading-5`}
                    >
                      {spectraLpPointsChange > 0
                        ? `+${formatStringToNumberNoDecimal(spectraLpPointsChange?.toString() || '0', true)}`
                        : `${formatStringToNumberNoDecimal(spectraLpPointsChange?.toString() || '0', true)}`}
                    </p>
                  </div>
                </TableHead>
                <TableHead
                  className={`h-full border-[1px] !border-l-0 !border-t-0 !border-r-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700 bg-stone-950'} p-4 text-stone-500 flex gap-2 items-start justify-center`}
                >
                  <NavLink
                    target="_blank"
                    to="https://app.spectra.finance/pools/eth:0x76717d6e40aae85dac1ef9edb77ceb340e32cedf"
                    className={`min-w-full w-fit h-fit max-w-fit max-h-full rounded-[184px] py-[6px] px-3 gap-[6px] border-[1px] ${theme.isLightTheme ? 'bg-stone-100 border-stone-300 text-stone-900' : 'bg-stone-900 border-stone-800 text-stone-50'}`}
                  >
                    <p
                      className={`min-w-full w-fit max-w-full font-Suisse-Intl font-medium text-sm text-nowrap text-center ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                    >
                      Deposit <SUSNIcon className="w-6 h-6 shrink-0 inline-block" />
                      &nbsp;sUSN into LP pool&nbsp;
                      <ArrowRightIcon
                        className={`w-5 h-5 shrink-0 inline ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
                      />
                    </p>
                  </NavLink>
                </TableHead>
              </TableRow>

              <TableRow
                className={`${activityVisible ? '' : 'hidden'} border-0 ${theme.isLightTheme ? 'bg-white text-stone-900' : 'bg-stone-900 text-stone-50'} font-Suisse-Intl font-semiMedium text-base`}
              >
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 font-Suisse-Intl font-semiMedium text-base`}
                >
                  8
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="h-full w-full max-w-full flex items-center justify-start gap-2">
                    <p className="p-0 m-0">
                      <SpectraIcon className="w-6 h-6 shrink-0 inline-block" />
                      &nbsp;Spectra Pool (YT-sUSN)
                    </p>
                    <Tooltip
                      hasArrow={true}
                      label={
                        <div className="w-full max-w-full flex flex-col gap-1">
                          <p>{`All points earned are tracked and displayed.`}</p>
                        </div>
                      }
                      bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                      color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                      placement="top-start"
                      borderRadius={'rounded-lg'}
                      paddingX={'px-3'}
                      paddingY={'py-[6px]'}
                      className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
                    >
                      <p
                        className={`w-fit max-w-full rounded-lg py-[2px] px-2 ${theme.isLightTheme ? 'bg-[#E0EAFF] text-[#565DD5]' : 'bg-[#3F4270] text-[#E0EAFF]'} text-sm flex items-center justify-center gap-[2px] cursor-pointer`}
                      >
                        <p>•</p>
                        <p>LIVE</p>
                      </p>
                    </Tooltip>
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="min-w-full w-fit max-w-fit h-full flex items-center justify-start -space-x-2 rtl:space-x-reverse">
                    {['Ethereum'].reverse().map((chain) => (
                      <img
                        key={chain}
                        className="w-6 h-6 rounded-[100px]"
                        src={`${process.env.PUBLIC_URL}/assets/chains/${chain?.toLowerCase() || 'ethereum'}.svg`}
                        alt=""
                      />
                    ))}
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] border-t-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 h-full`}
                >
                  <div className="m-0 w-full h-full flex flex-col md:flex-row items-start md:items-center justify-center md:justify-start flex-wrap p-0 gap-2">
                    <Popover closeOnEsc={true} closeOnBlur={true}>
                      <PopoverTrigger>
                        <div
                          className={`w-fit rounded-[64px] p-[2px] pr-[6px] gap-1 flex items-center justify-center ${theme.isLightTheme ? 'bg-indigo-200' : 'bg-indigo-950'} cursor-pointer`}
                        >
                          <YTsUSNIcon className="w-6 h-6 shrink-0 inline-block" />
                          <p
                            className={`font-Suisse-Intl font-medium text-sm ${theme.isLightTheme ? 'text-[#4A4BB8]' : 'text-[#E0EAFF]'}`}
                          >
                            20x
                          </p>
                        </div>
                      </PopoverTrigger>
                      <PopoverContent
                        padding={'12px'}
                        borderWidth={'1'}
                        borderRadius={'8'}
                        borderColor={theme.isLightTheme ? '#E7E5E4' : '#44403C'}
                        backgroundColor={theme.isLightTheme ? '#FFFFFF' : '#000000'}
                        className={`!border-[1px] ${theme.isLightTheme ? '!bg-white !text-stone-900 !border-stone-200' : '!bg-black !text-stone-50 !border-stone-700'} rounded-lg p-3 z-[1000]`}
                      >
                        <div
                          className={`w-fit max-w-fit h-fit flex flex-col items-start justify-start gap-4 ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                        >
                          <p
                            className={`w-fit max-w-fit font-Louize font-normal text-[22px] tracking-tighter`}
                          >{`Protocol Rewards`}</p>
                          <div className="w-fit max-w-fit flex items-center justify-center gap-14">
                            <p
                              className={`w-fit max-w-fit font-Suisse-Intl font-semiMedium text-sm ${theme.isLightTheme ? 'text-stone-800' : 'text-stone-300'} text-wrap`}
                            >
                              <p
                                className={`w-fit max-w-full rounded-[4px] py-[2px] px-[6px] ${theme.isLightTheme ? 'bg-[#E0EAFF] text-[#565DD5]' : 'bg-[#3F4270] text-[#E0EAFF]'} text-sm font-Suisse-Intl font-medium inline-block`}
                              >
                                20x
                              </p>
                              &nbsp;Noon Points per <YTsUSNIcon className="w-6 h-6 shrink-0 inline-block" />
                              &nbsp;{`YT-sUSN token held per day.`}
                              <br />
                              <br />
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`https://mirror.xyz/nooncapital.eth/EjihOJP_wyvnL-BFj1lGvfgLj1CKa6nGFqiysFWoeGw`}
                                className="underline"
                              >
                                Read more &nbsp;
                                <ArrowRightUpIcon
                                  className={`w-6 h-6 shrink-0 ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} inline`}
                                />
                              </a>
                            </p>
                          </div>
                        </div>
                      </PopoverContent>
                    </Popover>
                  </div>
                </TableHead>
                <TableHead
                  className={`border-[1px] !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4`}
                >
                  <div className="w-fit max-w-fit min-w-fit h-full flex items-center justify-center gap-1">
                    <div>{formatStringToNumberNoDecimal(spectraYtPoints?.toString() || '0', true)}</div>
                    <p
                      className={`font-Suisse-Intl font-medium text-sm rounded-md py-[2px] px-2 ${theme.isLightTheme ? 'text-[#027A3E] bg-[#D1FAE5]' : 'text-[#D1FAE5] bg-[#054F2A]'} leading-5`}
                    >
                      {spectraYtPointsChange > 0
                        ? `+${formatStringToNumberNoDecimal(spectraYtPointsChange?.toString() || '0', true)}`
                        : `${formatStringToNumberNoDecimal(spectraYtPointsChange?.toString() || '0', true)}`}
                    </p>
                  </div>
                </TableHead>
                <TableHead
                  className={`h-full border-[1px] !border-l-0 !border-t-0 !border-r-0 !border-b-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700 bg-stone-950'} p-4 text-stone-500 flex gap-2 items-start justify-center`}
                >
                  <NavLink
                    target="_blank"
                    to="https://app.spectra.finance/pools/eth:0x76717d6e40aae85dac1ef9edb77ceb340e32cedf"
                    className={`min-w-full w-fit h-fit max-w-fit max-h-full rounded-[184px] py-[6px] px-3 gap-[6px] border-[1px] ${theme.isLightTheme ? 'bg-stone-100 border-stone-300 text-stone-900' : 'bg-stone-900 border-stone-800 text-stone-50'}`}
                  >
                    <p
                      className={`min-w-full w-fit max-w-full font-Suisse-Intl font-medium text-sm text-nowrap text-center ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                    >
                      Get and hold <YTsUSNIcon className="w-6 h-6 shrink-0 inline-block" />
                      &nbsp;YT-sUSN &nbsp;
                      <ArrowRightIcon
                        className={`w-5 h-5 shrink-0 inline ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'}`}
                      />
                    </p>
                  </NavLink>
                </TableHead>
              </TableRow>

              <TableRow
                className={`border-0 ${theme.isLightTheme ? 'table-section-bg' : 'bg-stone-950'}`}
                onClick={() => setReferralsVisible((b) => !b)}
              >
                <TableCell
                  colSpan={4}
                  className={`border-[1px] !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 text-stone-500 font-Louize font-normal text-2xl tracking-tighter text-left h-full`}
                >
                  <div className="w-full max-w-full h-full max-h-full flex items-center justify-between gap-1 cursor-pointer">
                    <div className="grow">
                      Referrals&nbsp;
                      <Tooltip
                        hasArrow={true}
                        label={
                          <div className="w-full max-w-full flex flex-col gap-1">
                            <p>{`These are points you have earned by referring friends, or being referred.`}</p>
                          </div>
                        }
                        bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                        color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                        placement="top-start"
                        borderRadius={'rounded-lg'}
                        paddingX={'px-3'}
                        paddingY={'py-[6px]'}
                        className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
                      >
                        <InfoFilledIcon className="w-6 h-6 inline" />
                      </Tooltip>
                    </div>
                    <>
                      <div className="w-6 h-6 shrink-0">
                        {referralsVisible ? (
                          <MinimizeIcon className="w-full h-full" />
                        ) : (
                          <MaximizeIcon className="w-full h-full" />
                        )}
                      </div>
                    </>
                  </div>
                </TableCell>
                <TableCell
                  className={`border-b-[1px] ${theme.isLightTheme ? 'border-stone-200 text-stone-900 subtotal-bg' : 'border-stone-700 text-stone-50 subtotal-dark-bg'} p-4 font-Suisse-Intl font-normal text-base`}
                >
                  <div className="w-full h-full flex items-center justify-between">
                    <p className="text-stone-500">SUBTOTAL</p>
                    <p>{formatBigNumberNoDecimal(BigInt(pointsInfo?.referralPoints?.total || '0'), 18, true)}</p>
                  </div>
                </TableCell>
                <TableCell
                  className={`border-[1px] !border-r-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 text-stone-500`}
                ></TableCell>
              </TableRow>

              <TableRow
                className={`${referralsVisible ? '' : 'hidden'} ${theme.isLightTheme ? 'bg-white text-stone-900' : 'bg-stone-900 text-stone-50'} font-Suisse-Intl font-semiMedium text-base border-b-0 min-h-60 h-60 max-h-60`}
              >
                <TableCell colSpan={6} className="w-full h-full">
                  <div className="relative w-full h-full">
                    <img
                      src={theme.isLightTheme ? noRefereePointsContribution : noRefereePointsContributionDark}
                      className="absolute w-full h-full left-0 top-0"
                    />
                    <div
                      className={`border-0 absolute h-full w-full max-w-full max-h-full top-0 flex flex-col gap-1 items-center justify-center z-[100] data-container`}
                    >
                      <div className="w-[105px] h-[80px]">
                        {theme.isLightTheme ? (
                          <img alt="No recent transactions" className="w-full h-full" src={noRecentTransactions} />
                        ) : (
                          <img alt="No recent transactions" className="w-full h-full" src={noRecentTransactionsDark} />
                        )}
                      </div>
                      <p
                        className={`w-[50%] max-w-[50%] font-Louize font-normal text-3xl tracking-tighter leading-[38px] ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'} text-center text-wrap`}
                      >
                        Referral Breakdown will be available shortly
                      </p>
                      <p
                        className={`w-[50%] max-w-[50%] font-Louize font-semiMedium text-lg tracking-tighter leading-[38px] ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} text-center text-wrap`}
                      >
                        {`We are actively tracking the breakdown of referral points by each individual referral, and it will be available soon!`}
                      </p>
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>

            <TableFooter>
              <TableRow className={`${theme.isLightTheme ? 'table-section-bg' : 'bg-stone-950'}`}>
                <TableCell
                  colSpan={4}
                  className={`border-[1px] border-b-0 !border-l-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 text-stone-500 font-Louize font-normal text-2xl tracking-tighter text-left`}
                >
                  Total
                </TableCell>
                <TableCell
                  className={`border-[1px] border-b-0 ${theme.isLightTheme ? 'border-stone-200 text-stone-900 total-data-field-bg' : 'border-stone-700 text-stone-50 total-data-field-dark-bg'} p-4 font-Suisse-Intl font-medium text-xl`}
                >
                  <img src={NoonPointsLogo} className="w-7 h-7 inline" />
                  &nbsp;{totalPointsFormatted}
                </TableCell>
                <TableCell
                  className={`border-[1px] border-b-0 !border-r-0 ${theme.isLightTheme ? 'border-stone-200' : 'border-stone-700'} p-4 text-stone-500`}
                ></TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </div>

      <div
        className={`w-full max-w-full flex flex-col items-start justify-between gap-6 rounded-2xl border-[1px] p-6 ${theme.isLightTheme ? 'bg-white border-stone-300' : 'bg-stone-900 border-stone-700'} leaderboard-container`}
      >
        <div
          className={`w-full max-w-full grid grid-rows-1 grid-cols-1 md:grid-cols-1 md:grid-rows-1 items-center justify-between gap-8`}
        >
          <div
            className={`h-full grid grid-rows-2 grid-cols-1 md:grid-rows-1 md:grid-cols-2 items-start justify-between gap-4 md:gap-14`}
          >
            <div className="flex flex-col items-start justify-start gap-1">
              <p
                className={`w-full max-w-full font-Louize font-normal text-4xl tracking-tighter ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-200'} opacity-60 text-wrap`}
              >
                Points Leaderboard
              </p>
              <p
                className={`w-full max-w-full font-Suisse-Intl font-semiMedium text-base ${theme.isLightTheme ? 'text-[#57534E]' : 'text-[#A8A29E]'} text-wrap`}
              >
                Holdings and Activities only&nbsp;
                <Tooltip
                  hasArrow={true}
                  label={
                    <div className="w-full max-w-full flex flex-col gap-1">
                      <p>{`For the Leaderboard, we only look at points you have earned via Holdings or Activities, NOT via Referrals.`}</p>
                    </div>
                  }
                  bg={theme.isLightTheme ? 'bg-stone-50' : 'bg-black'}
                  color={theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}
                  placement="top-start"
                  borderRadius={'rounded-lg'}
                  paddingX={'px-3'}
                  paddingY={'py-[6px]'}
                  className={`${theme.isLightTheme ? 'bg-stone-50 text-stone-900' : 'bg-black text-stone-50'} rounded-lg py-[6px] px-3 w-fit h-fit z-[1000]`}
                >
                  <InfoFilledIcon className="w-6 h-6 inline" />
                </Tooltip>
              </p>
            </div>

            <div className="flex flex-col items-start justify-between gap-4">
              <div className="w-full max-w-full flex items-center justify-start md:justify-end gap-2">
                <div className="w-9 h-9 aspect-square shrink-0">
                  <img alt="Profile" className="w-full h-full aspect-square rounded-[18px] object-cover" src={avatar} />
                </div>

                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${account?.chain?.blockExplorers?.default.url}/address/${myLeaderboard?.walletAddress}`}
                  className={`font-Suisse-Intl font-[450] text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} flex justify-start gap-1 items-center`}
                >
                  {myLeaderboard?.walletAddress &&
                    ` ${myLeaderboard?.walletAddress?.slice(0, 4)}...${myLeaderboard?.walletAddress?.slice(myLeaderboard?.walletAddress?.length - 5)}`}
                  {myLeaderboard?.walletAddress && (
                    <div className={`w-5 h-5 text-stone-400 shrink-0`}>
                      <ArrowRightUpIcon className="w-full h-full" />
                    </div>
                  )}
                </a>
              </div>

              <div className="w-full max-w-full flex items-center justify-between md:justify-end gap-0 md:gap-10">
                <div className="flex flex-col items-start justify-start gap-[2px]">
                  <p
                    className={`w-full max-w-full text-wrap font-Suisse-Intl font-semiMedium text-base text-stone-500`}
                  >
                    Your Rank
                  </p>
                  <p
                    className={`w-full max-w-full font-Suisse-Intl font-semiMedium text-2xl ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'} text-wrap`}
                  >
                    {formatStringToNumberNoDecimal(myLeaderboard?.rank?.toString() || '0')}
                  </p>
                </div>

                <div className="flex flex-col items-start justify-start gap-[2px]">
                  <p
                    className={`w-full max-w-full text-wrap font-Suisse-Intl font-semiMedium text-base text-stone-500`}
                  >
                    Points
                  </p>
                  <p
                    className={`w-full max-w-full font-Suisse-Intl font-semiMedium text-2xl ${theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'} text-wrap`}
                  >
                    {formatBigNumberCompactNoDecimals(BigInt(myLeaderboard?.points || '0'), 18, true)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {relativeLeaderboard?.rankings?.length ? (
          <Table className={`p-0 m-0 border-collapse`}>
            <TableHeader className={`border-0 pb-1`}>
              <TableRow
                className={`px-4 border-0 !border-b-[1px] font-Suisse-Intl text-base ${theme.isLightTheme ? 'text-stone-500 border-[#E7E5E4]' : 'text-stone-400 border-stone-700'}`}
              >
                <TableHead>Rank</TableHead>
                <TableHead>Name</TableHead>
                <TableHead>Address</TableHead>
                <TableHead>Change (Rank | Points)</TableHead>
                <TableHead className="text-right">Points</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {relativeLeaderboard?.rankings.map((rl) => (
                <TableRow
                  key={rl.rank}
                  className={`w-full max-w-full px-4 py-6 font-Suisse-Intl text-base ${theme.isLightTheme ? 'text-stone-500' : 'text-stone-400'} border-0`}
                >
                  <TableCell>{rl.rank}</TableCell>
                  <TableCell>
                    <div className="flex items-center justify-start gap-2">
                      <div className="w-9 h-9 aspect-square shrink-0">
                        <img
                          alt="Profile"
                          className="w-full h-full aspect-square rounded-[18px] object-cover"
                          src={avatar}
                        />
                      </div>

                      <p
                        className={`font-Suisse-Intl font-semiMedium text-base ${account.address?.toLowerCase() === rl.walletAddress.toLowerCase() ? (theme.isLightTheme ? 'text-indigo-600' : 'text-indigo-300') : theme.isLightTheme ? 'text-stone-900' : 'text-stone-50'}`}
                      >
                        {rl?.username || 'fren'}
                      </p>
                    </div>
                  </TableCell>
                  <TableCell>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`${account?.chain?.blockExplorers?.default.url}/address/${rl?.walletAddress}`}
                      className={`w-full font-Suisse-Intl text-base ${account.address?.toLowerCase() === rl.walletAddress.toLowerCase() ? (theme.isLightTheme ? 'text-indigo-600' : 'text-indigo-300') : theme.isLightTheme ? 'text-stone-700' : 'text-stone-400'} flex justify-start gap-1 items-center`}
                    >
                      {rl?.walletAddress &&
                        ` ${rl?.walletAddress?.slice(0, 4)}...${rl?.walletAddress?.slice(rl?.walletAddress?.length - 5)}`}
                      {rl?.walletAddress && (
                        <div
                          className={`w-5 h-5 ${account.address?.toLowerCase() === rl.walletAddress.toLowerCase() ? (theme.isLightTheme ? 'text-indigo-600' : 'text-indigo-300') : 'text-stone-400'} shrink-0`}
                        >
                          <ArrowRightUpIcon className="w-full h-full" />
                        </div>
                      )}
                    </a>
                  </TableCell>
                  <TableCell
                    className={`${account.address?.toLowerCase() === rl.walletAddress.toLowerCase() ? (theme.isLightTheme ? 'text-indigo-600' : 'text-indigo-300') : theme.isLightTheme ? 'text-stone-700' : 'text-stone-400'} font-Suisse-Intl font-semiMedium text-base leading-6`}
                  >
                    <div
                      className={`w-fit max-w-fit h-full flex items-center justify-start gap-2 ${rl?.rankChange >= 0 ? (theme.isLightTheme ? 'text-[#03984E]' : 'text-[#32D583]') : theme.isLightTheme ? 'text-[#BB473E]' : 'text-[#F97066]'}`}
                    >
                      <div className="flex items-center justify-end gap-1 text-wrap text-right">
                        {(rl?.rankChange || 0) >= 0 ? (
                          <ChevronBigUpIcon className="w-6 h-6 inline" />
                        ) : (
                          <ChevronBigDownIcon className="w-6 h-6 inline" />
                        )}
                        {Math.abs(rl?.rankChange || 0)}
                        &nbsp;
                        <div className={`w-[2px] h-6 ${theme.isLightTheme ? 'bg-[#D6D3D1]' : 'bg-[#44403C]'}`}></div>
                      </div>
                      <div
                        className={`w-auto flex items-center justify-start gap-1 text-wrap text-left ${theme.isLightTheme ? 'text-[#03984E]' : 'text-[#32D583]'}`}
                      >
                        <img src={NoonPointsLogo} className="w-6 h-6 inline" />
                        {formatBigNumberCompactNoDecimals(BigInt(rl?.last24HoursPoints || '0'), 18, true)}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    className={`text-right ${account.address?.toLowerCase() === rl.walletAddress.toLowerCase() ? (theme.isLightTheme ? 'text-indigo-600' : 'text-indigo-300') : theme.isLightTheme ? 'text-stone-700' : 'text-stone-400'}`}
                  >
                    {formatBigNumberCompactNoDecimals(BigInt(rl?.points || '0'), 18, true)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

export default DashboardPage
