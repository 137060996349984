import React, { useCallback, useContext, useEffect, useMemo } from 'react'

import { darkTheme, lightTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { useLocation, useSearchParams } from 'react-router-dom'

import { mainnet } from 'wagmi/chains'

import { useDisclosure } from '@chakra-ui/react'

import multipleEllipseFrame from '../../assets/backgrounds/multiple-ellipse-frame.png'
import { prohibitedStateAlpha2, prohibitedStateNumeric } from '../../constants'
import noiseAndTexture from '../../assets/backgrounds/noise-and-texture.png'
import background329 from '../../assets/backgrounds/background-329.png'
import elipsis38 from '../../assets/backgrounds/ellipse-38.png'
import ProhibitedState from '../Modals/ProhibitedStateModal'
import { ThemeContext } from '../../context/ThemeProvider'
import ReferralModal from '../Modals/ReferralModal'
import WelcomeModal from '../Modals/WelcomeModal'
import { TCountry } from '../../types'
import Navbar from '../Navbar'
import Footer from '../Footer'
import Routes from '../Routes'

export const RootApp = () => {
  const location = useLocation()
  const theme = useContext(ThemeContext)
  const [searchParams, setSearchParams] = useSearchParams()
  const { isOpen: isStateModalOpen, onOpen: openStateModal, onClose: closeStateModal } = useDisclosure()
  const { isOpen: isWelcomeModalOpen, onOpen: openWelcomeModal, onClose: closeWelcomeModal } = useDisclosure()
  const { isOpen: isReferralModalOpen, onOpen: openReferralModal, onClose: _closeReferralModal } = useDisclosure()

  const fetcher = useCallback(async () => {
    return await fetch(`${process.env.REACT_APP_BASE_API_URL ?? ''}/api/v1/country`)
      .then(
        (res) => {
          if (res.ok) {
            return res.json()
          }

          throw new Error('Failed to fetch user activity data')
        },
        (err) => {
          throw new Error(err)
        },
      )
      .then((json) => json)
      .then((country) => (country ?? {}) as TCountry)
      .then(
        (country) =>
          ({
            alpha2: country?.countryCode?.alpha2 || '',
            numeric: country?.countryCode?.numeric || '0',
          }) as TCountry['countryCode'],
      )
      .catch(() => {
        return { alpha2: '', numeric: '0' } as TCountry['countryCode']
      })
  }, [navigator.geolocation])

  useEffect(() => {
    const checkProhibitedState = async () => {
      const countryCode = await fetcher()

      if (!countryCode || (!countryCode.alpha2 && !countryCode.numeric)) {
        closeStateModal()
      } else {
        if (
          prohibitedStateAlpha2.includes(countryCode.alpha2) ||
          prohibitedStateNumeric.includes(countryCode.numeric)
        ) {
          openStateModal()
        } else {
          closeStateModal()
        }
      }
    }

    checkProhibitedState()
  }, [navigator.geolocation, location, fetcher])

  useEffect(() => {
    const value = localStorage.getItem('noon-dapp-policies') === 'accepted'
    if (!value) {
      openWelcomeModal()
    }
  }, [location, localStorage])

  useEffect(() => {
    if (isStateModalOpen && isWelcomeModalOpen) {
      closeWelcomeModal()
    }
  }, [isStateModalOpen, isWelcomeModalOpen])

  const closeReferralModal = useCallback(() => {
    searchParams.delete('referralCode')
    setSearchParams(() => {
      _closeReferralModal()
      return searchParams
    })
  }, [_closeReferralModal])

  const referralCode = useMemo(() => searchParams?.get('referralCode'), [searchParams])

  useEffect(() => {
    if (isStateModalOpen || isWelcomeModalOpen) {
      _closeReferralModal()
      return
    }

    if (!location?.pathname?.includes('get')) {
      _closeReferralModal()
      return
    }

    if (!referralCode) {
      _closeReferralModal()
      return
    }

    if (referralCode) {
      openReferralModal()
      return
    }
  }, [referralCode, location, searchParams, isStateModalOpen, isWelcomeModalOpen])

  return (
    <RainbowKitProvider initialChain={mainnet} theme={theme.isLightTheme ? lightTheme() : darkTheme()}>
      <div
        className={`relative w-full h-full ${theme.isLightTheme ? 'bg-stone-50' : 'bg-stone-950'} z-0 overflow-hidden`}
      >
        {theme.isLightTheme && (
          <div className="absolute top-0 left-0 m-0 p-0 w-full h-[25%] md:w-[81%] md:h-[76%] z-10 background-image-329">
            <img src={background329} className="m-0 p-0 w-full h-full" />
          </div>
        )}

        <div className="absolute left-0 right-0 ml-auto mr-auto p-0 w-[890px] max-w-[890px] top:0 md:-top-52 z-20">
          <img src={elipsis38} className="m-0 p-0 w-full h-full" />
        </div>

        {location.pathname.includes('bridge') && theme.isLightTheme && (
          <img
            src={multipleEllipseFrame}
            className="hidden md:block absolute left-0 right-0 ml-auto mr-auto p-0 w-auto h-full aspect-auto top:0 bottom:0 z-30"
          />
        )}

        <img src={noiseAndTexture} className="absolute m-0 p-0 top-0 left-0 w-full h-full z-30" />

        <div className="relative top-0 left-0 w-full h-full m-0 p-0 z-40">
          <ReferralModal referralCode={referralCode || ''} isOpen={isReferralModalOpen} onClose={closeReferralModal} />
          <WelcomeModal isOpen={isWelcomeModalOpen} onClose={closeWelcomeModal} />
          <ProhibitedState isOpen={isStateModalOpen} />
          <Navbar />
          <Routes />
          <Footer />
        </div>
      </div>
    </RainbowKitProvider>
  )
}

export default RootApp
