import { useAccount } from 'wagmi'

import ERC20Abi from '../../constants/abis/ERC20.json'

import { Contract } from 'zksync-ethers'

import { JsonRpcProvider } from 'ethers'

import { useQuery } from '@tanstack/react-query'

import { rainbowKitConfig } from '../../constants/rainbowKitConfig'
import { REFETCH_INTERVAL } from '../../utils'
import { DAPP_CONFIG } from '../../constants'

const useTokenBalance = (token: string) => {
  const account = useAccount({ config: rainbowKitConfig })

  const fetch = async () => {
    if (!token || !account || !account.address || !account.isConnected || !account.chain || !account.chainId) {
      return BigInt(0)
    }
    const config = DAPP_CONFIG[account.chainId]
    if (!config) {
      return BigInt(0)
    }
    const _token = config.tokens[token]
    if (!_token) {
      return BigInt(0)
    }

    const contract = new Contract(
      _token.contractAddress,
      ERC20Abi,
      new JsonRpcProvider(config.rpcUrl || account.chain.rpcUrls.default.http.at(0)),
    )
    return contract.balanceOf(account.address)
  }

  return useQuery({
    queryFn: fetch,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    initialData: () => BigInt(0),
    placeholderData: () => BigInt(0),
    refetchInterval: REFETCH_INTERVAL,
    refetchIntervalInBackground: false,
    enabled: Boolean(token) && account.isConnected,
    select: (data) => (data || BigInt(0)) as bigint,
    queryKey: ['balance', token, account.address, account.chainId],
  })
}

export default useTokenBalance
